import React, { useEffect, useState } from 'react';
import "./ContentProvider.css"
import { ORGANIZATION_FIELDS, CONTENT_PROVIDER, TRUE, SEARCHABLE_STATES } from "../../constants";
import DropdownField from "../common/DropdownField";
import Filters from "../Filters/Filters";
import OrganizationsService from "../../Services/organizationsService";
import { useFilterOrganizationsByStatus } from '../../hooks/useFilterOrganizationsByStatus';

const fetchOrganizationParams = {
    page: undefined,
    results: undefined,
    sort: undefined,
    isInitial: undefined,
    currentResults: undefined,
    facetType: ORGANIZATION_FIELDS?.ORGANIZATION_TYPE
};

const ContentProvider = (props) => {

    const [filterOrganizationsByStatus] = useFilterOrganizationsByStatus(props.organizationsWithListings, TRUE);

    const activeOrganizationsWithListings = filterOrganizationsByStatus?.org_name?.[0]?.data?.map((orgName) => (orgName?.value))
    const organizationService = OrganizationsService({
        setOrganizationFacets: props.setOrganizationFacets,
        filters: props.filtersToFetchOrganizations
    });

    const handleSelectOrgTypeDropdown = (event) => {
        props.setSelectedOrgType(event)
        props.setIsEndorsed(false);
        props.setEnableClearFilterInContentProvider(true)
        props.setFilters([]);
        props.setFilterChange(props.filterChange + 1);
    };

    const generateFilters = () => {
        const filters = [];
        filters.push({
            org_type: props.selectedOrgType ? props.selectedOrgType.value : props.organizationFacets?.org_type?.[0]?.data?.map((orgType) => (orgType?.value))
        })
        filters.push({
            org_name: props.activeOrganizations ? props.activeOrganizations : activeOrganizationsWithListings
        })

        return filters;
    }

    const generateInitialSearchFilter = (orgNames) => {
        const filters = [];
        filters.push({
            org_name: orgNames
        })
        filters.push({
            resource_state: SEARCHABLE_STATES
        })

        return filters;
    }

    useEffect(() => {
        if (!props.organizationFacets) return;

        if (!props.selectedOrgType) {
            props.setDropdownOrgTypes(props.organizationFacets?.org_type?.[0]?.data?.map((selectedOrgType) => ({ value: selectedOrgType?.value, label: selectedOrgType?.value })));
        }

        if (!props.filtersToFetchOrganizations && activeOrganizationsWithListings) {
            props.setActiveOrganizations(activeOrganizationsWithListings)
            props.setFiltersToFetchOrganizations(generateFilters());
        }
    }, [props.organizationFacets, filterOrganizationsByStatus]);


    useEffect(() => {
        if (props.selectedOrgType) {
            fetchOrganizationParams.facetType = ORGANIZATION_FIELDS?.ORGANIZATION_NAME;
            props.setFiltersToFetchOrganizations(generateFilters());
        }
    }, [props.selectedOrgType]);

    useEffect(() => {
        organizationService.fetchOrganizations(fetchOrganizationParams.page, fetchOrganizationParams.results, fetchOrganizationParams.sort, fetchOrganizationParams.isInitial, fetchOrganizationParams.currentResults, fetchOrganizationParams.facetType);
    }, [props.filtersToFetchOrganizations]);

    useEffect(() => {
        if (props.selectedOrgType) {
            const orgNames = props.organizationFacets?.org_name?.[0]?.data?.map(orgName => orgName.value);
            props.setInitialContentProviderSearchFilter(generateInitialSearchFilter(orgNames?.map(orgName => orgName)))
            orgNames && props.setDropdownOrgNames({ [CONTENT_PROVIDER]: [...orgNames] })
        }
    }, [props.selectedOrgType, props.organizationFacets]);

    useEffect(() => {
        if (!props.resetOrgTypeDropdown) return;

        props.setSelectedOrgType(null)
        props.setDropdownOrgNames(null)
        props.setResetOrgTypeDropdown(false)
    }, [props.resetOrgTypeDropdown]);

    return (
        <div className='dropdown-multi-filter-container'>
            <div className='dropdown-multi-filter-title' >
                {CONTENT_PROVIDER}
            </div>
            <div className='dropdown-multi-filters'>
                <DropdownField
                    id="org_type"
                    value={props.selectedOrgType}
                    name="org_type"
                    onChange={(e) => handleSelectOrgTypeDropdown(e)}
                    options={props.dropdownOrgTypes}
                    placeholder="Select content provider type"
                    dynamicDropdownHeight={true}
                />
                {!props.selectedOrgType && <div className='orgType-dropdown-description'>Select the provider type to see the provider list</div>}
                {props.selectedOrgType && props.dropdownOrgNames && <Filters gtag={props.gtag} filters={props.filters} setFilters={props.setFilters} filterChange={props.filterChange} setFilterChange={props.setFilterChange} switchFilters={props.switchFilters} multiFilters={props.dropdownOrgNames} isEndorsed={props.isEndorsed} setIsEndorsed={props.setIsEndorsed} />}
            </div>
        </div>
    )
}

export default React.memo(ContentProvider);
