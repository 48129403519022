import { useEffect, useState } from "react";
import OrganizationsService from "../Services/organizationsService";
import { ORGANIZATION_FIELDS } from "../constants";

const fetchOrganizationParams = {
    page: undefined,
    results: undefined,
    sort: undefined,
    isInitial: undefined,
    currentResults: undefined,
    facetType: ORGANIZATION_FIELDS?.ORGANIZATION_NAME
};

/** Filters the organizations based on Active/Deactive status */
export const useFilterOrganizationsByStatus = (organizations, isActive) => {
    const [filteredOrganizations, setFilteredOrganization] = useState(null);

    const filters = organizations && [{ org_name: organizations }, { is_active: isActive }];
    const organizationService = OrganizationsService({
        setOrganizationFacets: setFilteredOrganization,
        filters: filters
    });

    useEffect(() => {
        if (organizations) {
            organizationService.fetchOrganizations(fetchOrganizationParams.page, fetchOrganizationParams.results, fetchOrganizationParams.sort, fetchOrganizationParams.isInitial, fetchOrganizationParams.currentResults, fetchOrganizationParams.facetType);
        }
    }, [organizations])

    return [filteredOrganizations]
}
