import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MEDIA_TYPE_FILTERS, multiFilters } from '../../../constants';
import './BrowseModal.css'

function BrowseModal(props) {
    const [groupSelected, setGroupSelected] = useState(false);
    const [category, setCategory] = useState('');
    const [selectedGroup, setSelectedGroup] = useState([]);
    const navigate = useNavigate();

    const handleSelection = (selectedFilter, category) => {
        props.setQuery('');
        props.setFilters([selectedFilter, category]);
        props.setBackDrop(false);
        props.setFilterChange(props.filterChange + 1);
        props.gtag('event', 'quick_filter', { 'category': category, 'item': selectedFilter });
        navigate('/results');
    }

    const renderItemsInColumns = (categories, key) => {
        const topicCategoriesOne = categories.filter((val, index) => index < 12);
        const topicCategoriesTwo = categories.filter((val, index) => index > 11);
        let topicOne = []
        topicCategoriesOne.forEach((txt, index) => {
            topicOne.push(
                <div className="categories" key={`${key}-${index}`} >
                    <p className="category-item" key={index} tabIndex="0" 
                        onClick={() => handleSelection(txt, key)}
                        onKeyPress={(e) => {
                            if (e.code === "Space" || e.code === "Enter") {
                                e.preventDefault();
                                handleSelection(txt, key);
                            }
                        }}
                    >
                        {txt}
                    </p>
                </div>
            );
        })
        let topicTwo = []
        topicCategoriesTwo.forEach((txt, index) => {
            topicTwo.push(
                <div className="categories" key={`${key}-${index}-2`} >
                    <p className="category-item" key={index} tabIndex="0"
                        onClick={() => handleSelection(txt, key)}
                        onKeyPress={(e) => {
                            if (e.code === "Space" || e.code === "Enter") {
                                e.preventDefault();
                                handleSelection(txt, key);
                            }
                        }}
                    >
                        {txt}
                    </p>
                </div>
            );
        })
        return [topicOne, topicTwo];
    }

    const renderItems = (multiFilters) => {
        const browseMultiFilters = {
            'AUDIENCE': multiFilters.AUDIENCE,
            'MEDIA TYPE': MEDIA_TYPE_FILTERS
        }

        let categories = [];
        Object.keys(browseMultiFilters).forEach(
            (key, index) => {
                categories.push(
                    <div className="categories" key={index + '-cat'} >
                        <p className='category-heading'>{key}</p>
                        {browseMultiFilters[key].map((txt, index) => {
                            return (
                                <p className="category-item" key={index + 'filter'} tabIndex="0"
                                    onClick={() => handleSelection(txt, key)}
                                    onKeyPress={(e) => {
                                        if (e.code === "Space" || e.code === "Enter") {
                                            e.preventDefault();
                                            handleSelection(txt, key);
                                        }
                                    }}
                                >
                                    {txt}
                                </p>
                            )
                        }
                        )}
                    </div>
                );
            }
        )

        const topicFilter = {
            'TOPIC': multiFilters.TOPIC
        }

        Object.keys(topicFilter).forEach((key, index) => {
            categories.push(
                <div className="categories two-column" key={index} >
                    <p className='category-heading-title'>{'TOPIC'}</p>
                    <hr className='category-heading-columns' />
                    <div className="category-column">
                        <div className="category-column-one">
                            {renderItemsInColumns(multiFilters.TOPIC, key)[0]}
                        </div>
                        <div className="category-column-two">
                            {renderItemsInColumns(multiFilters.TOPIC, key)[1]}
                        </div>
                    </div>
                </div>
            )
        })

        return categories;
    }
    return (
        <div>
            {props.mobile && (
                <div className="modal__content mobile-only">
                    <div className="_browse-mobile">
                        {groupSelected ?
                            <div className="main-content">
                                <div className="head">
                                    <div className="title back" onClick={() => setGroupSelected(!groupSelected)}>Back</div>
                                    <div className="icon close" onClick={() => { props.setBackDrop(!props.backDrop) }}></div>
                                </div>
                                <MobileFilterGroup category={category} values={selectedGroup} setFilters={props.setFilters} search={props.search} setBackDrop={props.setBackDrop} filterChange={props.filterChange} setFilterChange={props.setFilterChange} setQuery={props.setQuery} />
                            </div>
                            :
                            <div className="main-content">
                                <div className="head">
                                    <div className="title">Browse</div>
                                    <div className="icon close" onClick={() => { props.setBackDrop(!props.backDrop) }}></div>
                                </div>
                                <div className="category" onClick={() => {
                                    setGroupSelected(!groupSelected);
                                    setCategory('MEDIA TYPE');
                                    setSelectedGroup(MEDIA_TYPE_FILTERS);
                                }}>
                                    <div className="title">Media Type</div>
                                    <div className="icon"><img className="right" src="/icons/chevron.svg" alt="chevron" /></div>
                                </div>
                                <div className="category" onClick={() => {
                                    setGroupSelected(!groupSelected);
                                    setCategory('TOPIC');
                                    setSelectedGroup(multiFilters.TOPIC);
                                }}>
                                    <div className="title">Topic</div>
                                    <div className="icon"><img className="right" src="/icons/chevron.svg" alt="chevron" /></div>
                                </div>
                                <div className="category" onClick={() => {
                                    setGroupSelected(!groupSelected);
                                    setCategory('AUDIENCE');
                                    setSelectedGroup(multiFilters.AUDIENCE);
                                }}>
                                    <div className="title">Audience</div>
                                    <div className="icon"><img className="right" src="/icons/chevron.svg" alt="chevron" /></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )}
            {!props.mobile && (
                <div className="_browse-desktop">
                    <div className='category-section' id='browse-category'>
                        <div className="icon close" id='browse-close' tabIndex="0" 
                            onClick={() => { 
                                props.setBackDrop(!props.backDrop)
                            }}
                            onKeyPress={(e) => {
                                let key = e.code;
                                if (key === "Space" || key === "Enter") {
                                    props.setBackDrop(!props.backDrop);
                                }
                            }}
                        >
                        </div>
                        <div className="category-title">Browse by category</div>
                        <div className='category-container'>{renderItems(multiFilters)}</div>
                    </div>
                </div>
            )}
        </div>
    );


}

function MobileFilterGroup(props) {
    const navigate = useNavigate();
    const items = [];
    const performFilteredSearch = (value) => {
        props.setQuery('');
        props.setFilters([value, props.category]);
        props.setBackDrop(false);
        props.setFilterChange(props.filterChange + 1);
        navigate('/results');
    }
    props.values.forEach((value, index) => {
        items.push(<div className="item" key={index} onClick={() => performFilteredSearch(value)}>
            {value}
        </div>)
    });
    return (
        <div className="filter-group">
            {items}
        </div>
    );
}

export default BrowseModal;
