import DropDown from "../common/DropDown";
import InputComponent from "../common/InputComponent";
import { USER_SURVEY_URL } from '../../constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import utilityService from "../../Services/utilityService";
import './UserSurvey.css';

const Form = (props) => {

    // Variables

    const dropdownRoleMap = {
        'Teachers': 'Teachers',
        'Coaches & Mentors': 'Coaches & Mentors',
        'School Counselors & Social Workers': 'School Counselors & Social Workers',
        'Principals': 'Principals',
        'Administrators': 'Administrators',
        'ESCs': 'ESCs'
    };

    const dropdownRoleOptions = [
        'Teachers',
        'Coaches & Mentors',
        'School Counselors & Social Workers',
        'Principals',
        'Administrators',
        'ESCs'
    ]

    const dropdownOrgMap = {
        'Traditional Public School District': 'Traditional Public School District',
        'Career/JVSD/CTE Planning District': 'Career/JVSD/CTE Planning District',
        'STEM School': 'STEM School',
        'Community School': 'Community School',
        'Chartered Nonpublic School': 'Chartered Nonpublic School',
        'Educational Service Center': 'Educational Service Center',
        'Information Technology Center': 'Information Technology Center',
        'Educational Television': 'Educational Television',
        'Board of Developmental Disabilities': 'Board of Developmental Disabilities',
        'Early Childhood/Preschool': 'Early Childhood/Preschool',
        'Adult Education': 'Adult Education',
        'College/University': 'College/University',
        'State Agency': 'State Agency',
        'Other': 'Other'
    }

    const dropdownOrgOptions = [
        'Traditional Public School District',
        'Career/JVSD/CTE Planning District',
        'STEM School',
        'Community School',
        'Chartered Nonpublic School',
        'Educational Service Center',
        'Information Technology Center',
        'Educational Television',
        'Board of Developmental Disabilities',
        'Early Childhood/Preschool',
        'Adult Education',
        'College/University',
        'State Agency',
        'Other',
    ];

    // Event Handlers

    const handleInputChange = (e) => {
        const value = e.target.value;
        props.setFormData({
            ...props.formData,
            [e.target.name]: value
        });
    };

    // Form payload

    const handleSubmit = e => {
        e.preventDefault();
        const payload = props.formData;
        const data = {
            body: {
                payload
            }
        };
        props.onSubmit(data);
    };
    

    return (
        <form id={props.id} onSubmit={handleSubmit}>
            <DropDown
                label="Role"
                options={dropdownRoleOptions}
                id="_user_survey__role"
                field={props.dropdownRole}
                setField={props.setDropdownRole}
                setAscending={() => {}}
                fieldMap={dropdownRoleMap}
                basic
            />
            <DropDown
                label="School Organization Type"
                options={dropdownOrgOptions}
                id="_user_survey__org_type"
                field={props.dropdownOrgType}
                setField={props.setDropdownOrgType}
                setAscending={() => {}}
                fieldMap={dropdownOrgMap}
                basic
            />
            <InputComponent 
                label="School ZIP Code"
                type="text"
                name="zipCode"
                placeholder="12345"
                id="_user_survey__zip_code"
                value={props.formData.zipCode}
                onChange={(e) => handleInputChange(e)}
                maxLength={"5"}
                className="_user_survey__zip_code"
                isRequired={false}
            />
            {props.successMessage && 
                <p className="_change_password__form_success">{props.successMessage}</p>
            }
            {props.apiErrorMessage && 
                <p className="_change_password__form_error">{props.apiErrorMessage}</p>
            }
            <div className="_user_survey__submit_wrapper">
                <button className="_user_survey__submit" disabled={!props.formData.zipCode || !props.dropdownRole || !props.dropdownOrgType || props.successMessage} type="submit">Submit</button>
                <button className="_user_survey__skip" disabled={props.successMessage} type="button" onClick={() => {props.setDetailsViewCount(0); props.navigate(props.previous)} }>Skip Survey</button>
            </div>
        </form>
    )
};

function UserSurvey(props) {
    const navigate = useNavigate();
    const previous = props.previous;
    const setDetailsViewCount = props.setDetailsViewCount;

    // State setters
    const [successMessage, setSuccessMessage] = useState("");
    const [apiErrorMessage, setApiErrorMessage] = useState("");
    const [dropdownRole, setDropdownRole] = useState('Teachers');
    const [dropdownOrgType, setDropdownOrgType] = useState('Traditional Public School District');

    const onSuccess = () => {
        const uniqueID = utilityService.generateUniqueID();
        setSuccessMessage("Survey submitted successfully.");
        utilityService.createCookie('completedSurvey', uniqueID);
        setTimeout(() => {navigate(previous)}, 3700);
    }

    // Form data init
    const [formData, setFormData] = useState({
        role: dropdownRole,
        orgType: dropdownOrgType,
        zipCode: '',
    });


    // Submit handler
    const handleSubmit = data => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Request-Headers': '*',
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }
        fetch(USER_SURVEY_URL, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                let parsedData;
                if (typeof data.body === 'string') {
                    parsedData = JSON.parse(data.body);
                }
                if (data.statusCode === 500) {
                    setApiErrorMessage("Something went wrong, please try again in a bit.");
                    return;
                }
                if (data.statusCode === 400) {
                    setApiErrorMessage(data.body.message);
                    return;
                }
                if (parsedData[0].errors.length === 0) {
                    onSuccess();
                }

            })
    };
    return (
        <>
            <div id="_user_survey" className="_user_survey">
                <p className="_user_survey__description">
                    To improve the Ohio Professional Learning Directory, please tell us a little bit about your…
                </p>
                <Form 
                    onSubmit={handleSubmit} 
                    setFormData={setFormData} 
                    formData={formData}
                    successMessage={successMessage}
                    apiErrorMessage={apiErrorMessage}
                    navigate={navigate}
                    previous={previous}
                    setDetailsViewCount={setDetailsViewCount}
                    setDropdownRole={setDropdownRole}
                    setDropdownOrgType={setDropdownOrgType}
                    dropdownOrgType={dropdownOrgType}
                    dropdownRole={dropdownRole}
                    id="_user_survey__form"
                />
            </div>
        </>
    );
}

export default UserSurvey;
