import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { CARD_TYPES, LOCKED_CARD_TYPES, REVIEWLISTINGPOPUP, RESOURCES } from '../../constants';
import moment from 'moment';

import './Tile.css';

function Tile(props) {

  const navigate = useNavigate();
  const [showAllTags, setShowAllTags] = useState(false);
  const locked = props?.tile?.lock_status?.raw ? props?.tile?.lock_status?.raw.toLowerCase(): 'false';
  const isActiveReviewer = props?.tile?.lock_by?.raw === props?.currentUserId ? true : false;
  const cardType = locked === 'true' && !isActiveReviewer && !props.isSuperAdmin && props.resourceTab===RESOURCES.REVIEW ? LOCKED_CARD_TYPES[`${props?.tile?.media_type?.raw}`]: CARD_TYPES[`${props?.tile?.media_type?.raw}`];
  const reviewTag =  isActiveReviewer ? 'Finish Review': 'Review In Progress';
  const iconSrc = cardType?.icon || '';
  const cardColor = cardType?.class || '';
  const activeCard = locked === 'true' && !isActiveReviewer && !props.isSuperAdmin && props.resourceTab===RESOURCES.REVIEW ? '': "active";
  const resourceStartDate = props?.tile?.starting_date?.raw ? moment(props?.tile?.starting_date?.raw).format("MMM DD, YYYY") : '';
  const displayCost = props.tile.cost_display?.raw;

  const topicList = (result) => {
    let topics = typeof result.topic?.raw === "string" ? [result.topic?.raw] : result.topic?.raw || [];
    let topicsVisible = [];
    for (let i = 0; i <= topics.length; i++) {
      if ((topics[i]) && (i < 1)) {
        topicsVisible.push(topics[i]);
      }
      if ((topics[i]) && (topics[i].split('').length <= 25) && (i === 2)) {
        topicsVisible.push(topics[i]);
      }
    }
    return (
      <>
        <div className='visible-tags'>
          {topicsVisible.map((topic, key) => 
          <div className={topics.length === 1 ? 'tag full-width' : 'tag'} key={key}>{topic}</div>)}
          {topics.length > 3 &&  (
            <div tabIndex="0" id="more-tags" className='more-tags tag' 
              onMouseEnter={() => setShowAllTags(true)}
              onClick={(e) => {
                e.stopPropagation();
                setShowAllTags(true);
              }}
              onKeyPress={(e) => {
                if ((e.key === "Enter") && (!showAllTags)) {
                  e.stopPropagation();
                  setShowAllTags(true);
                } else {
                  e.stopPropagation();
                  setShowAllTags(false);
                }
              }}
            >
              <img className='tag-more-plus' src="/icons/cross.svg" alt="plus icon" />
              <span>MORE</span>
            </div>
          )}
        </div>
        { showAllTags && (
          <div className='hidden-tags' onMouseLeave={() => setShowAllTags(false)}>
            {topics.map((topic, key) => <span key={key}>{topics.length === key + 1 ? topic : `${topic},`}</span>)}
          </div>
        )}
      </>
    );
  }

  const viewDetails = (result) => {
    if (!(locked === 'true' && !isActiveReviewer && !props.isSuperAdmin && props.resourceTab===RESOURCES.REVIEW)){
      if (props.targetLocation === "/Admin/") {
        props.setResult(result);
        props.populateFields(result);
        props.setIsEditingDisabled(true)
        props.setCreateState(props.resourceTab);
        props.setTileId(`tile-${index}`)
      } else {
        props.setResult(result);
        props.setPrevious(document.location.pathname);
        props.setTileId(`tile-${index}`)
        navigate(`${props.targetLocation}${result.id.raw}`);
      }
      document.getElementById("overlay").style.display = "block";
      if ((!isActiveReviewer) && (locked === 'true')) {
          props.setReviewListingPopup(REVIEWLISTINGPOPUP.SUPERADMIN)
      } else {
          props.setReviewListingPopup(REVIEWLISTINGPOPUP.REVIEWER)
      }
      window.scrollTo(0, 0);
    }
  };

  const decodeIfNecessary = (content) => {
    return content ? content.includes(' ') ? content : decodeURIComponent(content) : '';
  }

  const endorsed = props.tile.is_endorsed?.raw === 'true';
  let index = props.tile.index ? props.tile.index : props.index;

  return (
    <div className={`_tile ${activeCard}`} id={'tile-' + index} tabIndex="0"
      onClick={() => {
        if (showAllTags) {
          setShowAllTags(false);
          return;
        }
        viewDetails(props.tile);
      }}
      onKeyPress={(e) => {
        if (e.code === "Space" || e.code === "Enter") {
          e.preventDefault();
          viewDetails(props.tile);
        }
      }}
    >
      <div className={`card__accent ${cardColor}`}></div>
      {endorsed && (
        <img className="tile__endorsed-badge" src="/icons/endorsed-badge.svg" alt="Blue checkmark endorsed badge" />
      )}
      {locked === 'true' && !isActiveReviewer && !props.isSuperAdmin && props.resourceTab===RESOURCES.REVIEW  && (
        <img className="tile__locked-badge" src="/icons/locked-badge.svg" alt="Dark blue locked badge" />
      )}
      <div className="tile__content">
        <img src={iconSrc} className='tile__icon' alt="card icon" />
        <div className="org-bar">
          {props.tile.org_name?.raw}
        </div>
        <div className="title">
          {decodeIfNecessary(props.tile.title?.raw)}
        </div>
        <div className='resource-info'>
          {(resourceStartDate !== 'Invalid date' || !resourceStartDate) && (
            <div className="resource-start-date">{resourceStartDate} </div>
          )}
          {((resourceStartDate !== 'Invalid date' || !resourceStartDate) && resourceStartDate !== '' && (displayCost)) && (
            <span className="tile__pipe">|</span>
          )
          }
          {displayCost &&
            <div className="resource-cost">
              ${displayCost}
            </div>
          }

        </div>
        <div className="tile-footer">
        {locked === 'true' && (isActiveReviewer || props.isSuperAdmin) && (
          <div className="review-tag">{reviewTag}</div>)}
          <div className="tile-footer__content">
            <div className="topics">
              {topicList(props.tile)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tile;
