import { ANALYTICS_EVENT_URL } from "../constants";

function groupResults(results, amt) {
  const groupedResults = [];
  const completeGroupIndex = Math.floor(results.length / amt) * amt;
  for (let x = 0; x < completeGroupIndex; x += amt) {
    const line = [];
    for (let y = 0; y < amt; y++) {
      line.push(results[x + y]);
    }
    groupedResults.push(line);
  }
  if (results.length % amt) {
    const finalSet = [];
    for (let x = completeGroupIndex; x < results.length; x++) {
      finalSet.push(results[x]);
    }
    groupedResults.push(finalSet);
  }
  return groupedResults;
};

function parseJwt(token) {
  if (!token) {
    return {};
  }
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}

function deleteCookie(name) {
  if (getCookie(name)) {
    document.cookie = name + "=" +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

function createCookie(key, value) {
  let date = new Date();
  date.setMonth(date.getMonth() + 12);
  document.cookie = `${key}=${value};expires=${date};`;
}

function generateUniqueID() {
  return Math.random().toString(36).slice(-8);
}

function focusNextCardOnLoadMore(props) {
  if (props.results.length > 9) {
    const finalPageCount = props.results.length % 9 ? props.results.length % 9 : 9;
    const finalPageTiles = props.results.length - finalPageCount;
    document.getElementById(`tile-${finalPageTiles}`)?.focus();
  }
}

function triggerAnalyticsEvent(analyticsEvent) {
  fetch(ANALYTICS_EVENT_URL, {
    method: "POST",
    headers: {
      "Access-Control-Request-Headers": "*",
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(
      {
        body: analyticsEvent
      }
    ),
  }).then(reponse => reponse.json())
    .then(reponse => {
      if (JSON.parse(reponse.statusCode) !== 200)
        throw new Error()
    })
    .catch(error => console.log('Error in postListingConversions api call: ', error))
}

const utilityService = {
  groupResults,
  parseJwt,
  getCookie,
  deleteCookie,
  generateUniqueID,
  createCookie,
  focusNextCardOnLoadMore,
  triggerAnalyticsEvent
}

export default utilityService;
