import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { RESOURCE_FIELDS, RESOURCE_STATES, ROLES, TRUE } from "../../constants";
import QueryService from "../../Services/QueryService";
import utilityService from "../../Services/utilityService";
import DropDown from "../common/DropDown";
import Tile from "../Tile/Tile";
import UtilityBar from "../UtilityBar/UtilityBar";
import "./ResourceReview.css";
import { RESOURCES } from '../../constants';
import ResourceView from '../ResourceView/ResourceView';
import ResourceConfirmationView from "../ResourceConfirmationView/ResourceConfirmationView";
import DropdownField from "../common/DropdownField";
import { useFilterOrganizationsByStatus } from "../../hooks/useFilterOrganizationsByStatus";

function ResourceReview(props) {

    const navigate = useNavigate();
    const [sortChange, setSortChange] = useState(0);
    const [viewChange, setViewChange] = useState(0);
    const [createState, setCreateState] = useState(RESOURCES.MAIN);
    const [postCallType, setPostCallType] = useState('');
    const [resourceInfo, setResourceInfo] = useState({});
    const [viewField, setViewField] = useState('');
    const [organizationsWithCountList, setOrganizationsWithCountList] = useState([]);
    const [lockStatusChange, setLockStatusChange] = useState(0);
    const orgNamesWithCount = useMemo(() => getOrgNamesWithCount(props?.facetCounts), [props.facetCounts]);
    const orgNames = useMemo(() => [...orgNamesWithCount.keys()], [orgNamesWithCount.size]);
    const [activeOrganizationsWithListings] = useFilterOrganizationsByStatus(orgNames, TRUE);
    const [reviewListingPopup, setReviewListingPopup] = useState({});

    useEffect(() => {
        const viewOptions = activeOrganizationsWithListings?.org_name?.[0]?.data?.map((facet, index) => ({ label: `${facet.value} (${orgNamesWithCount.get(facet.value)})`, value: facet.value }));
        if (viewOptions) {
            viewOptions.unshift({ label: 'All Organizations', value: "" });
            setOrganizationsWithCountList(viewOptions);
        }
    }, [activeOrganizationsWithListings]);

    const queryService = QueryService({
        setter: props.setResults,
        currentData: props.results,
        pageSetter: props.setPage,
        querySetter: props.querySetter,
        totalSetter: props.totalSetter,
        sortField: props.sortField,
        organization: viewField,
        sortAscending: props.sortAscending,
        filters: props.filters,
        exclude_org: !props.isSuperAdmin && props.user.org_id,
        setPostResults: props.setPostResults,
        postResults: props.postResults,
        facetCounts: props.setFacetCounts
    });

    const sortFieldMap = {
        'Newest': RESOURCE_FIELDS.UPLOADED_DATE,
        'Oldest': RESOURCE_FIELDS.UPLOADED_DATE,
        [RESOURCE_FIELDS.UPLOADED_DATE]: { true: 'Oldest', false: 'Newest' },
        'Listing Title (A-Z)': RESOURCE_FIELDS.TITLE,
        'Listing Title (Z-A)': RESOURCE_FIELDS.TITLE,
        [RESOURCE_FIELDS.TITLE]: { true: 'Listing Title (A-Z)', false: 'Listing Title (Z-A)' },
    };

    const sortOptions = ['Newest', 'Oldest', 'Listing Title (A-Z)', 'Listing Title (Z-A)'];

    if (props.isSuperAdmin || props.isOrganizationAdmin) {
        sortFieldMap['Organizations'] = RESOURCE_FIELDS.ORGANIZATION_NAME;
        sortFieldMap[[RESOURCE_FIELDS.ORGANIZATION_NAME]] = { true: 'Organizations' };
        sortOptions.push('Organizations')
    }

    useEffect(() => {
        if (!props.authCookie) {
            navigate('/');
        } else {

        }
    }, [props.authCookie, navigate]);

    useEffect(() => {
        if (props.user.roles && !props.user.roles.includes(ROLES.REVIEWER)) {
            navigate('/');
        }
    }, [props.user]);

    useEffect(() => {
        if (props.user.roles && sortChange === 1) {
            queryService.performAdminSearch(RESOURCE_STATES.REVIEW, RESOURCE_FIELDS.ORGANIZATION_NAME);
        }
        else if (props.user.roles && sortChange > 1) {
            queryService.performAdminSearch(RESOURCE_STATES.REVIEW);
        }
    }, [sortChange]);

    useEffect(() => {
        if (props.user.roles && viewChange > 0) {
            queryService.performAdminSearch(RESOURCE_STATES.REVIEW);
        }
    }, [viewField]);

    useEffect(() => {
        if (props?.postResults?.id && lockStatusChange === 0) {
            setCreateState(RESOURCES.SUBMIT_RESOURCE_CONFIRMED);
            window.scrollTo(0, 0);
        }
        setLockStatusChange(0);
    }, [props.postResults]);

    useEffect(() => {
        utilityService.focusNextCardOnLoadMore(props);
    }, [props.results.length]);

    function fetchMoreResults() {
        queryService.fetchNextPage('', props.page, props.results, RESOURCE_STATES.REVIEW);
    }

    function getOrgNamesWithCount(facetCounts) {
        const orgNamesWithCountMap = new Map();

        facetCounts?.[0]?.forEach((orgName) => {
            if (orgName?.value && orgName?.count) {
                orgNamesWithCountMap.set(orgName.value, orgName.count);
            }
        });

        return orgNamesWithCountMap;
    }

    //TODO: update the target location below to point to proper path when available.
    function renderResult(result, groupAmt) {
        return new Array(groupAmt).fill(0, 0, groupAmt).map((n, i) => {
            return (result.length > i ? <Tile key={i} index={i} tile={result[i]} isSuperAdmin={props.isSuperAdmin} currentUserId={props.user.id} setReviewListingPopup={setReviewListingPopup} search={props.search} setResult={props.setResult} setPrevious={props.setPrevious} setCreateState={setCreateState} resourceTab={RESOURCES.REVIEW} populateFields={populateFields} setIsEditingDisabled={() => { }} targetLocation="/Admin/" setTileId={props.setTileId} tileId={props.tileId} /> : <div key={i} className="_results _tile void"></div>);
        })
    }

    const getResults = (results) => {
        let cnt = 0;
        if (!results?.length) {
            return (
                <div className="no-results">
                    No results found.
                </div>
            )
        }
        results.forEach((result) => {
            result.index = cnt++;
        });
        const groupAmt = 3;
        const groupedResults = utilityService.groupResults(results, groupAmt);
        const renderedResults = groupedResults.map((result, index) => {
            return (
                <div className="tile-row" key={index} index={index}>
                    {renderResult(result, groupAmt)}
                </div>
            )
        });
        return (
            <div>
                {renderedResults}
            </div>
        )
    }

    const handleBackToListings = () => {
        let reqBody = {
            id: resourceInfo?.id?.raw,
            lock_status: "false",
            lock_by: ""
        };
        (async () => {
            await queryService.performPostDocuments(reqBody);
            setLockStatusChange(1);
            setPostCallType('');
            setTimeout(() => {
                setCreateState(RESOURCES.MAIN);
            }, 1500);
        })();
    }

    const handleLockButton = () => {
        if (resourceInfo?.lock_status?.raw === "true" && resourceInfo?.lock_by?.raw !== props.user.id) {
            setCreateState(RESOURCES.MAIN)
            setPostCallType('');
        } else {
            let reqBody = {
                id: resourceInfo?.id?.raw,
                lock_status: "true",
                lock_by: props.user.id
            };
            queryService.performPostDocuments(reqBody);
            setLockStatusChange(1);
        }
        document.getElementById("overlay").style.display = "none";
    }

    const handleUnlockButton = () => {
        (async () => {
            if (resourceInfo?.lock_status?.raw === "true") {
                let reqBody = {
                    id: resourceInfo?.id?.raw,
                    lock_status: "false",
                    lock_by: ""
                };
                await queryService.performPostDocuments(reqBody);
            }
            setLockStatusChange(1);
            setPostCallType('');
            setTimeout(() => {
                setCreateState(RESOURCES.MAIN);
            }, 1500);
        })();
        document.getElementById("overlay").style.display = "none";
    }

    const populateFields = (result) => {
        setResourceInfo(result);
    }

    const handleTextAreaChange = (e) => {
        const value = e.target.value;
        setResourceInfo({
            ...resourceInfo,
            [e.target.name]: value.replace(/[^a-zA-Z0-9\[\]\!\@\#\$\%\^\&\*\(\)\-\_\,\.\{\}\[\]\+\=\;\:\"\'\~\`\<\>\s]/gi, '')
        });
    }

    const handleApproveResource = () => {
        let comments = resourceInfo.reviewer_comments?.raw || resourceInfo.reviewer_comments;
        let reqBody = {
            resourceId: resourceInfo?.id?.raw,
            reviewer: props?.user?.name,
            comments: comments,
            approved: true,
            endorsementRequested: resourceInfo?.endorsement_requested?.raw,
            creatorId: resourceInfo?.uploaded_by_id?.raw,
            lock_status: "false",
            lock_by: "",
        };
        setPostCallType("Approve");
        queryService.performReviewResource(reqBody);
    }

    const handleRejectResource = () => {
        let comments = resourceInfo.reviewer_comments?.raw || resourceInfo.reviewer_comments;
        let reqBody = {
            resourceId: resourceInfo?.id?.raw,
            reviewer: props?.user?.name,
            comments: comments,
            approved: false,
            endorsementRequested: resourceInfo?.endorsement_requested?.raw,
            creatorId: resourceInfo?.uploaded_by_id?.raw,
            lock_status: "false",
            lock_by: "",
        };
        setPostCallType("Reject");
        queryService.performReviewResource(reqBody);
    }

    const handleSelectDropdown = (event) => {
        setViewField(event.value);
        setViewChange(viewChange + 1);
    }

    return (
        <>
            <div className="_review">
                <div className="_review__content">
                    {props.authCookie &&
                        <>
                            <div className="main">
                                {createState !== RESOURCES.SUBMIT_RESOURCE_CONFIRMED && (
                                    <>
                                        <div className="utility">
                                            <UtilityBar logoLink={props.logoLink} setLogoLink={props.setLogoLink} user={props.user} organizations={props.organizations} setListingsViewsCounts={props.setListingsViewsCounts} listingsViewsCounts={props.listingsViewsCounts} listingsPublishedCount={props.listingsPublishedCount} setListingsPublishedCount={props.setListingsPublishedCount} />
                                        </div>
                                        {createState === RESOURCES.MAIN &&
                                            <div className="content">
                                                <div className="page-title">Review Listings</div>
                                                <div className="results-control">
                                                    <div className="_dropdown-wrapper">
                                                        <span className="label">{`View:`}</span>
                                                        <DropdownField
                                                            id={"review-view"}
                                                            options={organizationsWithCountList}
                                                            onChange={handleSelectDropdown}
                                                            placeholder="Select Organization"
                                                            isRequired={false}
                                                        />
                                                    </div>
                                                    <div className="sort">
                                                        <DropDown label="Sort by" options={sortOptions} toggleAscending={true} ascending={props.sortAscending} setAscending={props.setSortAscending} field={props.sortField} setField={props.setSortField} fieldMap={sortFieldMap} change={sortChange} setChange={(val) => setSortChange(val)} id="review" />
                                                    </div>
                                                </div>
                                                <div className="results">
                                                    {getResults(props.results)}
                                                </div>
                                                {props.page < props.total &&
                                                    (
                                                        <div className="load-more">
                                                            <div className="void"></div>
                                                            <button className="btn-secondary" onClick={() => fetchMoreResults()}>Load More</button>
                                                            <div className="void"></div>
                                                        </div>
                                                    )
                                                }


                                            </div>
                                        }
                                        {createState === RESOURCES.REVIEW && (
                                            <div className="content">
                                                <div className="back" tabIndex="0"
                                                    onClick={() => handleBackToListings()}
                                                    onKeyPress={(e) => {
                                                        if (e.code === "Space" || e.code === "Enter") {
                                                            e.preventDefault();
                                                            handleBackToListings();
                                                        }
                                                    }}
                                                >
                                                    {"< Back to Listings"}
                                                </div>
                                                <div className="review-title">
                                                    <img className="tile__locked-badge" src="/icons/locked-badge.svg" alt="Dark blue locked badge" />
                                                    <div className="title">Review Listing</div>
                                                </div>
                                                <div className="sub-content">Please leave all feedback in the comments field at the bottom of the form.
                                                    All fields required unless otherwise stated.
                                                </div>
                                                <hr className="divider" />
                                                <ResourceView data={resourceInfo} handleTextAreaChange={handleTextAreaChange} handleApproveResource={handleApproveResource} handleRejectResource={handleRejectResource} handleBackToListings={handleBackToListings} postCallType={postCallType} />
                                            </div>
                                        )
                                        }
                                    </>
                                )}
                            </div>

                            {createState === RESOURCES.SUBMIT_RESOURCE_CONFIRMED && (
                                <ResourceConfirmationView handleBackToListings={handleBackToListings}
                                    heading={`The listing has been ${postCallType === "Approve" ? "approved" : "rejected"}`}
                                    subTextOne={'An email has been sent to creator of the listing informing about the decision.'}
                                    subTextTwo={'Click on the button below to go back to dashboard.'}
                                    postResults={props?.postResults}
                                />
                            )
                            }
                        </>
                    }
                </div>
            </div>
            <div id="overlay">
                <div id="review_listing_popup" className="review_listing_popup">
                    <div className="review_listing_popup_content">
                        <div className="review_listing_popup_title">
                            {reviewListingPopup.popup_title}
                        </div>
                        <div className="review_listing_popup_sub_title">
                            <span>{reviewListingPopup.sub_title1}</span>
                            <span>{reviewListingPopup.sub_title2}</span>
                        </div>
                        <button
                            className="btn-secondary"
                            id="continue_review"
                            onClick={() => handleLockButton()}>
                            {reviewListingPopup.lock_button}
                        </button>
                        <button
                            className="btn-secondary alternate"
                            id="unlock"
                            onClick={() => handleUnlockButton()}>
                            {reviewListingPopup.unlock_button}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(ResourceReview);
