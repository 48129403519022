import { useEffect, useState, useMemo } from 'react';
import { LOGIN_URL, RESET_PASSWORD_URL, ROLE_MAP, ROLES } from "../../constants";
import './Login.css';
import utilityService from "../../Services/utilityService";
import { useNavigate } from "react-router-dom";

const Field = ({ label, type, placeholder, value, onChange, errorMessage, id, onClick, showForgotPasswordText }) => {
    return (
        <div className="_login__form_row">
            <label className="_login__form_label" htmlFor={id}>{label}</label>
            <input className="_login__form_input" type={type} placeholder={placeholder} value={value} onChange={onChange} id={id} />
            {errorMessage &&
                <p className="_change_password__form_error">{errorMessage}</p>
            }
            {onClick &&
                <button type='button' className='_login__forgot_password' onClick={onClick}>forgot password?</button>
            }
            {showForgotPasswordText &&
                <div className='_login__forgot_password__text'>An email has been sent to your account address. Please try again in a few minutes if you haven't received the email.</div>
            }
        </div>
    );
};
const Form = ({ onSubmit, errorMessage, setErrorMessage }) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPasswordText, setShowForgotPasswordText] = useState(false);

    useEffect(() => {
        if (email) {
            setErrorMessage("");
        }
        if (email === '') {
            setShowForgotPasswordText(false);
        }
    }, [email]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const data = {
            body: {
                email: email,
                password: password
            }
        };
        onSubmit(data);
    };

    const handleForgotPassword = () => {
        if (showForgotPasswordText) {
            return;
        }
        if (!email) {
            setErrorMessage("Please enter an email address to reset password.");
        } else {
            setShowForgotPasswordText(true);
            const payload = {
                "body": {
                    "email": email
                }
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Access-Control-Request-Headers': '*',
                    'Content-type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(payload)
            }
            fetch(RESET_PASSWORD_URL, requestOptions)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Field
                onChange={handleEmailChange}
                value={email}
                label="Email Address"
                type="email"
                placeholder="Enter email address"
                id="emailAddress"
            />
            <Field
                onChange={handlePasswordChange}
                errorMessage={errorMessage}
                onClick={handleForgotPassword}
                showForgotPasswordText={showForgotPasswordText}
                value={password}
                label="Password"
                type="password"
                placeholder="Enter password"
                id="password"
            />
            <button className="_login__form_submit" disabled={!email || !password} type="submit">Sign In</button>
        </form>
    );
}

function Login(props) {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const isSuperAdmin = useMemo(() => props?.user?.roles?.includes(ROLES.SUPERADMIN), [props?.user?.roles]);
    const isOrganizationAdmin = useMemo(() => props?.user?.roles?.includes(ROLES.ORGANIZATION_ADMIN) && !props?.user?.roles?.includes(ROLES.SUPERADMIN), [props?.user?.roles]);

    useEffect(() => {
        if (props.authCookie && props.user?.roles) {
            const defaultTab = (isSuperAdmin || isOrganizationAdmin) ? 3 : 0
            navigate(`/Admin/${ROLE_MAP[props.user.roles[defaultTab]]}`);
        }
    });

    const handleSubmit = data => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Request-Headers': '*',
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data)
        }
        fetch(LOGIN_URL, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // error state
                if (data.body.message) {
                    setErrorMessage(data.body.message);
                }

                if (data.body.token) {
                    setErrorMessage("");

                    // Adding jwt to a cookie, set expiration for 1 year
                    utilityService.createCookie('auth', data.body.token);

                    // Parse JWT
                    const parsedJwt = utilityService.parseJwt(data.body.token);

                    // Set username
                    props.setUser(parsedJwt);

                    // Redirect to change password on first login
                    if (parsedJwt.resetRequired === 'true') {
                        navigate('/Admin/ChangePassword');
                    }
                }
            })
    };

    return (
        <>
            <div className="_login">
                <h1 className="_login__header">Sign In</h1>
                <hr className='_login__hr' />
                <Form onSubmit={handleSubmit} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
            </div>
        </>
    );
};

export default Login;
