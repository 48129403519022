import React, { useState } from "react";
import "./Home.css";
import Tile from "../Tile/Tile";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import utilityService from "../../Services/utilityService";

function Home(props) {

  const newContentSource = props.referrer && props.organizations.filter(org => org.website_link === props.referrer)[0]?.org_name;
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    props.setFilters([]);
  }, [])

  useEffect(() => {
    if (props.filterChange > 0) {
      navigate('/results');
    }
  }, [props.filterChange]);

  useEffect(() => {
    setIsMobile(props.windowSize < 601);
  }, [props.windowSize]);

  function renderResult(result, groupAmt) {
    return new Array(groupAmt).fill(0, 0, groupAmt).map((n, i) => {
      return (result.length > i ? <Tile key={i} index={i} tile={result[i]} search={props.search} setResult={props.setResult} setPrevious={props.setPrevious} targetLocation="/details/" setTileId={props.setTileId} tileId={props.tileId} /> : <div key={i} className="_tile void"></div>);
    })
  }

  function getTiles(content) {
    const stdTiles = utilityService.groupResults(utilityService.groupResults(content, 2), 2);
    const intermediaryTiles = utilityService.groupResults(content, 3);
    const stdTileSet = stdTiles.map((tileRow, index) => {
      const currentTileSets = tileRow.map((tileSet, subIndex) => {
        const currentTiles = renderResult(tileSet, 2);
        return <div className="tile-group" key={subIndex}>{currentTiles}</div>;
      });
      return <div className="tile-row" key={index}>{currentTileSets}</div>;
    })
    const intermediaryTileSet = intermediaryTiles.map((tileRow, index) => {
      const currentTiles = renderResult(tileRow, 3)
      return <div className="intermediary-tile-row" key={index}>{currentTiles}</div>;
    });
    return (
      <div className="tiles-main" id='new-content-tiles'>
        {stdTileSet}
        {intermediaryTileSet}
      </div>
    )
  }

  const doSearch = () => {
    const query = document.getElementById("homepage-searchbar").value;
    props.search(query);
  }

  const handleViewAllListings = () => {
    props.search('');
    navigate('/results');
  }

  return (
    <div className="_home">
      <div className="main" style={{ backgroundImage: isMobile ? `url("/graphics/Home_bg_mobile.png")` : `url("/graphics/Home_bg_desktop.png")` }}>
        <div className="main-content">
          <div className="summary" id="home-summary-title">
            Welcome to the <br />Ohio Professional Learning Directory
          </div>
          <div className="summary-detail" >
            <div className="summary-detail__content" id="home-summary-detail">
              A curated catalog of professional learning resources <br />
              providing educators with more choice and content providers with better
              access to those seeking professional learning across the state of Ohio.
            </div>
          </div>
          <div className="query">
            <label htmlFor="homepage-searchbar" />
            <div className="search-bar-wrapper">
              <input
                className="search-bar"
                id="homepage-searchbar"
                name="search"
                type="text"
                placeholder="Search listings..."
                onFocus={() => {
                  if (props.backDrop) {
                    props.setBackDrop(!props.backDrop)
                  }
                }
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    props.search(e.target.value);
                  }
                }}
              />
              <button id="homepage-submit" className="query-button" onClick={() => doSearch()} target="search"><img src="/icons/search.svg" alt="magnifying glass" /></button>
            </div>
          </div>
        </div>
        <img className={isMobile ? "home-graphic mobile-graphic" : "home-graphic desktop-graphic"} src={isMobile ? "/graphics/Home_Graphic_mobile.svg" : "/graphics/Home_Graphic_desktop.svg"} alt="woman reading" />
      </div>
      <div className="new-content">
        <div className="new-content-header">
          <h4>Explore the latest offerings {newContentSource && `from ${newContentSource}`}</h4>
          <div className="view-all">
            <button className="btn-secondary" id="view-all-new-content" onClick={handleViewAllListings}>View All Listings</button>
          </div>
        </div>
        {getTiles(props.newContent)}
        <div className="mobile-only">
          <button className="btn-secondary" id="view-all-new-content" onClick={handleViewAllListings}>View More</button>
        </div>
      </div>
    </div>
  );
}

export default Home;
