import { CREATE_USER_URL, DEACTIVATE_USER_URL, USER_PAGE_SIZE, USER_URL, ROLES } from "../constants";
const UsersService = (props) => {
    const displayUsers = (body, isInitial, currentResults) => {
        if (isInitial) {
            props.setUserResults(body.results);
            props.pageTotalSetter(body.meta?.page?.total_pages);
            props.userResultsTotalSetter(body.meta?.page?.total_results);
        } else {
            displayContinuedUsers(currentResults, body.results, body.meta?.page)
        }
    }
    const handleUsersError = (err) => {
        //TODO: handle fetch users error
    };
    const displayContinuedUsers = (current, res, page) => {
        while (res.length) {
            current.push(res.shift());
        }
        props.setUserResults(current);
        props.pageSetter(page?.current);
        props.pageTotalSetter(page?.total_pages);
    }

    const postResultsData = (res) => {
        if (res.statusCode) {
            props.setPostResults(res?.body?.message);
        } else {
            props.setPostResults(res[0]);
        }
    }

    const updateRequestWithFilters = (requestBody, filters) => {
        const filter =
        {
            "all": filters
        };

        if (props?.isOrganizationAdmin) {
            filter.none = [
                {
                    site_roles: ROLES.SUPERADMIN
                }
            ]
        }

        requestBody.filters = filter;
    }

    const userService = {
        fetchUsers: (page = 1, results = 1000, isAsc = true, isInitial = true, currentResults = [], sortField) => {

            const requestBody = {
                query: "",
                page: {
                    current: page,
                    size: results,
                },
                sort: {
                    [sortField]: isAsc ? 'asc' : 'desc',
                },
            }

            props?.filters && updateRequestWithFilters(requestBody, props?.filters)


            fetch(USER_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(
                    {
                        body: requestBody
                    }
                )
            })
                .then((response) => response.json())
                .then((data) => displayUsers(JSON.parse(data.body), isInitial, currentResults))
                .catch((e) => {
                    handleUsersError(e);
                });
        },
        fetchSomeUsers: (isAsc, currentResults, sortField) => {
            userService.fetchUsers(1, USER_PAGE_SIZE, isAsc, true, currentResults, sortField);
        },
        fetchMoreUsers: (page, isAsc, currentResults, sortField) => {
            userService.fetchUsers(page + 1, USER_PAGE_SIZE, isAsc, false, currentResults, sortField);
        },
        performPostUser: async (requestBody) => {
            try {
                const response = await fetch(CREATE_USER_URL, {
                    method: "POST",
                    headers: {
                        "Access-Control-Request-Headers": "*",
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify(
                        {
                            body: requestBody
                        }
                    ),
                })
                const data = await response.json();
                postResultsData(JSON.parse(data.body)) 
            } catch (e) {
                handleUsersError(e);
            }

        },
        performDeactivateUser: (requestBody) => {
            fetch(DEACTIVATE_USER_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(
                    {
                        body: requestBody
                    }
                ),
            })
                .then((response) => response.json())
                .then((data) => postResultsData(JSON.parse(data.body)))
                .catch((e) => {
                    handleUsersError(e);
                });

        },
    };

    return userService;
};

export default UsersService;
