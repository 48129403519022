import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import QueryService from '../../Services/QueryService';
import './Details.css';
import moment from 'moment';
import utilityService from "../../Services/utilityService"
import { EXIT_LINK, LISTING_VISITED } from '../../constants';

function Details(props) {
  let { docID } = useParams();
  const navigate = useNavigate();
  const [shareText, setShareText] = useState('Share');
  const [currentLogo, setCurrentLogo] = useState('');
  const [copiedState, setCopiedState] = useState(false);
  const [org, setOrg] = useState(null)

  useEffect(() => {
    const queryService = QueryService({
      setter: props.setResult,
      currentData: props.details
    })
    queryService.performDocumentSearch(docID)

  }, [docID, props.setResult]);

  useEffect(() => {
    getCurrentLogo();
  }, [props.organizations]);
  //Do not add props.details to the dependency array above as it is updated via performDocumentSearch

  function navResults() {
    if (props.previous === '/') {
      props.setFilterChange(0);
      navigate(props.previous);
    } else {
      props.setPageUpdate(true);
      navigate(props.previous);
    }
  }

  const getCurrentLogo = () => {
    const org = props.organizations.filter((org) => org.id?.raw === props.details.org_id?.raw)[0];
    setOrg(org)
    const logo = org?.logo_link?.raw
    if (logo) {
      setCurrentLogo(logo);
    }
  }
  return (
    <div className="_details">
      <div className="_details__wrapper">
        <div className="toolbar">
          <div className="to-results" id="back-to" tabIndex="0"
            onClick={() => navResults()}
            onKeyPress={(e) => {
              if (e.code === "Space" || e.code === "Enter") {
                e.preventDefault();
                navResults();
              }
            }}
          >
            {`< Back To ${props.previous === '/results' ? 'Results' : 'Home'}`}
          </div>
        </div>
        <div className="_details__content">
          <Summary details={props.details} gtag={props.gtag} currentLogo={currentLogo} shareText={shareText} setShareText={setShareText} copiedState={copiedState} setCopiedState={setCopiedState} org={org} />
          <Source details={props.details} currentLogo={currentLogo} />
        </div>
      </div>
    </div>
  );
}

const decodeIfNecessary = (content) => {

  return content ? content.includes(' ') ? content : decodeURIComponent(content) : '';
};



function Source(props) {

  return (
    <div className="source">
      <div className="source-details">
        <div className={`source-heading-section-${props.details?.media_type?.raw?.toLowerCase()}`}>
          <div className='heading-content'>
            <div className="event-type">
              <img src={`/icons/${props.details?.media_type?.raw?.toLowerCase()}.svg`} alt={props.details?.media_type?.raw?.toLowerCase()} />
              <span className="heading-item-title">{props.details?.media_type?.raw}</span>
            </div>
            <div className='date-cost'>
              {props.details.starting_date?.raw && (
                <div className="heading-item-date" id='start-date'>{props.details.starting_date?.raw && moment(props.details.starting_date?.raw).format('ll')}</div>
              )}
              <div className="heading-item" id='cost'>{props.details.cost_display?.raw ? `$${props.details.cost_display?.raw}` : ""}</div>
            </div>
          </div>

        </div>
        <div className='source-content'>
          <div className="sections">
            <div className='section'><Metadata data={props.details.topic?.raw} name="TOPIC" /></div>
            <div className="section"><Metadata data={props.details.audience?.raw} name="AUDIENCE" /></div>
            <div className="section-last">
              <div className='grade-span'>
                <Metadata data={props.details.grade_span?.raw} name="GRADE SPAN" />
              </div>
              <div className="credit">
                <Metadata data={props.details.credit_hours?.raw} name="CREDIT HOURS" />
                <Metadata data={props.details.completion_credit?.raw} name="CREDIT TYPE" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

function Metadata(props) {
  return (
    <>
      {props?.data?.length > 0 && (
        <div id={props.name.split(' ').join("-").toLowerCase() + "-details"} className="metadata">
          <div className="label">{props.name}</div>
          {getMetadata(props.data)}
        </div>
      )
      }
    </>
  );
}

function getMetadata(data) {
  const metadata = [];
  if (typeof (data) === 'object') {
    data.forEach((line, index) => {
      metadata.push(<div key={index} className="item">{line}</div>);
    });
  } else {
    metadata.push(<div key="i" className="item">{data}</div>)
  }
  return metadata;
}

function copyLink() {
  const link = document.location.href;
  navigator.clipboard.writeText(link)
}

function Summary(props) {

  const getAnalyticsEventPayload = () => {
    const analyticsEvent = {
      "org_id": props?.details?.org_id?.raw,
      "org_name": props?.details?.org_name?.raw,
      "org_type": props?.org?.org_type?.raw,
      "listing_id": props?.details?.id?.raw,
      "listing_name": props?.details?.title?.raw,
      "listing_mediatype": props?.details?.media_type?.raw,
      "listing_event_type": "",
      "event_date": "",
      "event_user": ""
    };

    return analyticsEvent;
  }

  useEffect(() => {
    props.gtag('event', 'listings_visited', { 'organization_name': props.details.org_name?.raw, 'title': props.details.title?.raw });
  }, []);

  useEffect(() => {
    if (!props?.org) return;

    const analyticsEvent = {
      ...getAnalyticsEventPayload(),
      "listing_event_type": LISTING_VISITED,
      "event_date": new Date().toISOString(),
    };
    utilityService.triggerAnalyticsEvent(analyticsEvent)
  }, [props?.org]);

  const endorsed = props.details.is_endorsed?.raw === 'true';
  let summary = (
    <div className="summary">
      <div className='title-section'>
        <div className='logo-container'>
          {props.currentLogo &&
            <div className="container">
              <img src={props.currentLogo} alt="org logo" />
            </div>
          }
          <span className='orgName'><span className="creatorTag">Creator</span><br />
            {props.details.org_name?.raw}</span>
        </div>
      </div>

      <div className="title">{decodeIfNecessary(props.details.title?.raw)}</div>
      {endorsed &&
        <div className="util">
          <div className="util-left">
            <div className="endorsed"><img src="/icons/endorsed.svg" alt="endorsed badge" />
              Endorsed
            </div>
            <div className="rating"></div>
          </div>
        </div>
      }
      <div className="description">
        <div id='long_description' dangerouslySetInnerHTML={{ __html: decodeIfNecessary(props.details.long_description?.raw) }}></div>
        {(decodeIfNecessary(props.details.learning_objectives?.raw) || props.details.oh_teaching_standards?.raw) && <hr className='divider' />}
        {props.details.learning_objectives?.raw &&
          <div className="sub-section" id='learning_objectives'>
            <div className="label">LEARNING OBJECTIVES</div>
            <div className="learning-objectives-text">{decodeIfNecessary(props.details.learning_objectives?.raw)}</div>
          </div>
        }
        {props.details.oh_teaching_standards?.raw?.length > 0 &&
          <div className="sub-section" id='teaching_standards'>
            <div className="label">TEACHING STANDARDS</div>
            {typeof props.details.oh_teaching_standards?.raw === "string" ? (
              <div className="">{props?.details?.oh_teaching_standards?.raw}</div>
            ) : (
              <>
                {props?.details?.oh_teaching_standards?.raw?.map((ele, key) => {
                  return <ol className='teaching-standards' key={key}>
                    <span className='list-item'>{key + 1}</span >.<span className='list-item-text'>{ele}</span>
                  </ol>
                })}
              </>
            )}
          </div>
        }
      </div>

      <div className='buttons-section'>
        <a className="listing"
          onClick={() => {
            props.gtag('event', 'exit_link', { 'organization_name': props.details.org_name?.raw, 'title': props.details.title?.raw, 'destination': props.details.resource_url?.raw });
            const analyticsEvent = {
              ...getAnalyticsEventPayload(),
              "listing_event_type": EXIT_LINK,
              "event_date": new Date().toISOString(),
            };
            utilityService.triggerAnalyticsEvent(analyticsEvent)
          }}
          href={props.details.resource_url?.raw} target="_blank" rel="noreferrer"
          onKeyPress={(e) => {
            if (e.code === "Space") {
              e.preventDefault();
              props.gtag('event', 'exit_link', { 'organization_name': props.details.org_name?.raw, 'title': props.details.title?.raw, 'destination': props.details.resource_url?.raw });
              window.open(props.details.resource_url?.raw, '_blank');
            }
          }}
        ><span>Go to Listing</span></a>
        <div id='share-btn' className={props.copiedState ? "share copied" : "share"} tabIndex="0"
          onMouseDown={() => {
            copyLink();
            props.setCopiedState(true);
            props.setShareText('Copied to clipboard');
          }}
          onMouseUp={() => {
            setTimeout(() => {
              props.setCopiedState(false);
              props.setShareText('Share');
            }, 3000);
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              props.setCopiedState(false);
              props.setShareText('Share');
            }, 3000);
          }
          }
          onKeyDown={(e) => {
            if (e.code === "Space" || e.code === "Enter") {
              e.preventDefault();
              copyLink();
              props.setCopiedState(true);
              props.setShareText('Copied to clipboard');
              setTimeout(() => {
                props.setCopiedState(false);
                props.setShareText('Share');
              }, 3000);
            }
          }}
        >
          {props.shareText === 'Share' &&
            <img src="/icons/share.svg" alt="share icon" />
          }
          {props.shareText}
        </div>
      </div>
    </div>
  );

  return summary;
}

export default Details;
