const BASIC_SEARCH_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/search`
const DOCUMENT_SEARCH_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/getDocuments`;
const POST_DOCUMENTS_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/postDocuments`;
const UNPUBLISH_RESOURCE_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/unpublishResource`;
const NEW_CONTENT_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/getLatestResources`;
const ORGANIZATIONS_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/getOrganizations`;
const DEFAULT_LOGO_URL = process.env.REACT_APP_DEFAULT_LOGO_URL;
const CHANGE_PASSWORD_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/changePassword`;
const RESET_PASSWORD_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/resetPassword`;
const LOGIN_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/login`;
const USER_SURVEY_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/submitUserSurvey`;
const REVIEW_RESOURCE_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/reviewResource`;
const ENDORSE_RESOURCE_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/endorseResource`;
const DOCUMENT_UPLOAD_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/documentUpload`;
const IMAGE_UPLOAD_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/imageUpload`;
const CREATE_USER_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/postUser`;
const DEACTIVATE_USER_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/activateUser`;
const DEACTIVATE_ORG_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/activateOrganization`;
const CREATE_ORG_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/postOrganization`;
const RESOURCE_EXPORT_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/elasticsearchDataExport`;
const PROVIDER_REPORT_EXPORT_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/providerReportExport`;
const USER_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/getUsers`;
const RESOURCE_UPLOAD_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/fileParserJson`;
const ANALYTICS_EVENT_URL = `${process.env.REACT_APP_APIG_ROOT_URL}/postListingConversions`;
const TOOL_USER_GUIDE_URL = `${process.env.REACT_APP_HELP_DOCS_ROOT_URL}/Tool+User+Guide.pdf`;
const LISTING_CRITERIA_URL = `${process.env.REACT_APP_HELP_DOCS_ROOT_URL}/Listing+Criteria.pdf`;
const ENDORSEMENT_CRITERIA_URL = `${process.env.REACT_APP_HELP_DOCS_ROOT_URL}/Endorsement+Criteria.pdf`;
const USER_GUIDE_URL = `${process.env.REACT_APP_HELP_DOCS_ROOT_URL}/OPLD%E2%80%93UserGuide.pdf`;
const RESOURCE_TEMPLATE = `${process.env.REACT_APP_HELP_DOCS_ROOT_URL}/Oesca+Template+for+Listing+Upload.csv`;

const ORGANIZATION_PAGE_SIZE = 20;
const USER_PAGE_SIZE = 20;

const ANALYTICS_TAG_IDS = {
    STAGE: 'G-CR2HDTYT51',
    PROD: 'G-183G8Q06FB',
}

const ENV_DOMAINS = {
    PROD: `https://n468w6hg3l.execute-api.us-east-2.amazonaws.com/prod`,
    STAGE: `https://62g88z5ikg.execute-api.us-east-2.amazonaws.com/stg`,
    DEV: "https://0u2jsw6jn7.execute-api.us-east-2.amazonaws.com/dev"
};

const RESOURCE_STATES = {
    PUBLISHED: 'published',
    UNPUBLISHED: 'unpublished',
    REVIEW: 'submitted_review',
    ENDORSE: 'submitted_endorsement',
    REJECTED_REVIEW: 'rejected_review',
    REJECTED_ENDORSEMENT: 'rejected_endorsement',
    ENDORSED: 'endorsed'
};

const SEARCHABLE_STATES = [
    RESOURCE_STATES.PUBLISHED,
    RESOURCE_STATES.ENDORSE,
    RESOURCE_STATES.REJECTED_ENDORSEMENT,
    RESOURCE_STATES.ENDORSED
];

// content provider multifilter
const CONTENT_PROVIDER = "CONTENT PROVIDER";
const MULTIPLE_SELECTED = "Multiple Selected";
const TRUE = "true";

// analytics event
const LISTING_VISITED = "listing_visited"
const LISTING_CREATED = "listing_created"
const EXIT_LINK = "exit_link"


//TODO: Uncomment endorsed only when endorsements are ready..
const switchFilters = [/*'ENDORSED ONLY'*/];
const multiFilters = {
    'AUDIENCE': [
        'Teachers',
        'Coaches & Mentors',
        'School Counselors & Social Workers',
        'Principals',
        'Administrators',
        // 'ESCs (login required)',
    ],
    'MEDIA TYPE': [
        'Recording',
        'Document',
        // '['Event', 'Starting Soon', 'Expiring Soon'],' event filter consolidated
        'Event',
        'Course'
    ],
    'TOPIC': [
        'Assessment & Data Use',
        'Career & Workforce Development',
        'Classroom Management',
        'Coaching & Mentoring',
        'Culturally Responsive Instructions',
        'Differentiation',
        'Digital Literacy & Technology Integration',
        'Diversity, Equity, Inclusion',
        'Early Childhood',
        'English Language Learners',
        'Gifted Education',
        'High-Quality Instructional Materials',
        'Instructional Planning',
        'Leadership',
        'Literacy / Dyslexia',
        'Mental Health, Wellness & Safety',
        'Online & Blended Teachings',
        'Parent & Community Engagement',
        'Personalized Learning',
        'Project-based Learning',
        'Social Emotional Learning',
        'Special Education',
        'State Required',
        'Teaching in Content Areas',
        'Other'
    ],
    'GRADE SPAN': [
        'PreK',
        'Elementary',
        'Middle',
        'High',
    ],
    'CREDIT TYPE': [
        'CEUs',
        'Graduate Credit',
        'Contact/Clock Hours',
        'Certificate',
        'Badge',
    ]
}

const MEDIA_TYPE_FILTERS = [
    'Recording',
    'Document',
    'Event',
    'Course'
]

const MEDIA_TYPE_OPTIONS = [
    { value: "Recording", label: "Recording (Audio, Video, Recorded webinar)" },
    { value: "Document", label: "Document (doc, xls, ppt, pdf, etc.)" },
    { value: "Event", label: "Event (webinar, conference, workshop, etc.)" },
    { value: "Course", label: "Course: Facilitated, Blended, Self-paced" }
]

const AUDIENCE_OPTIONS = [
    { value: "Teachers", label: "Teachers" },
    { value: "Coaches & Mentors", label: "Coaches & Mentors" },
    { value: "School Counselors & Social Workers", label: "School Counselors & Social Workers" },
    { value: "Principals", label: "Principals" },
    { value: "Administrators", label: "Administrators" },
    { value: "ESCs", label: "ESCs" }
]

const TOPIC_OPTIONS = [
    { value: "Assessment & Data Use", label: "Assessment & Data Use" },
    { value: "Career & Workforce Development", label: "Career & Workforce Development" },
    { value: "Classroom Management", label: "Classroom Management" },
    { value: "Coaching & Mentoring", label: "Coaching & Mentoring" },
    { value: "Culturally Responsive Instruction", label: "Culturally Responsive Instruction" },
    { value: "Differentiation", label: "Differentiation" },
    { value: "Digital Literacy & Technology Integration", label: "Digital Literacy & Technology Integration" },
    { value: "Diversity, Equity, Inclusion", label: "Diversity, Equity, Inclusion" },
    { value: "Early Childhood", label: "Early Childhood" },
    { value: "English Language Learners", label: "English Language Learners" },
    { value: "Gifted Education", label: "Gifted Education" },
    { value: "High-Quality Instructional Materials", label: "High-Quality Instructional Materials" },
    { value: "Instructional Planning", label: "Instructional Planning" },
    { value: "Leadership", label: "Leadership" },
    { value: "Literacy / Dyslexia", label: "Literacy / Dyslexia" },
    { value: "Mental Health, Wellness & Safety", label: "Mental Health, Wellness & Safety" },
    { value: "Online & Blended Teaching", label: "Online & Blended Teaching" },
    { value: "Parent & Community Engagement", label: "Parent & Community Engagement" },
    { value: "Personalized Learning", label: "Personalized Learning" },
    { value: "Project-based Learning", label: "Project-based Learning" },
    { value: "Social Emotional Learning", label: "Social Emotional Learning" },
    { value: "Special Education", label: "Special Education" },
    { value: "State Required", label: "State Required" },
    { value: "Teaching in Content Areas", label: "Teaching in Content Areas" },
    { value: "Other", label: "Other" }
]

const GRADE_SPAN_OPTIONS = [
    { value: "PreK", label: "PreK" },
    { value: "Elementary", label: "Elementary" },
    { value: "Middle", label: "Middle" },
    { value: "High", label: "High" }
]

const CREDIT_TYPE_OPTIONS = [
    { value: "CEUs", label: "CEUs" },
    { value: "Graduate Credit", label: "Graduate Credit" },
    { value: "Contact/Clock Hours", label: "Contact/Clock Hours" },
    { value: "Certificate", label: "Certificate" },
    { value: "Badge", label: "Badge" }
]

const OH_TEACHING_STANDARD_OPTIONS = [
    { value: "Teachers understand student learning and development and respect the diversity of the students they teach.", label: "Teachers understand student learning and development and respect the diversity of the students they teach." },
    { value: "Teachers know and understand the content area for which they have instructional responsibility.", label: "Teachers know and understand the content area for which they have instructional responsibility." },
    { value: "Teachers understand and use varied assessments to inform instruction, evaluate and ensure student learning.", label: "Teachers understand and use varied assessments to inform instruction, evaluate and ensure student learning." },
    { value: "Teachers plan and deliver effective instruction that advances the learning of each individual student.", label: "Teachers plan and deliver effective instruction that advances the learning of each individual student." },
    { value: "Teachers create learning environments that promote high levels of learning and achievement for all students.", label: "Teachers create learning environments that promote high levels of learning and achievement for all students." },
    { value: "Teachers collaborate and communicate with students, parents, other educators, administrators and the community to support student learning.", label: "Teachers collaborate and communicate with students, parents, other educators, administrators and the community to support student learning." },
    { value: "Teachers assume responsibility for professional growth, performance and involvement as an individual and as a member of a learning community.", label: "Teachers assume responsibility for professional growth, performance and involvement as an individual and as a member of a learning community." }

]

const RESOURCES = {
    CREATE_RESOURCE: 0,
    SUBMIT_RESOURCE: 1,
    MAIN: 2,
    REVIEW: 3,
    ENDORSE: 4,
    SUBMIT_RESOURCE_CONFIRMED: 5
}

const ORGANIZATION_FIELDS = Object.freeze({
    ORGANIZATION_TYPE: "org_type",
    ORGANIZATION_NAME: "org_name"
})

const RESOURCE_FIELDS = {
    PUBLISHED_DATE: 'published_date',
    UPLOADED_DATE: 'uploaded_date',
    SCORE: '_score',
    ORGANIZATION_NAME: 'org_name',
    COST: 'cost',
    TITLE: 'title',
    RESOURCE_STATE: 'resource_state'
};

// Add any fields being used to sort here in order to have their ascending and descending flipped
//(e.g. A high _score is desired when sorting by most relevant).
const DESCENDING_DESIRED_RESOURCE_FIELDS = [
    RESOURCE_FIELDS.SCORE,
]

const RESOURCE_STATE = {
    VIEW: "view",
    MODIFY: 'modify',
    REVIEW: 'review',
    ENDORSE: 'endorse'
}

const NON_ADMIN_ROLES = [
    'creator',
    'reviewer',
    'endorser',
    'organization-admin'
]

const ROLES = {
    CREATOR: 'creator',
    REVIEWER: 'reviewer',
    ENDORSER: 'endorser',
    ADMIN: 'admin',
    ORGANIZATION_ADMIN: "organization-admin",
    SUPERADMIN: 'super-admin',
};

const SUPER_ADMIN = "Super-Admin"

const ROLE_MAP = {
    [ROLES.CREATOR]: 'Create',
    [ROLES.REVIEWER]: 'Review',
    [ROLES.ENDORSER]: 'Endorse',
    [ROLES.ORGANIZATION_ADMIN]: 'Manage',
    [ROLES.SUPERADMIN]: 'Manage',
};

const MANAGE_STATE = {
    MAIN: 0,
    CREATE_USER: 1,
    MODIFY_USER: 2,
    SUBMIT_USER: 3,
    CREATE_ORG: 4,
    MODIFY_ORG: 5,
    SUBMIT_ORG: 6,
};

const USER_ROLE_MAP = {
    'Creator': 'creator',
    'creator': 'Creator',
    'Reviewer': 'reviewer',
    'reviewer': 'Reviewer',
    'Endorser': 'endorser',
    'endorser': 'Endorser',
    "organization-admin": "Org-Admin",
    'Super Admin': 'super-admin',
    'super-admin': 'Super Admin',
    'super admin': 'super-admin',
};

const MODIFY_RESOURCE_STATES = ["rejected_review", "rejected_endorsement", "unpublished"];
const UNPUBLISH_RESOURCE_STATES = ["published", "submitted_endorsement", "rejected_endorsement", "endorsed"];

const CARD_TYPES = {
    Document: {
        'icon': '/icons/card-icon-document.svg',
        'class': 'card-document-color'
    },
    Course: {
        'icon': '/icons/card-icon-course.svg',
        'class': 'card-course-color'
    },
    Recording: {
        'icon': '/icons/card-icon-recording.svg',
        'class': 'card-recording-color'
    },
    Event: {
        'icon': '/icons/card-icon-event.svg',
        'class': 'card-event-color'
    }
}

const LOCKED_CARD_TYPES = {
    Document: {
        'icon': '/icons/locked-card-icon-document.svg',
        'class': 'locked-card-color'
    },
    Course: {
        'icon': '/icons/locked-card-icon-course.svg',
        'class': 'locked-card-color'
    },
    Recording: {
        'icon': '/icons/locked-card-icon-recording.svg',
        'class': 'locked-card-color'
    },
    Event: {
        'icon': '/icons/locked-card-icon-event.svg',
        'class': 'locked-card-color'
    }
}

const REVIEWLISTINGPOPUP = {
    REVIEWER: {
        'popup_title': 'This listing is currently being reviewed by you.',
        'sub_title1': 'While on this page, the listing will be locked for other reviewers.',
        'sub_title2': 'If you leave this page without approving or rejecting this listing, your review will not be saved.',
        'lock_button': 'Continue Review',
        'unlock_button': 'Leave Page and Unlock'
    },
    SUPERADMIN: {
        'popup_title': 'This listing is currently under review by a reviewer.',
        'sub_title1': 'While this listing is being reviewed, it will be locked for other reviewers.',
        'sub_title2': 'You can either keep this listing locked or unlock it for other reviewers.',
        'lock_button': 'Leave Page and Keep it Locked',
        'unlock_button': 'Leave Page and Unlock'
    }
}

export {
    BASIC_SEARCH_URL,
    DOCUMENT_SEARCH_URL,
    NEW_CONTENT_URL,
    ORGANIZATIONS_URL,
    DEFAULT_LOGO_URL,
    DESCENDING_DESIRED_RESOURCE_FIELDS,
    CHANGE_PASSWORD_URL,
    RESET_PASSWORD_URL,
    LOGIN_URL,
    USER_SURVEY_URL,
    POST_DOCUMENTS_URL,
    UNPUBLISH_RESOURCE_URL,
    TOOL_USER_GUIDE_URL,
    LISTING_CRITERIA_URL,
    ENDORSEMENT_CRITERIA_URL,
    USER_GUIDE_URL,
    RESOURCE_TEMPLATE,
    REVIEW_RESOURCE_URL,
    ENDORSE_RESOURCE_URL,
    DOCUMENT_UPLOAD_URL,
    IMAGE_UPLOAD_URL,
    switchFilters,
    multiFilters,
    MEDIA_TYPE_FILTERS,
    MEDIA_TYPE_OPTIONS,
    AUDIENCE_OPTIONS,
    TOPIC_OPTIONS,
    ORGANIZATION_FIELDS,
    GRADE_SPAN_OPTIONS,
    CREDIT_TYPE_OPTIONS,
    OH_TEACHING_STANDARD_OPTIONS,
    RESOURCES,
    RESOURCE_STATES,
    RESOURCE_FIELDS,
    RESOURCE_STATE,
    NON_ADMIN_ROLES,
    ROLES,
    SUPER_ADMIN,
    ROLE_MAP,
    USER_ROLE_MAP,
    UNPUBLISH_RESOURCE_STATES,
    MODIFY_RESOURCE_STATES,
    RESOURCE_EXPORT_URL,
    PROVIDER_REPORT_EXPORT_URL,
    RESOURCE_UPLOAD_URL,
    SEARCHABLE_STATES,
    CREATE_USER_URL,
    DEACTIVATE_USER_URL,
    DEACTIVATE_ORG_URL,
    CREATE_ORG_URL,
    USER_URL,
    ANALYTICS_EVENT_URL,
    LISTING_VISITED,
    LISTING_CREATED,
    EXIT_LINK,
    ORGANIZATION_PAGE_SIZE,
    USER_PAGE_SIZE,
    MANAGE_STATE,
    ENV_DOMAINS,
    ANALYTICS_TAG_IDS,
    CARD_TYPES,
    LOCKED_CARD_TYPES,
    CONTENT_PROVIDER,
    TRUE,
    MULTIPLE_SELECTED,
    REVIEWLISTINGPOPUP
}
