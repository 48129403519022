import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    RESOURCE_FIELDS,
    ROLES,
    MANAGE_STATE,
    NON_ADMIN_ROLES,
    USER_ROLE_MAP,
    SUPER_ADMIN,
    ORGANIZATION_FIELDS
} from '../../constants';
import OrganizationsService from '../../Services/organizationsService';
import UserService from '../../Services/UserService';
import DropDown from '../common/DropDown';
import UtilityBar from '../UtilityBar/UtilityBar';
import './ManageDashboard.css';
import CreateUser from './CreateUser/CreateUser';
import CreateOrg from './CreateOrg/CreateOrg';
import ViewUser from './ViewUser/ViewUser';
import DropdownField from "../common/DropdownField";

const availableRoles = ["Creator", "Reviewer", "Endorser", "Organization-Admin", "Super-Admin"];
let updatedCheckedState = [];

const initialformData = {
    firstName: '',
    lastName: "",
    emailAddress: "",
    contentProvider: '',
    roles: null,
    is_active: 'Activate'
};

const initialOrgFormData = {
    logo_link: '',
    org_name: '',
    website_link: '',
    is_active: '',
    org_type: null
};

const ManageDashboard = (props) => {
    const navigate = useNavigate();
    const [viewField, setViewField] = useState('Organizations');
    const [organizationFacets, setOrganizationFacets] = useState([])
    const [sortField, setSortField] = useState(RESOURCE_FIELDS.ORGANIZATION_NAME);
    const [sortOptions, setSortOptions] = useState(['Organization']);
    const [organizationResults, setOrganizationResults] = useState(() => []);
    const [userResults, setUserResults] = useState(() => []);
    const [page, setPage] = useState(1);
    const [pageTotal, setPageTotal] = useState(0);
    const [resultsTotal, setResultsTotal] = useState(0);
    const [userResultsTotal, setUserResultsTotal] = useState(0);
    const [sortChange, setSortChange] = useState(0);
    const [viewChange, setViewChange] = useState(0);
    const [createState, setCreateState] = useState(MANAGE_STATE.MAIN);
    const [viewOptionsWithCount, setViewOptionsWithCount] = useState([{ label: `Organizations (0)`, value: `Organizations` }, { label: `Users (0)`, value: `Users` }]);
    const isSuperAdmin = useMemo(() => props?.user?.roles?.includes(ROLES.SUPERADMIN), [props?.user?.roles]);
    const isOrganizationAdmin = useMemo(() => props?.user?.roles?.includes(ROLES.ORGANIZATION_ADMIN) && !props?.user?.roles?.includes(ROLES.SUPERADMIN), [props?.user?.roles]);
    const filters = useMemo(() => isOrganizationAdmin && [{ [RESOURCE_FIELDS.ORGANIZATION_NAME]: props?.user?.org_name }], [isOrganizationAdmin]);
    const availableRolesToCreate = useMemo(() => isOrganizationAdmin ? availableRoles.filter((roles) => roles !== SUPER_ADMIN) : availableRoles, [isOrganizationAdmin]);
    const [checkedState, setCheckedState] = useState(new Array(availableRolesToCreate.length).fill(false));
    const [emailAddressErrorMsg, setEmailAddressErrorMsg] = useState('');
    const [formErrorMsg, setFormErrorMsg] = useState('');
    const [postResultsMsg, setPostResultsMsg] = React.useState('');
    const [orgViewState, setOrgViewState] = useState('');
    const [singleOrgData, setSingleOrgData] = useState({});
    const [isFormDisabled, setIsFormDisabled] = React.useState(true);
    const [successMsg, setSuccessMsg] = React.useState('');
    const [orgUploadStatusMsg, setOrgUploadStatusMsg] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: "",
        emailAddress: "",
        contentProvider: null,
        roles: null,
        org_name: '',
        website_link: '',
        logo_link: '',
        is_active: '',
        org_type: null
    });

    const sortOptionMap = {
        'Organizations': ['Organization'],
        'Users': ['First Name', 'Last Name', 'Organization'],
    };
    const sortFieldMap = {
        'Organization': RESOURCE_FIELDS.ORGANIZATION_NAME,
        'First Name': 'first_name',
        'Last Name': 'last_name',
        [RESOURCE_FIELDS.ORGANIZATION_NAME]: 'Organization',
        'first_name': 'First Name',
        'last_name': 'Last Name',
    };

    const viewFieldMap = {
        'Organizations': 'Organizations',
        'Users': 'Users',
    };

    const fetchOrganizationParams = {
        page: undefined,
        results: undefined,
        sort: undefined,
        isInitial: undefined,
        currentResults: undefined,
        facetType: ORGANIZATION_FIELDS?.ORGANIZATION_TYPE
    };

    const organizationService = OrganizationsService({
        setter: setOrganizationResults,
        setOrganizationFacets: setOrganizationFacets,
        pageSetter: setPage,
        pageTotalSetter: setPageTotal,
        resultsTotalSetter: setResultsTotal,
        filters: filters
    });
    const userService = UserService({
        setUserResults: setUserResults,
        pageSetter: setPage,
        pageTotalSetter: setPageTotal,
        userResultsTotalSetter: setUserResultsTotal,
        setPostResults: props.setPostResults,
        postResults: props.postResults,
        filters: filters,
        isOrganizationAdmin: isOrganizationAdmin
    });

    useEffect(() => {
        if (resultsTotal > 0 || userResultsTotal > 0) {
            setViewOptionsWithCount([{ label: `Organizations (${resultsTotal})`, value: `Organizations` }, { label: `Users (${userResultsTotal})`, value: `Users` }]);
        }
    }, [resultsTotal, userResultsTotal]);

    useEffect(() => {
        setSortOptions(sortOptionMap[viewField]);
        if (viewField !== 'Organizations') {
            setSortField('first_name');
        } else {
            setSortField(RESOURCE_FIELDS.ORGANIZATION_NAME);
        }
    }, [viewChange]);

    useEffect(() => {
        if (!props.authCookie) {
            navigate('/');
        }
    }, [props.authCookie, navigate]);

    useEffect(() => {
        if (props?.user?.roles && sortChange === 1) {
            setPageTotal(0);
            setPage(1);
            organizationService.fetchSomeOrganizations(props.sortAscending, organizationResults, ORGANIZATION_FIELDS?.ORGANIZATION_TYPE);
            userService.fetchSomeUsers(props.sortAscending, userResults, sortField);
        } else if (props?.user?.roles && sortChange > 1) {
            setPageTotal(0);
            setPage(1);
            if (viewField === 'Organizations') {
                organizationService.fetchSomeOrganizations(props.sortAscending, organizationResults, ORGANIZATION_FIELDS?.ORGANIZATION_TYPE);
            } else {
                userService.fetchSomeUsers(props.sortAscending, userResults, sortField);
            }
        }
    }, [sortChange]);

    useEffect(() => {
        if (!isSuperAdmin && !isOrganizationAdmin) {
            navigate('/');
        }
    }, [props.user]);

    useEffect(() => {
        if (props?.postResults?.id) {
            userService.fetchUsers();
            organizationService.fetchOrganizations(fetchOrganizationParams.page, fetchOrganizationParams.results, fetchOrganizationParams.sort, fetchOrganizationParams.isInitial, fetchOrganizationParams.currentResults, fetchOrganizationParams.facetType);
            setTimeout(() => {
                setCreateState(MANAGE_STATE.MAIN);
            }, 1000);
            setPostResultsMsg(successMsg);
            window.scrollTo(0, 0);
        } else {
            if (props?.postResults) {
                setFormErrorMsg(props?.postResults);
            }
        }

    }, [props?.postResults, formErrorMsg]);

    useEffect(() => {
        if (successMsg && successMsg.match('Organization') && successMsg.match('ctivated')) {
            setCreateState(MANAGE_STATE.MAIN);
            window.scrollTo(0, 0);
            setPostResultsMsg(successMsg);
            setOrganizationResults([]);
            organizationService.fetchOrganizations(fetchOrganizationParams.page, fetchOrganizationParams.results, fetchOrganizationParams.sort, fetchOrganizationParams.isInitial, fetchOrganizationParams.currentResults, fetchOrganizationParams.facetType);
        }
    }, [successMsg]);

    useEffect(() => {
        if (postResultsMsg) {
            setTimeout(() => setPostResultsMsg(''), 3000);
        }
    }, [postResultsMsg]);

    useEffect(() => {
        if (props?.uploadResult?.link) {
            setOrgUploadStatusMsg('File uploaded successfully!');
        }
    }, [props.uploadResult]);

    const getOrganizations = () => {
        if (!(organizationResults?.length > 0)) {
            return (
                <div className="no-results">
                    No Organizations found.
                </div>
            )
        }
        const renderedOrganizations = organizationResults.map((org, index) => {
            return (
                <div id={`organizationResult-${index + 1}`} className={index % 2 ? 'row row-secondary' : 'row row-primary'} key={index} index={index}>
                    <div className="img">
                        {org.logo_link?.raw ? <img src={org.logo_link.raw} alt="company logo" className="org-logo" /> : 'N/A'}
                    </div>
                    <div className="row-right">
                        <div className="org-name">{org.org_name?.raw}</div>
                        {/* <div className="org-link">{org.website_link?.raw}</div> */}
                        <div className="org-edit" onClick={() => handleModifyOrg(org)}>edit</div>
                    </div>
                </div>
            );
        })

        return (
            <div>
                {renderedOrganizations}
            </div>
        )
    };

    const getUsers = () => {
        if (!userResults?.length) {
            return (
                <div className="no-results">
                    No Users found.
                </div>
            )
        }
        const renderedUsers = userResults.map((user, index) => {
            let orgs = user.org_name?.raw;
            if (typeof (orgs) === 'object') {
                if (props.sortAscending) {
                    orgs = orgs.sort().join(', ').toString();
                } else {
                    orgs = orgs.sort().reverse().join(', ').toString();
                }
            }
            return (
                <div id={`userResult-${index + 1}`} className={index % 2 ? 'row user-row row-secondary' : 'row user-row row-primary'} key={index} index={index}>
                    <div className="user-fname">{user.first_name?.raw}</div>
                    <div className="user-lname">{user.last_name?.raw}</div>
                    <div className="user-org">{orgs}</div>
                    <div className="user-roles">
                        {user.site_roles?.raw.map((role) => {
                            return (
                                NON_ADMIN_ROLES.includes(role) &&
                                <div className="user-role" key={role}>
                                    <span className="check-box">
                                        <img src={'/icons/check.svg'} alt="check" />
                                    </span>
                                    {USER_ROLE_MAP[role]}
                                </div>
                            );
                        })}
                    </div>
                    <div className="user-edit" onClick={() => handleViewUser(user)}>edit</div>
                </div>
            );
        })

        return (
            <div>
                {renderedUsers}
            </div>
        )
    };

    function fetchMore() {
        if (viewField === 'Organizations') {
            organizationService.fetchMoreOrganizations(page, props.sortAscending, organizationResults);
        } else {
            userService.fetchMoreUsers(page, props.sortAscending, userResults, sortField);
        }
    };

    const handleCreateUser = () => {
        setCreateState(MANAGE_STATE.CREATE_USER);
        setFormData(initialformData);
        setCheckedState(new Array(availableRolesToCreate.length).fill(false));
        props.setPostResults(null);
        setFormErrorMsg('');
    };

    const handleCreateOrg = () => {
        setCreateState(MANAGE_STATE.CREATE_ORG);
        setOrgViewState('create');
        setFormData(initialOrgFormData);
        props.setPostResults(null);
        setFormErrorMsg('');
        setIsFormDisabled(false);
        if (orgViewState !== 'create') {
            props.setUploadResult({});
        }
    }

    const handleModifyOrg = (org) => {
        setFormData({
            id: org?.id?.raw,
            org_name: org?.org_name?.raw,
            website_link: org?.website_link?.raw,
            logo_link: org?.logo_link?.raw,
            is_active: org?.is_active?.raw,
            org_type: { value: org?.org_type?.raw, label: org?.org_type?.raw }
        })
        setOrgViewState('modify');
        setCreateState(MANAGE_STATE.CREATE_ORG);
        props.setPostResults(null);
        setSingleOrgData(org);
        setFormErrorMsg('');
        setIsFormDisabled(true);
    }

    const handleViewUser = (user) => {
        setCreateState(MANAGE_STATE.MODIFY_USER);
        populateFields(user);
        props.setPostResults(null);
        setFormErrorMsg('');
        setIsFormDisabled(true);
    }

    const handleBackFromCreate = () => {
        setCreateState(MANAGE_STATE.MAIN);
        setFormErrorMsg('');
        setFormData({});
        setCheckedState(new Array(availableRolesToCreate.length).fill(false));
        setIsFormDisabled(true);
        setSuccessMsg("");
        props.setUploadResult({});
    }

    var bodyStyles = window.getComputedStyle(document.body);
    const missedRequiredFields = [];
    const organizationOptions = [];
    props?.organizations?.forEach(org => {
        const activeOrganization = org.is_active.raw ? org.is_active.raw : null;
        if (JSON.parse(activeOrganization)) {
            if (isSuperAdmin) {
                organizationOptions.push({ value: org?.org_name?.raw, label: org?.org_name?.raw });
            }
            else if (isOrganizationAdmin) {
                props?.user?.org_name.includes(org?.org_name?.raw) && organizationOptions.push({ value: org?.org_name?.raw, label: org?.org_name?.raw });
            }
        }

    });

    const handleCheckbox = (position) => {
        const selRoles = [];
        updatedCheckedState = checkedState.map((item, index) => {
            let role = [3, 4].includes(position) && index <= position ? index : position;
            return index === role ? !item : item;
        });
        if (updatedCheckedState[3] === true && updatedCheckedState[4] === false) {
            updatedCheckedState = [true, true, true, true, false];
        }
        else if (updatedCheckedState[4] === true) {
            updatedCheckedState = [true, true, true, true, true];
        }
        else if (updatedCheckedState?.length === 4 && updatedCheckedState[3] === true) {
            updatedCheckedState = [true, true, true, true];
        }

        setCheckedState(updatedCheckedState);

        updatedCheckedState.reduce((sum, currentState, index) => {
            if (currentState === true) {
                selRoles.push(availableRolesToCreate[index].toLocaleLowerCase());
                selRoles;
            }
        }, 0);

        setFormData({
            ...formData,
            roles: selRoles
        });
        setFormErrorMsg('');
        document.getElementById('roles').style.border = 'none';
    }

    const handleSelectDropdowns = (event, name) => {
        setFormData({
            ...formData,
            [name]: event
        });
        setFormErrorMsg('');
        document.getElementById(name).style.border = 'none';
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value.replace(/[^a-zA-Z0-9\[\]\!\@\#\$\%\^\&\*\(\)\-\_\,\.\{\}\[\]\+\=\;\:\"\'\~\`\<\>\s]/gi, '')
        });
        setFormErrorMsg('');
        document.getElementById(e.target.name).style.border = `1px solid ${bodyStyles.getPropertyValue('--brand-md-grey')}`;
    }

    const handleWebsiteURLChange = (e) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value
        });
        setFormErrorMsg('');
        document.getElementById(e.target.name).style.border = `1px solid ${bodyStyles.getPropertyValue('--brand-md-grey')}`;
    }

    const handleEmailAddress = (e) => {
        const value = e.target.value;

        setFormData({
            ...formData,
            [e.target.name]: value.replace(/[^a-zA-Z0-9\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\~\@\.\s]/gi, '')
        });
        setEmailAddressErrorMsg('');
        setFormErrorMsg('');
        document.getElementById(e.target.name).style.border = `1px solid ${bodyStyles.getPropertyValue('--brand-md-grey')}`;
    }

    const handleSubmit = (type) => {
        Object.keys(formData).forEach((key) => {
            if (!formData[key] || !formData[key].length) {
                missedRequiredFields.push(key);
            } else {
                if (key === "emailAddress") {
                    const isEmailValid = /^[^\s@]+@[a-zA-Z0-9\-\s@]+(\.[a-zA-Z0-9\s@]+)+$/.test(formData?.emailAddress);
                    if (!isEmailValid) {
                        setEmailAddressErrorMsg("Please provide a valid email address.");
                        missedRequiredFields.push(key);
                    }
                }

            }
        })

        if (missedRequiredFields.length) {
            setFormErrorMsg(`* A value must be entered/selected for below field`);
            if (!document.getElementById("error-msg")) {
                document.getElementById(missedRequiredFields[0]).style.border = '1px solid red';
                document.getElementById(missedRequiredFields[0]).focus();
            }
        } else {

            const contentProviderValues = [];
            formData?.contentProvider?.forEach((ele) => {
                contentProviderValues.push(ele.value);
            });

            const orgIds = [];
            props?.organizations?.forEach((org) => {
                if (contentProviderValues?.includes(org?.org_name?.raw)) {
                    orgIds.push(org?.id?.raw);
                }

            })

            if (type === "create") {
                (async () => {
                    const reqBody = {
                        email: formData?.emailAddress,
                        first_name: formData?.firstName,
                        last_name: formData?.lastName,
                        org_id: orgIds,
                        org_name: contentProviderValues,
                        site_roles: formData?.roles,
                        created_by: props?.user?.name,
                        created_date: new Date().toISOString()
                    }
                    await userService.performPostUser(reqBody);
                    setSuccessMsg("Admin user has been successfully created.");
                })();
            } else if (type === "modify") {
                (async () => {
                    const reqBody = {
                        email: formData?.emailAddress,
                        first_name: formData?.firstName,
                        last_name: formData?.lastName,
                        org_id: orgIds,
                        org_name: contentProviderValues,
                        site_roles: translateCheckboxValuesToPost(formData?.roles),
                        id: formData?.id,
                    }
                    await userService.performPostUser(reqBody);
                    setSuccessMsg("Admin user details saved successfully.");
                })();
            }
        }

    }

    const populateFields = (user) => {
        const updatedFormData = {
            firstName: user?.first_name?.raw,
            lastName: user?.last_name?.raw,
            emailAddress: user?.email?.raw,
            contentProvider: getMultiSelectValues(user?.org_id?.raw, user?.org_name?.raw),
            roles: getCheckboxValues(user?.site_roles?.raw),
            id: user?.id?.raw,
            is_active: user?.is_active?.raw
        }
        setCheckedState(getCheckboxValues(user?.site_roles?.raw));
        setFormData(updatedFormData);
    }

    const getMultiSelectValues = (ids, names) => {
        const valuePair = [];
        organizationOptions?.forEach((val) => {
            if (typeof names === "object") {
                names.forEach((name) => {
                    if (name === val.value) {
                        valuePair.push({ value: val.value, label: val.value })
                    }
                })
            } else {
                if (names === val.value) {
                    valuePair.push({ value: names, label: names })
                }
            }

        })
        return valuePair;
    }

    const getCheckboxValues = (roles) => {
        const selectedRoles = [];
        availableRolesToCreate.forEach((role) => {
            if (roles.includes(role.toLocaleLowerCase())) {
                selectedRoles.push(true);
            } else {
                selectedRoles.push(false);
            }

        })
        return selectedRoles
    }

    const handleOnClickModifyUser = () => {
        setIsFormDisabled(false);
    }

    const translateCheckboxValuesToPost = (roles) => {
        const selectedRoles = [];
        if (typeof roles[0] !== "string") {
            roles.reduce((sum, currentState, index) => {
                if (currentState === true) {
                    selectedRoles.push(availableRolesToCreate[index].toLocaleLowerCase());
                    selectedRoles;
                }
            }, 0);
        } else {
            return roles;
        }
        return selectedRoles
    }

    const deactivatUser = (actionName) => {
        const reqBody = {
            userId: formData?.id,
            active: formData?.is_active && !JSON.parse(formData?.is_active)
        }
        userService.performDeactivateUser(reqBody);
        setSuccessMsg(`Admin user has been ${actionName.toLowerCase()}d.`);
    }

    const handleSelectDropdown = (event) => {
        setViewField(event.value);
        setViewChange(viewChange + 1);
    }

    return (
        <div className="_manage">
            {props.authCookie &&
                <div className="main">
                    <div className="utility">
                        <UtilityBar user={props.user} organizations={props.organizations} logoLink={props.logoLink} setLogoLink={props.setLogoLink} setListingsViewsCounts={props.setListingsViewsCounts} listingsViewsCounts={props.listingsViewsCounts} listingsPublishedCount={props.listingsPublishedCount} setListingsPublishedCount={props.setListingsPublishedCount} />
                    </div >
                    {createState === MANAGE_STATE.MAIN && (
                        <div className="content">
                            <div className="actions">
                                <button id='createUser' className="btn-secondary primary" type="button" onClick={() => handleCreateUser()}
                                    onKeyPress={(e) => {
                                        if (e.code === "Space" || e.code === "Enter") {
                                            e.preventDefault();
                                            handleCreateUser()
                                        }
                                    }}><img src="/icons/cross.svg" alt="cross" /><span>Create A User</span></button>
                                {isSuperAdmin && <button id='createOrganization' className="btn-secondary alternate" type="button"
                                    onClick={() => handleCreateOrg()}
                                    onKeyPress={(e) => {
                                        if (e.code === "Space" || e.code === "Enter") {
                                            e.preventDefault();
                                            handleCreateOrg();
                                        }
                                    }}><img src="/icons/cross.svg" alt="cross" /><span>Create An Organization</span></button>}
                            </div>
                            {postResultsMsg && (<div className="successMessage">{postResultsMsg}</div>)}
                            <div className="results-control">
                                <div className="_dropdown-wrapper">
                                    <span className="label">{`View:`}</span>
                                    <DropdownField
                                        id={"manage-view"}
                                        options={viewOptionsWithCount}
                                        onChange={handleSelectDropdown}
                                        placeholder={viewOptionsWithCount[0].label}
                                        isRequired={false}
                                    />
                                </div>
                                <div className="sort">
                                    <DropDown label="Sort by" options={sortOptions} ascending={props.sortAscending} setAscending={() => props.setSortAscending(true)} field={sortField} setField={setSortField} fieldMap={sortFieldMap} change={sortChange} setChange={(val) => setSortChange(val)} id="manage-sort" />
                                </div>
                            </div>

                            <div className="results">
                                {
                                    viewField === 'Organizations' &&
                                    <div className="org-field-names">
                                        <div className="org">Organization</div>
                                        <div className="admin">Organization Name</div>
                                        <div className="modify">Modify</div>
                                    </div>
                                }
                                {
                                    viewField === 'Organizations' &&
                                    getOrganizations()
                                }
                                {
                                    viewField === 'Users' &&
                                    <div className="field-names">
                                        <div className="fname">First Name</div>
                                        <div className="lname">Last Name</div>
                                        <div className="org">Organization(s)</div>
                                        <div className="role">Role(s)</div>
                                        <div className="modify">Modify</div>
                                    </div >
                                }
                                {
                                    viewField === 'Users' &&
                                    getUsers()
                                }

                            </div >
                            {

                                (page < pageTotal &&
                                    <div className="load-more">
                                        <div className="void"></div>
                                        <button className="btn-secondary" onClick={() => fetchMore()}>Load More</button>
                                        <div className="void"></div>
                                    </div>
                                )
                            }
                        </div >
                    )}
                    {
                        createState === MANAGE_STATE.CREATE_ORG && (
                            <div className="content">
                                <div className="back" onClick={() => handleBackFromCreate()}>{"< Back"}</div>
                                <CreateOrg
                                    handleInputChange={handleInputChange}
                                    formData={formData}
                                    handleWebsiteURLChange={handleWebsiteURLChange}
                                    uploadResult={props.uploadResult}
                                    setUploadResult={props.setUploadResult}
                                    user={props.user}
                                    postResultsMsg={postResultsMsg}
                                    handleBackFromCreate={handleBackFromCreate}
                                    formErrorMsg={formErrorMsg}
                                    setPostResultsMsg={setPostResultsMsg}
                                    orgViewState={orgViewState}
                                    isFormDisabled={isFormDisabled}
                                    setIsFormDisabled={setIsFormDisabled}
                                    singleOrgData={singleOrgData}
                                    setResults={props.setResults}
                                    setSuccessMsg={setSuccessMsg}
                                    setPostResults={props.setPostResults}
                                    handleSelectDropdowns={handleSelectDropdowns}
                                    organizationFacets={organizationFacets}
                                    isSuperAdmin={isSuperAdmin}
                                />
                            </div>
                        )
                    }
                    {
                        createState === MANAGE_STATE.CREATE_USER && (
                            <div className="content">
                                <div className="back" onClick={() => handleBackFromCreate()}>{"< Back"}</div>
                                <CreateUser
                                    handleCheckbox={handleCheckbox}
                                    handleEmailAddress={handleEmailAddress}
                                    handleInputChange={handleInputChange}
                                    handleSelectDropdowns={handleSelectDropdowns}
                                    formErrorMsg={formErrorMsg}
                                    emailAddressErrorMsg={emailAddressErrorMsg}
                                    handleSubmit={handleSubmit}
                                    formData={formData}
                                    organizationOptions={organizationOptions}
                                    checkedState={checkedState}
                                    availableRolesToCreate={availableRolesToCreate}
                                />
                            </div>
                        )
                    }

                    {
                        createState === MANAGE_STATE.MODIFY_USER && (
                            <div className="content">
                                <div className="back" onClick={() => handleBackFromCreate()}>{"< Back"}</div>
                                <ViewUser
                                    handleCheckbox={handleCheckbox}
                                    isFormDisabled={isFormDisabled}
                                    handleOnClickModifyUser={handleOnClickModifyUser}
                                    handleEmailAddress={handleEmailAddress}
                                    handleInputChange={handleInputChange}
                                    handleSelectDropdowns={handleSelectDropdowns}
                                    formErrorMsg={formErrorMsg}
                                    emailAddressErrorMsg={emailAddressErrorMsg}
                                    handleSubmit={handleSubmit}
                                    formData={formData}
                                    organizationOptions={organizationOptions}
                                    checkedState={checkedState}
                                    availableRolesToCreate={availableRolesToCreate}
                                    deactivatUser={deactivatUser}
                                />
                            </div>
                        )
                    }
                </div >
            }
        </div>
    );
}

export default React.memo(ManageDashboard);
