import { useEffect, useState } from "react";
import DropdownMultiFilter from "./DropdownMultiFilter";
import { CONTENT_PROVIDER } from "../../constants"

function MultiFilter(props) {
    const [filterExpanded, setExpanded] = useState(false);
    const [contentProviderFilter, setContentProviderFilter] = useState([])

    useEffect(() => {
        if (props.filters.includes(props.name)) {
            setExpanded(true);
        }
    }, [props.filterChange]);

    useEffect(() => {
        if (props?.name === CONTENT_PROVIDER) {
            const contentProviderFilters = [];
            props.filters.forEach((data, index) => {
                if (data === CONTENT_PROVIDER) {
                    contentProviderFilters.push(props.filters[index - 1])
                }
            })
            setContentProviderFilter(contentProviderFilters);
        }
    }, [props.filters])

    const updateFilterValues = (filterValues, dropdownLabel, value) => {
        props?.name === CONTENT_PROVIDER ? filterValues.push({
            label: dropdownLabel,
            value: dropdownLabel,
            text: value,
        }) : filterValues.push(dropdownLabel)
    }
    const handleChange = (e) => {
        if (props.filters.includes(e.target.value)) {
            let filters = [...props.filters];
            filters.splice(props.filters.indexOf(e.target.value), 2)
            props.gtag('event', 'remove_filter', { 'item': e.target.value, 'category': e.target.name });
            props.setFilters(filters);
            props.setFilterChange(props.filterChange + 1);
        } else {
            props.gtag('event', 'add_filter', { 'item': e.target.value, 'category': e.target.name });
            props.setFilters([e.target.value, e.target.name, ...props.filters]);
            props.setFilterChange(props.filterChange + 1);
        }
    }

    function getSubFilters(value) {
        const subFilters = [];
        for (let x = 1; x < value.length; x++) {
            subFilters.push(
                <div className="sub-filter" key={x}>
                    <label htmlFor={`${value[x]}-checkbox`} />
                    <input id={`${value[x]}-checkbox`} type="checkbox" onChange={handleChange} value={value[x]} name={props.name} checked={props.filters.includes(value[x])}
                        onKeyPress={(e) => {
                            if (e.code === "Space" || e.code === "Enter") {
                                e.preventDefault();
                                handleChange;
                            }
                        }}
                    />
                    {value[x]}
                </div>
            )
        }
        return subFilters;
    }

    function getFiltervalues(values) {
        const filterValues = [];
        values.forEach(
            (value, index) => {
                if (typeof (value) === 'object') {
                    const dropdownLabel =
                        <div className="complex-filter" key={index}>
                            <div className="value">
                                <label for={`${value}-checkbox`} />
                                <input id={`${value}-checkbox`} type="checkbox" onChange={handleChange} value={value[0]} name={props.name} checked={props.filters.includes(value[0])}
                                    onKeyPress={(e) => {
                                        if (e.code === "Space" || e.code === "Enter") {
                                            e.preventDefault();
                                            handleChange;
                                        }
                                    }}
                                />
                                {value[0]}
                            </div>
                            {getSubFilters(value)}
                        </div>
                    { updateFilterValues(filterValues, dropdownLabel, value) }
                } else {
                    const dropdownLabel =
                        <div className="value" key={index}>
                            <label htmlFor={`${value}-checkbox`} />
                            <input id={`${value}-checkbox`} type="checkbox" onChange={handleChange} value={value} name={props.name} checked={props.filters.includes(value)}
                                onKeyPress={(e) => {
                                    if (e.code === "Space") {
                                        e.preventDefault();
                                        handleChange;
                                    }
                                    if (e.code === "Enter") {
                                        e.currentTarget.click();
                                    }
                                }}
                            />
                            {value}
                        </div>
                    { updateFilterValues(filterValues, dropdownLabel, value) }
                }
            }
        )
        return filterValues;
    }

    return (

        props?.name === CONTENT_PROVIDER ? <DropdownMultiFilter id={props.name} name={props.name} filters={props.filters} getFiltervalues={getFiltervalues} values={props.values} placeholder={"Select content provider"} contentProviderFilter={contentProviderFilter} setContentProviderFilter={setContentProviderFilter} /> :
            <div className="multi-filter">
                <div className="multi-filter__label" tabIndex="0"
                    onClick={() => {
                        setExpanded(!filterExpanded);
                    }}
                    onKeyPress={(e) => {
                        if (e.code === "Space" || e.code === "Enter") {
                            e.preventDefault();
                            setExpanded(!filterExpanded);
                        }
                    }}
                >
                    <div className="filter-key" id={props.name.split(' ').join("-").toLowerCase() + '-filter'}>{props.name}</div>
                    {(filterExpanded) ? <img src="/icons/chevron-dark-blue.svg" className="flip" alt="chevron" /> : <img src="/icons/chevron-dark-blue.svg" alt="chevron" />}
                </div>
                <div className="multi-filter__content">
                    <div className="filter-value" >
                        {(filterExpanded) && getFiltervalues(props.values)}
                    </div>
                </div>
            </div>
    );
}

export default MultiFilter;
