import { NEW_CONTENT_URL } from "../constants";


const NewContentService = (setter, logoSetter) => {
    const displayNewContent = (res) => {
        if(res.length) {
            displayData(res);
        }
    };
    const handleNewContentError = (err) => {
        //TODO: handle new content error
    };
    const displayData = (data) => {
        setter(data);
    }

    const setLogo = (link) => {
        if(link?.length) {
            logoSetter(link);
        }
    }

    return ({
        fetchNewContent : (referrer, organizations) => {
            const org = referrer && organizations.filter(org => org.website_link?.raw === referrer)[0];
            const org_id = org?.id?.raw;
            const logo = org?.logo_link?.raw;

            setLogo(logo);

            fetch(NEW_CONTENT_URL, {
            method: "POST",
            headers: {
                "Access-Control-Request-Headers": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(
                {
                    body: {
                        size: 4,
                        organization: org_id,
                    }
                }
            ),
            })
            .then((response) => response.json())
            .then((data) => displayNewContent(JSON.parse(data.body).results))
            .catch((e) => {
                handleNewContentError(e);
            });
        }
    });
};

export default NewContentService;
