import {
    BASIC_SEARCH_URL,
    POST_DOCUMENTS_URL,
    UNPUBLISH_RESOURCE_URL,
    RESOURCE_UPLOAD_URL,
    REVIEW_RESOURCE_URL,
    ENDORSE_RESOURCE_URL,
    DOCUMENT_UPLOAD_URL,
    SEARCHABLE_STATES,
    IMAGE_UPLOAD_URL,
    RESOURCE_EXPORT_URL,
    PROVIDER_REPORT_EXPORT_URL,
    RESOURCE_FIELDS,
    DESCENDING_DESIRED_RESOURCE_FIELDS,
    RESOURCE_STATES,
} from "../constants";
import moment from 'moment'

const QueryService = (props) => {
    /*
        props : {
            setter,
            currentData,
            pageSetter,
            querySetter,
            totalSetter,
            sortField,
            viewField
            sortAscending,
            filters,
            include_org,
            exclude_org,
            facetCounts,
        }
    */

    const displaySearchResults = (res, currentPage = 1) => {
        displayData(res)
        props.pageSetter(currentPage);
    };
    const displayContinuedSearchResults = (current, res, page) => {
        while (res.length) {
            current.push(res.shift());
        }
        displayData(current);
        props.pageSetter(page);
    }
    const displayDocument = (res) => {
        displayData(res[0]);
    }
    const handleSearchError = (err) => {
        let errorObj = {}
        errorObj.isError = true;
        errorObj.data = err;
        props.setter(errorObj);
    };

    const handleFacetCounts = (data, facetType) => {
        let facetTypeCounts;
        if (facetType === 'org_name') {
            facetTypeCounts = JSON.parse(data.body).facets.org_name;
        } else if (facetType === 'resource_state') {
            facetTypeCounts = JSON.parse(data.body).facets.resource_state;
        } else {
            handleSearchError(data);
        }
        props.facetCounts(facetTypeCounts.map((facetsCount) => facetsCount.data));
    };

    const generateSortingFields = (sortAscending) => {
        const sortFields = [{
            [props.sortField]: sortAscending ? 'asc' : 'desc'
        }];
        props.sortField === RESOURCE_FIELDS.ORGANIZATION_NAME && sortFields.push({ [RESOURCE_FIELDS.UPLOADED_DATE]: "asc" });

        return sortFields;
    }

    const displayData = (data) => {
        props.setter(data);
    }

    const postResultsData = (res) => {
        props.setPostResults(res[0]);
    }

    const uploadResult = (res) => {
        if (res.link) {
            props.setUploadResult(res);
        } else {
            if (res.statusCode) {
                const parsedBody = JSON.parse(res.body);
                props.setUploadResult(parsedBody.link);
                props.postResultsMsg('File uploaded successfully!');
            } else {
                props.setPostResults(res[0]);
            }
        }

    }

    const getFilters = (trueFilters, resourceState) => {
        let filters = [...trueFilters];
        let categories = {
            "TOPIC": "topic",
            "MEDIA TYPE": "media_type",
            "GRADE SPAN": "grade_span",
            "CREDIT TYPE": "completion_credit",
            "AUDIENCE": "audience",
            "ENDORSED ONLY": "is_endorsed",
            "CONTENT PROVIDER": "org_name",
        }

        let filterObj = {
            all: [
                { "topic": [] },
                { "audience": [] },
                { "media_type": [] },
                { "grade_span": [] },
                { "completion_credit": [] },
                { "org_name": [] },
                { "is_endorsed": false },
            ]
        }
        while (filters.length >= 2) {

            let val = filters.shift();
            let key = filters.shift();

            filterObj.all.forEach((category) => {
                Object.keys(category).forEach((cat) => {
                    if (cat === categories[key]) {
                        if (Array.isArray(category[cat])) {
                            if (val === "Starting Soon") {
                                category[cat].push("Event", "starting_date");
                            } else if (val === "Expiring Soon") {
                                category[cat].push("Event", "expiration_date");
                            } else {
                                category[cat].push(val)
                            }
                        } else {
                            category[cat] = val.toString()
                        }

                    }
                })
            })
        }

        let filterAllArray = [];

        let eventSubFilterObj = {}
        const futureDate = moment(moment()).add(1, 'M');
        const currentDateTime = moment().utc().format();
        const futureDateTime = futureDate.utc(futureDate).format();

        filterObj.all.forEach((category, index) => {
            Object.keys(category).forEach((cat) => {
                if (category[cat].length) {
                    if (category[cat].includes("starting_date") && category[cat].includes("expiration_date")) {
                        let eventExpirationObj = {};
                        category[cat] = ["Event"];
                        eventSubFilterObj.starting_date = { from: currentDateTime, to: futureDateTime }
                        eventExpirationObj.expiration_date = { from: currentDateTime, to: futureDateTime }
                        filterAllArray.push(filterObj.all[index], eventSubFilterObj, eventExpirationObj);
                    } else {
                        if (category[cat].includes("starting_date")) {
                            category[cat] = ["Event"];
                            eventSubFilterObj.starting_date = { from: currentDateTime, to: futureDateTime }
                            filterAllArray.push(filterObj.all[index], eventSubFilterObj);
                        } else if (category[cat].includes("expiration_date")) {
                            category[cat] = ["Event"];
                            eventSubFilterObj.expiration_date = { from: currentDateTime, to: futureDateTime }
                            filterAllArray.push(filterObj.all[index], eventSubFilterObj);
                        } else {
                            filterAllArray.push(filterObj.all[index]);
                        }
                    }

                }
            });
        });
        filterObj.all = filterAllArray;
        if (resourceState?.length) {
            filterObj.all.push({ resource_state: resourceState });
        }
        if (props.viewField) {
            filterObj.all.push({ resource_state: props.viewField });
        }
        if (props.organization) {
            filterObj.all.push({ org_name: props.organization });
        }

        filterObj.none = [];

        if (props.include_org) {
            filterObj.all.push({ org_id: props.include_org });
        } else if (props.exclude_org) {
            filterObj.none.push({ org_id: props.exclude_org });
        }

        return filterObj;
    }

    const updateFilter = (initialContentProviderSearchFilter, filters, resourceState) => {
        let filter;
        if (initialContentProviderSearchFilter && filters?.length < 1) {
            filter = {
                "all": initialContentProviderSearchFilter
            };
        } else {
            filter = getFilters(filters, resourceState)
        }

        return filter
    }

    const queryService = {
        performSearch: (query, page, initialDisplay, currentResults, resourceState = '', size = 9, currentPage = 1, facetType) => {
            props.querySetter(query);
            const ascendingDesirable = !(DESCENDING_DESIRED_RESOURCE_FIELDS.includes(props.sortField));
            const sortAscending = ascendingDesirable ? props.sortAscending : !(props.sortAscending);
            const sortFields = generateSortingFields(sortAscending);
            if (resourceState === RESOURCE_STATES.REVIEW){
                sortFields.unshift({ lock_status: "desc" });
            }

            const requestBody = {
                query: query,
                page: {
                    current: page,
                    size: size,
                },
                filters: updateFilter(props.initialContentProviderSearchFilter, props.filters, resourceState),
                search_fields: {
                    title: {},
                    short_description: {},
                    long_description: {},
                    org_name: {},
                    media_type: {},
                    topic: {},
                    audience: {},
                    grade_span: {},
                    learning_objectives: {},
                    search_tags: {}
                },
                sort: sortFields
            };
            if (facetType) {
                let facetsName = `${facetType}_facet`;
                let facets = {
                    [facetType]: {
                        type: "value",
                        name: facetsName,
                        sort: {
                            value: "asc"
                        },
                        size: 100
                    }
                };
                requestBody.facets = facets;
            }
            fetch(BASIC_SEARCH_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(
                    {
                        body: requestBody
                    }
                ),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.statusCode === 200) {
                        if (initialDisplay) {
                            if (currentPage === 1) {
                                props.totalSetter(JSON.parse(data.body).meta?.page?.total_pages);
                            }
                            if (JSON.parse(data?.body).results) {
                                if (typeof (props.resultsTotalSetter) === 'function') {
                                    props.resultsTotalSetter(JSON.parse(data.body).meta?.page?.total_results);
                                }
                                displaySearchResults(JSON.parse(data.body).results, currentPage);
                            } else {
                                handleSearchError(data);
                            }
                        } else {
                            displayContinuedSearchResults(currentResults, JSON.parse(data.body).results, JSON.parse(data.body).meta?.page?.current)
                        }
                        if (facetType) {
                            handleFacetCounts(data, facetType);
                        }
                    } else {
                        handleSearchError(data);
                    }
                })
                .catch((e) => {
                    handleSearchError(e);
                });
        },
        performAdminResourceSearch: (organization_ids, facetType) => {
            if (facetType && organization_ids) {
                let facetsName = `${facetType}_facet`;
                let facets = {
                    [facetType]: {
                        type: "value",
                        name: facetsName,
                        sort: {
                            value: "asc"
                        },
                        size: 100
                    }
                };
                fetch(BASIC_SEARCH_URL, {
                    method: "POST",
                    headers: {
                        "Access-Control-Request-Headers": "*",
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify(
                        {
                            body: {
                                query: "",
                                filters: {
                                    "org_id": organization_ids
                                },
                                facets,
                            }
                        }
                    ),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        let facetTypeCounts;
                        if (facetType === 'topic') {
                            facetTypeCounts = JSON.parse(data.body).facets.topic;
                        } else if (facetType === 'audience') {
                            facetTypeCounts = JSON.parse(data.body).facets.audience;
                        } else if (facetType === 'media_type') {
                            facetTypeCounts = JSON.parse(data.body).facets.media_type;
                        } else {
                            handleSearchError(data);
                        }
                        props.setter(facetTypeCounts.map((facetsCount) => facetsCount.data));
                    })
                    .catch((e) => {
                        handleSearchError(e);
                    });
            }
        },

        performAdminResourcePublishedCount: (organization_ids) => {
            if (organization_ids) {
                fetch(BASIC_SEARCH_URL, {
                    method: "POST",
                    headers: {
                        "Access-Control-Request-Headers": "*",
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify(
                        {
                            body: {
                                query: "",
                                page: {
                                    current: 1,
                                    size: 1
                                },
                                filters: {
                                    all: [
                                        {
                                            resource_state: [
                                                "published",
                                                "submitted_endorsement",
                                                "rejected_endorsement",
                                                "endorsed"
                                            ]
                                        },
                                        { "org_id": organization_ids }
                                    ]
                                }
                            }
                        }
                    ),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        props.resultsTotalSetter(JSON.parse(data.body).meta?.page?.total_results);
                    })
                    .catch((e) => {
                        handleSearchError(e);
                    });
            }
            handleSearchError(organization_ids);
        },
        performAllListingsExport: (organization_ids) => {
            if (organization_ids) {
                return fetch(RESOURCE_EXPORT_URL, {
                    method: "POST",
                    headers: {
                        "Access-Control-Request-Headers": "*",
                        "Content-type": "application/json"
                    },
                    body: JSON.stringify({ 'body': organization_ids })
                }).then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();
                    let parsedData;
                    if (typeof data.body === 'string') {
                        parsedData = JSON.parse(data.body);
                    }
                    if (data.statusCode !== 200) {
                        handleSearchError(data.body);
                    }
                    if (data.statusCode === 200) {
                        return parsedData.link;
                    }
                });
            }
            handleSearchError(organization_ids);
        },
        performListingByProviderExport: (organization_ids) => {
            if (organization_ids) {
                return fetch(PROVIDER_REPORT_EXPORT_URL, {
                    method: "POST",
                    headers: {
                        "Access-Control-Request-Headers": "*",
                        "Content-type": "application/json"
                    },
                    body: JSON.stringify({ 'body': null })
                }).then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();
                    let parsedData;
                    if (typeof data.body === 'string') {
                        parsedData = JSON.parse(data.body);
                    }
                    if (data.statusCode !== 200) {
                        handleSearchError(data.body);
                    }
                    if (data.statusCode === 200) {
                        return parsedData.link;
                    }
                });
            }
            handleSearchError(organization_ids);
        },
        performAdminSearch: (resourceState = '', facetType = '', query = '', page = 1, initialDisplay = true, currentResults = [], size = 9, currentPage = 1,) => {
            queryService.performSearch(query, page, initialDisplay, currentResults, resourceState, size, currentPage, facetType);
        },
        performBasicSearch: (query, page = 1, initialDisplay = true, currentResults = [], facetType = '') => {
            queryService.performSearch(query, page, initialDisplay, currentResults, SEARCHABLE_STATES, 9, 1, facetType);
            //TODO: Remove this when endpoints are fully resolved. This mock data can be used for making adjustments when the service is down.
            // displaySearchResults([mockData[1],mockData[2],mockData[3],mockData[4],mockData[5]]);
        },
        fetchNextPage: (query, page, currentResults, resourceState = '') => {
            queryService.performSearch(query, page + 1, false, currentResults, resourceState);
        },
        fetchPreviousPages: (query, page) => {
            queryService.performSearch(query, 1, true, [], SEARCHABLE_STATES, page * 9, page);
        },
        performDocumentSearch: (docID) => {
            //TODO: Wire this to Document search url when schemas are matching
            if (props.currentData.id?.raw !== docID) {
                fetch(BASIC_SEARCH_URL, {
                    method: "POST",
                    headers: {
                        "Access-Control-Request-Headers": "*",
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify(
                        {
                            body: {
                                query: docID,
                                page: {
                                    current: 1,
                                    size: 1
                                },
                                search_fields: {
                                    id: {},
                                },
                            }
                        }
                    ),
                })
                    .then((response) => response.json())
                    .then((data) => displayDocument(JSON.parse(data.body).results))
                    .catch((e) => {
                        handleSearchError(e);
                    });
            }
        },
        performPostDocuments: async (requestBody) => {
            try {
                const response = await fetch(POST_DOCUMENTS_URL, {
                    method: "POST",
                    headers: {
                        "Access-Control-Request-Headers": "*",
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify(
                        {
                            body: requestBody
                        }
                    ),
                })
                const data = await response.json();
                postResultsData(JSON.parse(data.body))
            } catch (error) {
                handleSearchError(error);
            }
        },
        performPostResourceUpload: (fileData, user) => {
            return fetch(RESOURCE_UPLOAD_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                    "Content-type": "text/csv",
                    "org_id": user.org_id,
                    "org_name": user.org_name,
                    "user": user.name,
                },
                body: fileData
            });
        },
        sortedSearch: (field, ascending, query) => {
            queryService.performBasicSearch(query, 1, true, [],)
        },

        performUnpublishResource: (requestBody) => {
            fetch(UNPUBLISH_RESOURCE_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(
                    {
                        body: requestBody
                    }
                ),
            })
                .then((response) => response.json())
                .then((data) => postResultsData(JSON.parse(data.body)))
                .catch((e) => {
                    handleSearchError(e);
                });
        },

        performReviewResource: (requestBody) => {
            fetch(REVIEW_RESOURCE_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(
                    {
                        body: requestBody
                    }
                ),
            })
                .then((response) => response.json())
                .then((data) => postResultsData(JSON.parse(data.body)))
                .catch((e) => {
                    handleSearchError(e);
                });
        },

        performEndorseResource: (requestBody) => {
            fetch(ENDORSE_RESOURCE_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(
                    {
                        body: requestBody
                    }
                ),
            })
                .then((response) => response.json())
                .then((data) => postResultsData(JSON.parse(data.body)))
                .catch((e) => {
                    handleSearchError(e);
                });
        },

        performDocumentUpload: (fileData) => {
            fetch(DOCUMENT_UPLOAD_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                },
                body: fileData,

            })
                .then((response) =>
                    response.clone().json()
                )
                .then((data) => uploadResult(data))
                .catch((e) => {
                    handleSearchError(e);
                });
        },

        performImageUpload: (fileData) => {
            fetch(IMAGE_UPLOAD_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                },
                body: JSON.stringify(fileData)
            })
                .then((response) =>
                    response.clone().json()
                )
                .then((response) => uploadResult(response))
                .catch((e) => {
                    handleSearchError(e);
                });
        },

        //TODO: Remove this when endpoints are fully resolved. This mock data can be used for making adjustments when the service is down.
        //displayDocument([mockData.1]);
    };
    return queryService;
};

export default QueryService;
