import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import utilityService from "../../Services/utilityService";
import { ROLES } from "../../constants";
import "./AdminBar.css";

function AdminBar(props) {
    const [isOpen, setIsOpen] = useState(false);
    const path = document.location.pathname;
    const navigate = useNavigate();

    const navOPLD = () => {
        if (!props.formChanged) {
            window.location.href = window.location.origin;
        } else {
            props.setLeavingForm(true);
            props.setDialogRedirect('/');
            setTimeout(() => {
                document.getElementById('dialog-button-yes').focus();
            }, 0);
        }
        if (path !== "/") {
            props.setPostResults([]);
        }
    }

    const navCreate = () => {
        if (!props.formChanged) {
            navigate('/Admin/Create');
        } else {
            props.setLeavingForm(true);
            props.setDialogRedirect('/Admin/Create');
            setTimeout(() => {
                document.getElementById('dialog-button-yes').focus();
            }, 0);
        }
        props.setUploadedFile('');
        if (path !== "/Admin/Create") {
            props.setPostResults([]);
        }
    }

    const navEndorse = () => {
        if (!props.formChanged) {
            navigate('/Admin/Endorse');
        } else {
            props.setLeavingForm(true);
            props.setDialogRedirect('/Admin/Endorse');
            setTimeout(() => {
                document.getElementById('dialog-button-yes').focus();
            }, 0);
        }
        props.setUploadedFile('');
        if (path !== "/Admin/Endorse") {
            props.setPostResults([]);
        }
    }

    const navReview = () => {
        if (!props.formChanged) {
            navigate('/Admin/Review');
        } else {
            props.setLeavingForm(true);
            props.setDialogRedirect('/Admin/Review');
            setTimeout(() => {
                document.getElementById('dialog-button-yes').focus();
            }, 0);
        }
        props.setUploadedFile('');
        if (path !== "/Admin/Review") {
            props.setPostResults([]);
        }
    }

    const navManage = () => {
        if (!props.formChanged) {
            navigate('/Admin/Manage');
        } else {
            props.setLeavingForm(true);
            props.setDialogRedirect('/Admin/Manage');
        }
        props.setUploadedFile('');

        if (path !== "/Admin/Manage") {
            props.setPostResults([]);
        }
    }

    const logOut = () => {
        utilityService.deleteCookie("auth");
        window.location.href = window.location.origin;
    }

    return (
        <div className="_admin-bar">
            <div className="_admin-bar__content">
                <div className="title">Administration</div>
                <div className="nav">
                    {props.user?.roles?.includes(ROLES.CREATOR) && <button className={path.match('/Admin/Create') ? 'current link' : 'link'} onClick={() => navCreate()}>Create</button>}
                    {props.user?.roles?.includes(ROLES.REVIEWER) && <button className={path.match('/Admin/Review') ? 'current link' : 'link'} onClick={() => navReview()}>Review</button>}
                    {props.user?.roles?.includes(ROLES.ENDORSER) && <button className={path.match('/Admin/Endorse') ? 'current link' : 'link'} onClick={() => navEndorse()}>Endorse</button>}
                    {(props.user?.roles?.includes(ROLES.SUPERADMIN) || props.user?.roles?.includes(ROLES.ORGANIZATION_ADMIN)) && <button className={path.match('/Admin/Manage') ? 'current link' : 'link'} onClick={() => navManage()}>Manage</button>}
                </div>
                <div className="right-content">
                    <div className="spacer"></div>
                    <div className="right-content__content">
                        <button className="btn-secondary" onClick={() => navOPLD()}>Visit OPLD</button>
                        <div id="account_profile" tabIndex="0" className="account"
                            onClick={() => { !isOpen ? setIsOpen(true) : setIsOpen(false) }}
                            onKeyPress={(e) => {
                                if (e.code === "Space" || e.code === "Enter") {
                                    e.preventDefault();
                                    !isOpen ? setIsOpen(true) : setIsOpen(false);
                                }
                            }}
                        >
                            {props.user.name}
                            <img className="right" src="/icons/chevron-white.svg" alt="chevron" />
                        </div>
                    </div>
                    {
                        isOpen &&
                        <div id="account_profile__menu" className="account_profile__menu">
                            <button id="account_profile__menu_logout" className="account_profile__menu_logout" onClick={() => logOut()}>Log Out</button>
                        </div>
                    }
                </div >
            </div >
        </div >
    );
};

export default AdminBar;
