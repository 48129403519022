import React, { useState, useEffect } from 'react';
import InputComponent from '../../common/InputComponent';
import QueryService from "../../../Services/QueryService";
import OrganizationsService from '../../../Services/organizationsService';
import DropdownField from "../../common/DropdownField"
import './CreateOrg.css';
import { ORGANIZATION_FIELDS } from '../../../constants';

const CreateOrg = (props) => {
    const toggleButtonName = props?.formData?.is_active && JSON.parse(props?.formData?.is_active) ? "Deactivate" : "Activate";
    const showDeactivateOrgButtons = false;
    const organizationTypes = props?.organizationFacets?.org_type?.[0]?.data?.map((orgType) => ({ value: orgType?.value, label: orgType?.value }));

    const organizationService = OrganizationsService({
        formErrorMsg: props.formErrorMsg,
        setPostResultsMsg: props.setPostResultsMsg,
        postResultsMsg: props.postResultsMsg,
        handleBackFromCreate: props.handleBackFromCreate,
        orgViewState: props.orgViewState,
        setPostResults: props.setPostResults,
    });

    const [websiteURLErrorMsg, setWebsiteURLErrorMsg] = useState('');
    const [orgImage, setOrgImage] = useState('');

    const queryService = QueryService({
        uploadResult: props.uploadResult,
        setUploadResult: props.setUploadResult,
        setter: props.setResults,
    });

    useEffect(() => {
        if (props?.formData?.logo_link && props.orgViewState === 'modify') {
            setOrgImage(props.formData.logo_link);
        }
    }, [props?.formData?.logo_link]);

    useEffect(() => {
        if (typeof props.uploadResult !== 'object') {
            setOrgImage(props.uploadResult);
        }
    }, [props.uploadResult]);

    const readFile = (file, callback, fileName) => {
        const reader = new FileReader();

        reader.onload = () => {
            const readerResult = reader.result;
            callback(fileName, readerResult);
        }

        reader.readAsDataURL(file);
    }

    const apiSubmit = (fileName, readerResult) => {
        let data = {
            body: {
                "fileName": fileName,
                "image": `${readerResult}`,
            }
        };

        queryService.performImageUpload(data);
    };

    const uploadImage = (e) => {
        const file = e.target.files[0];
        const fileName = file.name;
        readFile(file, apiSubmit, fileName);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let data;
        setWebsiteURLErrorMsg("");
        if (props.orgViewState === 'modify') {
            data = {
                body: {
                    "org_name": props.formData.org_name,
                    "id": props.singleOrgData.id.raw,
                    "org_type": props?.formData?.org_type?.value,
                    "website_link": props?.formData?.website_link,
                    "logo_link": orgImage,
                    "created_by": props?.user?.name,
                    "created_date": new Date().toISOString()
                }
            };
        } else {
            data = {
                body: {
                    "org_name": props?.formData?.org_name,
                    "website_link": props?.formData?.website_link,
                    "org_type": props?.formData?.org_type?.value,
                    "logo_link": props?.uploadResult,
                    "created_by": props?.user?.name,
                    "created_date": new Date().toISOString()
                }
            };
        }

        organizationService.performPostOrg(data);
        if (props.orgViewState === 'modify') {
            props.setSuccessMsg('Organization details saved.');
        } else {
            props.setSuccessMsg('Organization has been successfully created.');
        }
        props.setUploadResult({});
        window.scrollTo(0, 0);
    };

    const deactivatOrganization = (actionName) => {
        const reqBody = {
            orgId: props.formData?.id,
            active: props.formData?.is_active && !JSON.parse(props.formData?.is_active)
        }
        organizationService.performDeactivateOrg(reqBody, () => {
            props.setSuccessMsg(`Organization has been ${actionName.toLowerCase()}d.`);
        });
    }

    return (
        <div className='_create-org' id='_create-org'>
            {props.orgViewState === 'create' &&
                <>
                    <div className='title'>Create an Organization</div>
                    <hr className="divider" />
                </>
            }
            {props.orgViewState === 'modify' &&
                <>
                    <button className="btn-secondary" id="modifyOrg" onClick={() => props.setIsFormDisabled(false)}
                        onKeyPress={(e) => {
                            if (e.code === "Space" || e.code === "Enter") {
                                e.preventDefault();
                                props.setIsFormDisabled(false);
                            }
                        }}
                        disabled={!props.isFormDisabled}
                    >
                        Modify Organization
                    </button>
                    {props?.isSuperAdmin && <button className="btn-primary" id="deactivateOrg" onClick={() => deactivatOrganization(toggleButtonName)}
                        onKeyPress={(e) => {
                            if (e.code === "Space" || e.code === "Enter") {
                                e.preventDefault();
                                deactivatOrganization(toggleButtonName)
                            }
                        }}
                    >{`${toggleButtonName} Organization`}
                    </button>}
                </>
            }
            {showDeactivateOrgButtons && props.orgViewState === 'modify' &&
                <button className="btn-primary" id="deleteUser" onClick={() => { }}
                    onKeyPress={(e) => {
                        if (e.code === "Space" || e.code === "Enter") {
                            e.preventDefault();
                            // TODO
                        }
                    }}>Delete User</button>
            }
            {props.formErrorMsg &&
                <span className="error-message">
                    {props.formErrorMsg}
                </span>
            }
            <form onSubmit={handleSubmit}>
                <fieldset className="field-set">
                    <div className="org-name-input">
                        <InputComponent
                            id={"org_name"}
                            label="Name of Organization"
                            name="org_name"
                            type={"text"}
                            placeholder="Name of Organization goes here"
                            value={props.formData.org_name}
                            onChange={(e) => props.handleInputChange(e)}
                            disabled={props.isFormDisabled}
                            className="lg-input-field"
                            isRequired
                        />
                    </div>
                    <div className="website-address-input">
                        <InputComponent
                            id={"website_link"}
                            label="Website Address"
                            name="website_link"
                            type={"text"}
                            placeholder="https://www.oesca.org"
                            value={props.formData.website_link}
                            onChange={(e) => props.handleWebsiteURLChange(e)}
                            disabled={props.isFormDisabled}
                            className="lg-input-field"
                            isUrl
                            isRequired
                            setWebsiteURLErrorMsg={setWebsiteURLErrorMsg}
                        />
                        {websiteURLErrorMsg && <div id="error-msg" className="error-message">{websiteURLErrorMsg}</div>}
                    </div>
                    <div>
                        <DropdownField
                            id="org_type"
                            title="Organization Type"
                            value={props?.formData?.org_type}
                            name="org_type"
                            onChange={(e) => props.handleSelectDropdowns(e, ORGANIZATION_FIELDS?.ORGANIZATION_TYPE)}
                            options={organizationTypes}
                            placeholder="Select Organization Type"
                            isRequired
                            disabled={props.isFormDisabled}
                        />
                    </div>
                    <div className="upload-org-logo-wrapper">
                        <label>Organization Logo</label>
                        <div hidden>
                            <label htmlFor="upload-org-logo-input" />
                            <input hidden type="file" id="upload-org-logo-input" onChange={(e) => uploadImage(e)} />
                        </div>
                        <button id="uploadImagebtn" disabled={props.isFormDisabled} className="btn-secondary alternate" type="button"
                            onKeyPress={(e) => {
                                if (e.code === "Space" || e.code === "Enter") {
                                    e.preventDefault();
                                    document.getElementById('upload-org-logo-input').click();
                                }
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                document.getElementById('upload-org-logo-input').click();
                            }}
                        >
                            <label htmlFor="upload-org-logo-input">
                                <span className="upload-org-logo-input-label">Upload Image</span>
                            </label>
                        </button>
                        <div id="uploaded-logo-container" className='uploaded-logo-container'>
                            <img className='org-logo-src' src={orgImage}
                                alt={props?.formData?.logo_link ? props?.formData?.org_name : ''} />
                        </div>
                        <span className='logo-upload-requirements'>File types supported:  PNG, JPEG, SVG,  or BMP.</span>
                        <span className='logo-upload-requirements'>Files should not exceed: 5mb</span>
                    </div>
                </fieldset>
                <button className="btn-secondary" id="submit" disabled={!props.formData.org_name || !props.formData.website_link || !props?.formData?.org_type || props.isFormDisabled || websiteURLErrorMsg}>
                    Submit
                </button>
            </form>
        </div>
    );
};

export default React.memo(CreateOrg);
