import Filters from '../../Filters/Filters';
import './FilterModal.css'

function FilterModal (props) {
    return (
        <div className="_filter-modal mobile-only">
            <div className="head">
                { props.filters.length > 0 ?
                    <div className="title back clear" onClick={() => {props.setFilters([]); props.setFilterChange(0); props.setIsEndorsed(false);}}>Clear Filters<img className="tilt-45" src="/icons/cross.svg" alt="chevron" /></div> :
                    <div className="title back" onClick={() => {props.setBackDrop(!props.backDrop)}}><img className="left" src="/icons/chevron.svg" alt="chevron" />Back</div>
                }
                <div className="icon close" onClick={() => {props.setBackDrop(!props.backDrop)}}>
                    <img className='close-svg' src="/icons/x-icon-dark.svg" alt="x close icon" />
                </div>
            </div>
            <div className="section">
                <Filters gtag={props.gtag} filters={props.filters} setFilters={props.setFilters} filterChange={props.filterChange} setFilterChange={props.setFilterChange} switchFilters={props.switchFilters} multiFilters={props.multiFilters} isEndorsed={props.isEndorsed} setIsEndorsed={props.setIsEndorsed} />
            </div>
        </div>
    );
}

export default FilterModal;
