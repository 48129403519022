import React, { useEffect, useState } from 'react';
import './InputComponent.css'

const InputComponent = ({ label, id, type, name, value, placeholder, onChange, width, height, maxLength, className, isCharacterCount, isRequired, disabled, subTitle = undefined, submitAttempted = false, isUrl, setWebsiteURLErrorMsg }) => {

    const [isInputValid, setIsInputValid] = useState(!submitAttempted);

    const inputClassName = (!isInputValid && isRequired) ? `${className} invalid` : className;
    const displayLabel = label.includes("(") ? label.substring(0, label.indexOf("(")) : label;
    const errorMessage = `* ${displayLabel} is required`;

    useEffect(() => {
        if (submitAttempted) {
            isRequired && setIsInputValid(value?.length)
        } else {
            setIsInputValid(true);
        }
    }, [submitAttempted]);

    const onBlur = (e) => {
        isRequired && setIsInputValid(value?.length);
        if (isUrl) {
            setWebsiteURLErrorMsg("");
            const value = e.target.value;
            const isWebsiteURLValid = /^((https|http):\/\/)(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
            if (!isWebsiteURLValid) {
                setWebsiteURLErrorMsg("Please provide a valid web address.");
                return;
            } else {
                setWebsiteURLErrorMsg("");
            }
        }
    }

    const onFocus = (e) => {
        if (e.target.id === "date") {
            e.target.type = "date"
        }
        setIsInputValid(true)
    }

    return (
        <div className="input">
            {label}
            <input
                id={id}
                name={name}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                maxLength={maxLength}
                className={inputClassName}
                onBlur={onBlur}
                onFocus={onFocus}
                disabled={disabled}
                min={type === 'number' ? 0 : undefined}
                max={type === 'number' ? 100000000 : undefined}
            />
            {subTitle && <div className="subtitle">{subTitle}</div>}
            {(!isInputValid && isRequired) && (<span id="error-msg" className="error-message">{errorMessage}</span>)}
            <div>
                {isCharacterCount && <span className="character-count">Character count: {value?.length} / {maxLength}</span>}
            </div>

        </div>
    );
};

export default InputComponent;
