import React from 'react';
import './ResourceView.css';
import TextArea from '../common/TextArea';

function ResourceView(props) {
  const displayValues = (values, key) => {
    if (key === 'oh_teaching_standards') {
      return values.map((value, index) => {
        return <p key={index}>{value}</p>;
      });
    }

    if (typeof values === 'object') {
      return values?.join('; ');
    } else {
      return values;
    }
  };

  const decodeIfNecessary = (content) => {
    return content ? (content.includes(' ') ? content : decodeURIComponent(content)) : '';
  };

  const displayDateValues = (value) => {
    let date = new Date(value).toLocaleDateString('en-US');
    return value ? date : '';
  };
  const isReplaceFileAction = props.uploadedFile ? '' : 'upload-evaluation';

  return (
    <div className="_resource-view">
      <div className="section-row">
        <div className="md-content-provider">
          <div className="heading-title">Content Provider</div>
          <div className="value">{displayValues(props.data?.org_name?.raw)}</div>
        </div>
        <div className="section-cost-field">
          <div className="vsm-input-field">
            <div className="heading-title">Cost</div>
            <div className="value">${displayValues(props.data?.cost?.raw)}</div>
          </div>
        </div>
      </div>
      <div>
        <div className="heading-title">Title (80 characters)</div>
        <div className="value">{decodeIfNecessary(displayValues(props.data?.title?.raw))}</div>
      </div>

      <div className="section-row">
        <div className="sm-text-area">
          <div className="heading-title">Short Description (140 characters)</div>
          <div className="value-short">
            {decodeIfNecessary(displayValues(props.data?.short_description?.raw))}
          </div>
        </div>
        <div className="section-field">
          <div className="md-text-area">
            <div className="heading-title">Long description</div>
            <div className="value-long">
              {decodeIfNecessary(displayValues(props.data?.long_description?.raw))}
            </div>
          </div>
        </div>
      </div>
      <div className="section-row w-42">
        <div className="heading-title">Listing URL</div>
        <div className="value-url">
          <a href={displayValues(props.data?.resource_url?.raw)} target="_blank" rel="noreferrer">
            {displayValues(props.data?.resource_url?.raw)}
          </a>
        </div>
      </div>
      <div className="section-row">
        <div>
          <div className="heading-title">Media Type</div>
          <div className="value">{displayValues(props.data?.media_type?.raw)}</div>
        </div>
      </div>
      <div className="section-row publish-dates ">
        <div className="publish-date">
          <div className="sm-input-field">
            <div className="heading-title">Start Date (Scheduled Event)</div>
            <div className="value">{displayDateValues(props.data?.starting_date?.raw)}</div>
          </div>
        </div>
        <div className="section-field">
          <div className="sm-input-field">
            <div className="heading-title">Unpublish Date (Scheduled Event)</div>
            <div className="value">{displayDateValues(props.data?.expiration_date?.raw)}</div>
          </div>
        </div>
      </div>
      <div className="section-topic">
        <div>
          <div className="heading-title">Topic (Max = 5) </div>
          <div className="value">{displayValues(props.data?.topic?.raw)}</div>
        </div>
      </div>
      <div className="section-row">
        <div>
          <div className="heading-title">Audience</div>
          <div className="value">{displayValues(props.data?.audience?.raw)}</div>
        </div>
      </div>
      <div className="section-row">
        <div>
          <div className="heading-title">OH Teaching Standards (optional) </div>
          <div className="value">
            {displayValues(props.data?.oh_teaching_standards?.raw, 'oh_teaching_standards')}
          </div>
        </div>
      </div>
      <div className="section-row">
        <div>
          <div className="heading-title">Grade Span </div>
          <div className="value">{displayValues(props.data?.grade_span?.raw)}</div>
        </div>
      </div>
      <div className="section-row-credit">
        <div className="credit-type">
          <div className="sm-dropdown-field-credit">
            <div className="heading-title">Credit Type (optional)</div>
            <div className="value">{displayValues(props.data?.completion_credit?.raw)}</div>
          </div>
        </div>
        <div className="section-field-sm">
          <div className="sm-input-field-credit-hours">
            <div className="heading-title">Contact/Credit Hours (optional) </div>
            <div className="value">{displayValues(props.data?.credit_hours?.raw)}</div>
          </div>
        </div>
      </div>
      <div className="section-row">
        <div className="md-input-field">
          <div className="heading-title">Learning Objectives (optional) </div>
          <div className="value">
            {decodeIfNecessary(displayValues(props.data?.learning_objectives?.raw))}
          </div>
        </div>
        <div className="section-field">
          <div className="md-input-field">
            <div className="heading-title">Search tags (optional) </div>
            <div className="value">{displayValues(props.data?.search_tags?.raw)}</div>
          </div>
        </div>
      </div>
      {props.isFromEndorseTab && (
        <>
          <div className="upload-evaluation">
            <div className="upload-section">
              <form hidden encType="multipart/form-data" method="POST" id="uploadFile">
                <label htmlFor="upload-evaluation" />
                <input
                  hidden
                  type="file"
                  id="upload-evaluation"
                  onChange={(e) => props.uploadFile(e)}
                />
              </form>

              {props.uploadedFile ? (
                <button
                  className="btn-secondary replace-uploaded-file"
                  onClick={() => props.handleOpenDialog()}>
                  Upload Evaluation
                </button>
              ) : (
                <button
                  className="btn-secondary alternate upload"
                  type="button"
                  onKeyPress={(e) => {
                    if (e.code === 'Space' || e.code === 'Enter') {
                      e.preventDefault();
                      document.getElementById('upload-evaluation').click();
                    }
                  }}>
                  <label htmlFor={isReplaceFileAction}>
                    <span className="upload-evaluation-label">Upload Evaluation</span>
                  </label>
                </button>
              )}
            </div>
            {props?.uploadedFile && (
              <span className="uploaded-file">
                {props?.uploadedFile}{' '}
                <span className="uploaded-file-cross" onClick={props.removeUploadedFile}>
                  X
                </span>
              </span>
            )}
          </div>
          <span className="file-types">
            File types supported: jpg/jpeg, png, pdf, xlsx, txt, docx files should not exceed: 5mb
          </span>
        </>
      )}
      <hr className="divider-hr" />
      <div className="section-row">
        <TextArea
          label="Reviewer/Endorser Comments"
          type="textarea"
          name={
            document.location.pathname.match('Review') ? 'reviewer_comments' : 'endorser_comments'
          }
          value={
            document.location.pathname.match('Review')
              ? props.data?.reviewer_comments?.raw
              : props.data?.endorser_comments?.raw
          }
          onChange={(e) => props.handleTextAreaChange(e)}
          placeholder="Please provide any notes or comments for the author of this resource."
          rows="5"
          cols="33"
          className="lg-text-area"
        />
      </div>
      {props.data?.creater_comments?.raw && (
        <div className="section-row disabled">
          <div className="label">Creator Comments</div>
          <div className="comments">{props.data?.creater_comments?.raw}</div>
        </div>
      )}
      {document.location.pathname.match('Review') && props.data?.endorser_comments?.raw && (
        <div className="section-row disabled">
          <div className="label">Endorser Comments</div>
          <div className="comments">{props.data?.endorser_comments?.raw}</div>
        </div>
      )}
      {document.location.pathname.match('Endorse') && props.data?.reviewer_comments?.raw && (
        <div className="section-row disabled">
          <div className="label">Reviewer Comments</div>
          <div className="comments">{props.data?.reviewer_comments?.raw}</div>
        </div>
      )}
      <button
        className="btn-secondary"
        id="approve"
        onClick={() => props.handleApproveResource()}
        disabled={props.postCallType}>
        Approve
      </button>
      <button
        className="btn-secondary alternate"
        id="reject"
        onClick={() => props.handleRejectResource()}
        disabled={props.postCallType}>
        Reject
      </button>
      {props.handleBackToListings &&
        <button
          className="btn-secondary alternate"
          id="unlock"
          onClick={() => props.handleBackToListings()}>
          Leave Page and Unlock
        </button>
      }
    </div>
  );
}

export default ResourceView;
