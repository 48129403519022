import React from "react";
import './ResourceConfirmationView.css';

function ResourceConfirmationView(props) {

    return (
        <div className="_confirmation">
            <div className="confirmed-main">
                <div className="confirmed-submit-resource">
                    <div className="content">
                        {props?.postResults?.id ? (
                            <>
                                <div className="confirmation-title">
                                    {props?.heading}
                                </div>
                                <div className="confirmation-message">
                                    <span >{props?.subTextOne}</span><br />
                                    <span>{props?.subTextTwo}</span><br />
                                </div>
                            </>
                        ) : (
                            <div className="post-results-error-message">
                                {props.postResults?.errors?.join(',')}
                            </div>
                        )}
                        <button className="btn-secondary" id="backToDashboard" onClick={() => props.handleBackToListings()}
                            onKeyPress={(e) => {
                                if (e.code === "Space" || e.code === "Enter") {
                                    e.preventDefault();
                                    props.handleBackToListings();
                                }
                            }}>Go to Dashboard</button>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResourceConfirmationView;
