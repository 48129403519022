import { useEffect, useState } from "react";
import DropdownField from "../common/DropdownField";
import { MULTIPLE_SELECTED } from "../../constants"

const DropdownMultiFilter = (props) => {
    const [dropdownMultiFilterValue, setDropdownMultiFilterValue] = useState(null);

    useEffect(() => {
        if (props?.contentProviderFilter?.length === 1) {
            setDropdownMultiFilterValue({ value: props?.contentProviderFilter[0], label: props?.contentProviderFilter[0] })
        }
        else if (props?.contentProviderFilter?.length > 1) {
            setDropdownMultiFilterValue({ value: MULTIPLE_SELECTED, label: MULTIPLE_SELECTED })
        }
        else {
            setDropdownMultiFilterValue(null)
        }

    }, [props?.contentProviderFilter]);

    const customFilterOption = (option, rawInput) => {
        const textToSearch = option?.data?.text?.toLowerCase();
        const searchTerm = rawInput?.toLowerCase();
        return textToSearch?.includes(searchTerm);
    };

    return (
        <DropdownField
            id={props.id}
            value={dropdownMultiFilterValue}
            name={props.name}
            options={props.getFiltervalues(props.values)}
            placeholder={props.placeholder}
            closeMenuOnSelect={false}
            filterOption={customFilterOption}
            dynamicDropdownHeight={true}
        />
    )
}

export default DropdownMultiFilter;
