import React from "react";

function SwitchFilter(props) {

    const handleClick = (name) => {
        if (props.filters.includes(name)) {
            // NOTE: implement gtags below if the isEndorsed filter is re-implemented
            // props.gtag('event', 'remove_filter', {'item': endorsed, 'category': ''});
            let filters = [...props.filters];
            filters.splice(props.filters.indexOf(props.isEndorsed), 2)
            props.setFilters(filters);
            props.setFilterChange(props.filterChange + 1);
        } else {
            // props.gtag('event', 'add_filter', {'item': endorsed, 'category': ''});
            props.setFilters([!props.isEndorsed, name, ...props.filters]);
            props.setFilterChange(props.filterChange + 1);
        }

    }


    return (<div className="switch-filter" onClick={() => {
        props.setIsEndorsed(!props.isEndorsed)
        handleClick(props.name)
    }}>
        <div className="filter-key">{props.name}</div>
        <div className="filter-values">{props.isEndorsed ? (<div className="on">ON <div className="circle"></div></div>) : (<div className="off"><div className="circle"></div>OFF</div>)}</div>
    </div>)
}

export default SwitchFilter;
