import { useState, useEffect } from 'react';
import './ChangePassword.css';
import { CHANGE_PASSWORD_URL } from '../../constants';
import { useNavigate } from 'react-router-dom';
import utilityService from '../../Services/utilityService';

const Field = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  onKeyUp,
  passwordType,
  requirementsError,
  passwordMatchError,
  successMessage,
  apiErrorMessage,
  id
}) => {
  return (
    <div
      className={
        requirementsError || passwordMatchError
          ? '_change_password__form_row_no_errors'
          : '_change_password__form_row'
      }>
      <label className="_change_password__form_label" for={id}>
        {label}
        <br />
        <input
          className="_change_password__form_input"
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          id={id}
        />
      </label>
      {passwordType === 'newPassword' && requirementsError && (
        <p className="_change_password__form_error">Password does not meet requirements.</p>
      )}
      {passwordType === 'confirmPassword' && passwordMatchError && (
        <p className="_change_password__form_error">Passwords must match.</p>
      )}
      {successMessage && <p className="_change_password__form_success">{successMessage}</p>}
      {apiErrorMessage && <p className="_change_password__form_error">{apiErrorMessage}</p>}
    </div>
  );
};

const Form = ({ onSubmit, successMessage, apiErrorMessage, setApiErrorMessage, userToken }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Validation state setter
  const [contains8C, setContains8C] = useState(false); // min 8 characters
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsSC, setContainsSC] = useState(false); // special character
  const [passwordMatch, setPasswordMatch] = useState(false); // passwords match
  const [allValid, setAllValid] = useState(false); // checks all validations are true

  // Error message state setter
  const [requirementsError, setRequirementsError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  // Password rule check
  const validatePassword = () => {
    setApiErrorMessage('');
    // has uppercase letter
    if (password.toLowerCase() !== password) {
      setContainsUL(true);
    } else {
      setContainsUL(false);
      setRequirementsError(true);
    }

    if (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password)) {
      setContainsSC(true);
    } else {
      setContainsSC(false);
      setRequirementsError(true);
    }

    // has 8 characters
    if (password.length > 8) {
      setContains8C(true);
    } else {
      setContains8C(false);
      setRequirementsError(true);
    }

    // passwords match
    if (password !== '' && password === confirmPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
      setPasswordMatchError(true);
    }

    // Three rules match
    if (containsUL && containsSC && contains8C) {
      setRequirementsError(false);
    }

    // all validations passed
    if (containsUL && containsSC && contains8C && passwordMatch) {
      setAllValid(true);
      setRequirementsError(false);
      setPasswordMatchError(false);
    } else {
      setAllValid(false);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const profileObject = utilityService.parseJwt(utilityService.getCookie('auth'));
    let data = {};

    if (userToken) {
      data = {
        body: {
          token: userToken,
          password: password
        }
      };
    } else {
      data = {
        body: {
          userId: profileObject.id,
          password: password
        }
      };
    }
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Field
        onChange={handlePasswordChange}
        value={password}
        onKeyUp={validatePassword}
        label="New Password"
        type="password"
        placeholder="Enter a new password"
        passwordType="newPassword"
        requirementsError={requirementsError}
        id="newPassword"
      />
      <Field
        onChange={handleConfirmPasswordChange}
        value={confirmPassword}
        onKeyUp={validatePassword}
        successMessage={successMessage}
        apiErrorMessage={apiErrorMessage}
        label="Retype New Password"
        type="password"
        placeholder="Enter a new password"
        passwordType="confirmPassword"
        passwordMatchError={passwordMatchError}
        id="retypeNewPassword"
      />
      <p className="_change_password__form_disclaimer">
        If you forgot your password please contact adminopld@oesca.org
      </p>
      <button className="_change_password__form_submit" disabled={!allValid} type="submit">
        Change Password
      </button>
    </form>
  );
};

function ChangePassword(props) {
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [userToken, setUserToken] = useState();

  const onSuccess = () => {
    setSuccessMessage('Password successfully changed.');
    setTimeout(() => {
      if (userToken) {
        navigate('/Admin/Login');
      } else {
        navigate('/Admin/Create');
      }
    }, 3700);
  };

  useEffect(() => {
    const fullParams = new URLSearchParams(window.location.search) || '';
    setUserToken(fullParams.get('token'));
    if (!props.authCookie && userToken === '') {
      navigate('/');
    }
  }, []);

  const handleSubmit = (data) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Access-Control-Request-Headers': '*',
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(data)
    };
    fetch(CHANGE_PASSWORD_URL, requestOptions).then(async (response) => {
      const isJson = response.headers.get('content-type')?.includes('application/json');
      const data = isJson && (await response.json());
      let parsedData;
      if (typeof data.body === 'string') {
        parsedData = JSON.parse(data.body);
      }
      if (data.statusCode === 500 && data.body.message) {
        setApiErrorMessage(data.body.message);
        return;
      }
      if (data.statusCode === 500) {
        setApiErrorMessage('Something went wrong, please try again in a bit.');
        return;
      }
      if (data.statusCode === 400) {
        setApiErrorMessage(data.body.message);
        return;
      }
      if (parsedData[0].errors.length === 0) {
        onSuccess();
      }
    });
  };

  return (
    <>
      <div className="_change_password">
        <div id="_change_password__form_container">
          <h1 className="_change_password__header">Change Password</h1>
          <hr className="_change_password__hr" />
          <Form
            onSubmit={handleSubmit}
            successMessage={successMessage}
            apiErrorMessage={apiErrorMessage}
            setApiErrorMessage={setApiErrorMessage}
            userToken={userToken}
          />
        </div>
        <div id="_change_password__password_rules">
          <p className="_change_password__password_rules__title">Password Requirements:</p>
          <ul className="_change_password__password_rules__list">
            <li className="_change_password__password_rules__list_item">
              Be at least 8 characters
            </li>
            <li className="_change_password__password_rules__list_item">
              Include at least one uppercase letter
            </li>
            <li className="_change_password__password_rules__list_item">
              Include at least one special character
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
