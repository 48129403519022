import React, { useEffect, useState } from 'react';
import './CreateResourceForm.css';
import InputComponent from '../../common/InputComponent';
import DateComponent from '../../common/DateComponent';
import TextArea from '../../common/TextArea';
import DropdownField from '../../common/DropdownField';
import MultiSelectField from '../../common/MultiSelectField';
import {
  MEDIA_TYPE_OPTIONS,
  AUDIENCE_OPTIONS,
  TOPIC_OPTIONS,
  GRADE_SPAN_OPTIONS,
  CREDIT_TYPE_OPTIONS,
  OH_TEACHING_STANDARD_OPTIONS,
  TRUE
} from '../../../constants';

function CreateResourceForm(props) {
  const {
    formData,
    formErrorMessage,
    requestEndorsement,
    handleOnSave,
    handleSelectDropdowns,
    handleInputChange,
    handleResourceURLChange,
    handleTextAreaChange,
    handleSearchTagsChange,
    handleRequestEndorsement,
    isEditingDisabled,
    setFormChanged,
    submitAttempted = false
  } = props;
  const [orgOptions, setOrgOptions] = useState([]);
  const [websiteURLErrorMsg, setWebsiteURLErrorMsg] = useState('');

  useEffect(() => {
    const orgIDs = props.user?.org_id;
    const orgs = [];
    props.organizations
      ?.filter((org) => orgIDs?.includes(org?.id?.raw))
      ?.filter((org) => org?.is_active?.raw === TRUE)
      .forEach((org) => orgs.push(org));
    const options = [];
    orgs.forEach((org) => {
      options.push({ value: org?.org_name?.raw, label: org?.org_name?.raw });
    });
    setOrgOptions(options);
    formData.contentProvider = options[0];
  }, [props.organizations, props.user]);

  const costType = isEditingDisabled ? 'text' : 'number';

  return (
    <div className="_create-resource" id="create-resource-form">
      <form onSubmit={handleOnSave}>
        <fieldset className="field-set" disabled={isEditingDisabled}>
          <div className="section-row">
            <DropdownField
              title={'Content Provider'}
              id={'content-provider'}
              className="md-content-provider"
              value={formData.contentProvider}
              onChange={(e) => {
                setFormChanged(true);
                handleSelectDropdowns(e, 'contentProvider');
              }}
              options={orgOptions}
              placeholder="Enter a content provider here"
              disabled={orgOptions.length === 1 ? true : isEditingDisabled}
              submitAttempted={submitAttempted}
            />
            <div className="section-cost-field">
              <InputComponent
                id={'cost-input'}
                label="Cost per Person"
                name="cost"
                type={costType}
                placeholder="ex. 999"
                value={formData.cost}
                onChange={(e) => {
                  setFormChanged(true);
                  handleInputChange(e);
                }}
                className="vsm-input-field"
                submitAttempted={submitAttempted}
              />
            </div>
          </div>
          <div>
            <InputComponent
              id={'create-title'}
              label="Title (80 characters)"
              type="text"
              name="title"
              placeholder="Enter a title here"
              value={formData.title}
              onChange={(e) => {
                props.setFormChanged(true);
                handleInputChange(e);
              }}
              maxLength={'80'}
              className="lg-input-field"
              isCharacterCount
              isRequired
              submitAttempted={submitAttempted}
            />
          </div>

          <div className="section-row">
            <TextArea
              id={'short-desc-input'}
              label="Short Description (140 characters)"
              type="text"
              name="shortDescription"
              value={formData.shortDescription}
              onChange={(e) => {
                props.setFormChanged(true);
                handleTextAreaChange(e);
              }}
              placeholder="Enter a short description here"
              rows="5"
              cols="33"
              maxLength={'140'}
              className="sm-text-area"
              isCharacterCount
              isRequired
              submitAttempted={submitAttempted}
            />
            <div className="section-field">
              <TextArea
                id={'long-desc-input'}
                label="Long Description"
                type="text"
                name="longDescription"
                value={formData.longDescription}
                onChange={(e) => {
                  props.setFormChanged(true);
                  handleTextAreaChange(e);
                }}
                placeholder="Enter a long description here"
                rows="5"
                cols="33"
                className="md-text-area"
                isRequired
                maxLength={'1000'}
                submitAttempted={submitAttempted}
              />
            </div>
          </div>
          <div>
            <InputComponent
              id={'create-url'}
              label="Resource URL"
              type="text"
              name="listingURL"
              value={formData.listingURL}
              placeholder="https://www.oesca.org"
              onChange={(e) => {
                props.setFormChanged(true);
                handleResourceURLChange(e);
              }}
              className="lg-input-field"
              isRequired
              isUrl
              submitAttempted={submitAttempted}
              setWebsiteURLErrorMsg={setWebsiteURLErrorMsg}
            />
            {websiteURLErrorMsg && (
              <div id="error-msg" className="error-message">
                {websiteURLErrorMsg}
              </div>
            )}
          </div>
          <div className="section-row">
            <DropdownField
              id={'media-type'}
              title={'Media Type'}
              className="md-dropdown-field"
              value={formData.mediaType}
              name="mediaType"
              onChange={(e) => {
                props.setFormChanged(true);
                handleSelectDropdowns(e, 'mediaType');
              }}
              options={MEDIA_TYPE_OPTIONS}
              placeholder="Select a media type"
              isRequired
              disabled={isEditingDisabled}
              submitAttempted={submitAttempted}
            />
          </div>
          <div className="section-row publish-dates ">
            <div className="publish-date">
              <DateComponent
                id={'publish-date-calender'}
                label="Start Date (Scheduled Event)"
                selected={formData.publishDate}
                name="publishDate"
                onChange={(e) => {
                  props.setFormChanged(true);
                  props.handleDateChange(e, 'publishDate');
                }}
                className="publish-date-calender"
                disabled={isEditingDisabled}
                required={formData?.mediaType?.value?.includes('Event')}
                submitAttempted={submitAttempted}
                formData={formData}
                formErrorMessage={props.formErrorMessage}
                formChanged={props.formChanged}
                setFormErrorMessage={props.setFormErrorMessage}
              />
            </div>
            <div className="section-field">
              <DateComponent
                id={'unpublish-date-calender'}
                label="Unpublish Date (Scheduled Event)"
                selected={formData.unpublishDate}
                name="unpublishDate"
                onChange={(e) => {
                  props.setFormChanged(true);
                  props.handleDateChange(e, 'unpublishDate');
                }}
                className="unpublish-date-calender"
                disabled={isEditingDisabled}
                required={formData?.mediaType?.value?.includes('Event')}
                submitAttempted={submitAttempted}
                formData={formData}
                formErrorMessage={props.formErrorMessage}
              />
            </div>
          </div>
          <div className="section-topic">
            <MultiSelectField
              id={'topic'}
              title={'Topic (Max = 5)'}
              className="md-multi-select"
              value={formData.topic}
              onChange={(e) => {
                props.setFormChanged(true);
                handleSelectDropdowns(e, 'topic');
              }}
              isMulti
              isRequired
              placeholder="Select a topic"
              limitOptions={true}
              maxLimit={5}
              options={TOPIC_OPTIONS}
              submitAttempted={submitAttempted}
              isClearable={false}
            />
          </div>
          <div className="section-topic">
            <MultiSelectField
              id={'audience'}
              title={'Audience'}
              className="md-multi-select"
              value={formData.audience}
              onChange={(e) => {
                props.setFormChanged(true);
                handleSelectDropdowns(e, 'audience');
              }}
              isMulti
              isRequired
              placeholder="Select an audience"
              options={AUDIENCE_OPTIONS}
              submitAttempted={submitAttempted}
              isClearable={false}
            />
          </div>
          <div className="section-topic">
            <MultiSelectField
              id={'oh-teaching-standards'}
              title={'OH Teaching Standards (optional)'}
              className="lg-multi-select"
              value={formData.ohTeachingStandards}
              onChange={(e) => {
                props.setFormChanged(true);
                handleSelectDropdowns(e, 'ohTeachingStandards');
              }}
              isMulti
              isOhTeaching
              placeholder="Select standard"
              options={OH_TEACHING_STANDARD_OPTIONS}
              submitAttempted={submitAttempted}
              isClearable={false}
            />
          </div>
          <div className="section-topic">
            <MultiSelectField
              id={'grade-span'}
              title={'Grade Span'}
              className="sm-multi-select"
              value={formData.gradeSpan}
              onChange={(e) => {
                props.setFormChanged(true);
                handleSelectDropdowns(e, 'gradeSpan');
              }}
              isMulti
              isRequired
              placeholder="Select grade span"
              options={GRADE_SPAN_OPTIONS}
              submitAttempted={submitAttempted}
              isClearable={false}
            />
          </div>
          <div className="section-row-credit">
            <div className="credit-type">
              <MultiSelectField
                id={'credit-type'}
                title={'Credit Type (optional)'}
                className="sm-multi-select"
                value={formData.creditType}
                onChange={(e) => {
                  props.setFormChanged(true);
                  handleSelectDropdowns(e, 'creditType');
                }}
                isMulti
                placeholder="Select one"
                options={CREDIT_TYPE_OPTIONS}
                submitAttempted={submitAttempted}
                isClearable={false}
              />
            </div>

            <div className="section-field-sm">
              <InputComponent
                id={'credit-hours-input'}
                label="Contact/Credit Hours (optional)"
                type="number"
                name="contactOrCreditHours"
                value={formData.contactOrCreditHours}
                placeholder="Enter hours here"
                onChange={(e) => {
                  props.setFormChanged(true);
                  handleInputChange(e);
                }}
                className="sm-input-field-credit-hours"
                submitAttempted={submitAttempted}
              />
            </div>
          </div>
          <div className="section-row">
            <InputComponent
              id={'learning-objectives-input'}
              label="Learning Objectives (optional)"
              type="text"
              name="learningObjectives"
              value={formData.learningObjectives}
              placeholder="Enter learning objectives here"
              onChange={(e) => {
                props.setFormChanged(true);
                handleInputChange(e);
              }}
              className="md-input-field"
              submitAttempted={submitAttempted}
            />
            <div className="section-field">
              <InputComponent
                id={'tags-input'}
                label="Search tags (optional)"
                type="text"
                name="searchTags"
                value={formData.searchTags}
                placeholder="Enter search tags here"
                onChange={(e) => {
                  props.setFormChanged(true);
                  handleSearchTagsChange(e);
                }}
                className="md-input-field"
                subTitle={'Use ";" to separate tags.'}
                submitAttempted={submitAttempted}
              />
            </div>
          </div>
          <div className="checkbox-section">
            <input
              id={'endorsement-checkbox'}
              type="checkbox"
              name={'requestEndorsement'}
              onChange={(e) => {
                props.setFormChanged(true);
                handleRequestEndorsement(e);
              }}
              checked={requestEndorsement}
            />
            <span className="checkbox-label">Request Endorsement</span>
          </div>

          <div className="section-row">
            <TextArea
              id={'reviewer-instr-input'}
              label="Listing Reviewer/Endorser Instructions (optional)"
              type="text"
              name="listingReviewerInstructions"
              value={formData.listingReviewerInstructions}
              onChange={(e) => {
                props.setFormChanged(true);
                handleTextAreaChange(e);
              }}
              placeholder="Please provide any notes or comments for the reviewer/endorser of this resource."
              rows="5"
              cols="33"
              className="lg-text-area"
              submitAttempted={submitAttempted}
            />
          </div>
          {props.getFileName && (
            <div className="section-row">
              <a
                href={props.result?.endorsement_evaluation_link?.raw}
                download
                target="_blank"
                className="downloadFile">
                {props.getFileName}
                <img className="downloadIcon" src="/icons/download.svg" alt="download" />
              </a>
            </div>
          )}
          {props.result?.endorser_comments?.raw && (
            <div className="section-row disabled">
              <div className="label">Endorser Comments</div>
              <div className="comments">{props.result?.endorser_comments?.raw}</div>
            </div>
          )}
          {props.result?.reviewer_comments?.raw && (
            <div className="section-row disabled">
              <div className="label">Reviewer Comments</div>
              <div className="comments">{props.result?.reviewer_comments?.raw}</div>
            </div>
          )}
        </fieldset>
      </form>
      {!isEditingDisabled && (
        <button className="btn-secondary" id="save" onClick={handleOnSave}>
          Continue
        </button>
      )}
      <div className="form-error">{formErrorMessage}</div>
    </div>
  );
}

export default CreateResourceForm;
