import React from "react";
import './CreateUser.css';
import InputComponent from '../../common/InputComponent'
import DropdownField from '../../common/DropdownField';

function CreateUser({
    formErrorMsg,
    emailAddressErrorMsg,
    formData,
    handleInputChange,
    handleCheckbox,
    handleEmailAddress,
    handleSelectDropdowns,
    handleSubmit,
    organizationOptions,
    checkedState,
    availableRolesToCreate,
}) {

    const disableSubmitBtn = !(formData.firstName && formData.lastName && formData.emailAddress && formData.contentProvider?.length > 0 && formData.roles?.length > 0);

    return (
        <div className="_create-user" id="create-user-form">
            <div className="title">Create a New User</div>
            <hr className="divider" />
            {formErrorMsg && <span className="error-message">{formErrorMsg}</span>}
            <form onSubmit={() => { }}>
                <fieldset className="field-set">
                    <div className="section-row">
                        <div className="first-name-input">
                            <InputComponent
                                id={"firstName"}
                                label="First Name"
                                name="firstName"
                                type={"text"}
                                placeholder="First name"
                                value={formData.firstName}
                                onChange={(e) => handleInputChange(e)}
                                className="md-input-field"
                                isRequired
                            />
                        </div>
                        <div className="last-name-input">
                            <InputComponent
                                id={"lastName"}
                                label="Last Name"
                                name="lastName"
                                type={"text"}
                                placeholder="Last name"
                                value={formData.lastName}
                                onChange={(e) => handleInputChange(e)}
                                className="md-input-field"
                                isRequired
                            />
                        </div>

                    </div>
                    <InputComponent
                        id={"emailAddress"}
                        label="Email Address"
                        name="emailAddress"
                        type={"text"}
                        placeholder="emailaddress@organization.com"
                        value={formData.emailAddress}
                        onChange={(e) => handleEmailAddress(e)}
                        className="lg-input-field"
                        isRequired
                    />
                    {emailAddressErrorMsg && <span id="error-msg" className="error-message">{emailAddressErrorMsg}</span>}
                    <DropdownField
                        title={"Content Provider"}
                        id={"contentProvider"}
                        className="md-content-provider"
                        value={formData.contentProvider}
                        onChange={(e) => handleSelectDropdowns(e, "contentProvider")}
                        options={organizationOptions}
                        placeholder="Select content provider"
                        disabled={false}
                        isRequired
                        isMulti
                    />
                    <div className="roles-section" >
                        <label id="roles">Roles</label>
                        {availableRolesToCreate.map((name, index) => {
                            return (
                                <div className="checkbox-section" key={index}>
                                    <input
                                        className={((checkedState[3] === true && index < 3) || (checkedState[4] === true && index < 4)) ? 'disabled-checkbox' : ""}
                                        type="checkbox"
                                        id={`role-checkbox-${index + 1}`}
                                        name={name}
                                        value={name}
                                        checked={checkedState[index]}
                                        onChange={() => handleCheckbox(index)}
                                        onKeyPress={(e) => {
                                            if (e.code === "Space" || e.code === "Enter") {
                                                e.preventDefault();
                                                handleCheckbox(index)
                                            }
                                        }}
                                    />
                                    <label htmlFor={`custom-checkbox-${index}`} className="checkbox-label">{name}</label>
                                </div>
                            );
                        })}
                    </div>
                </fieldset>
            </form>
            <button className="btn-secondary" id="submit" onClick={() => handleSubmit("create")} disabled={disableSubmitBtn}
                onKeyPress={(e) => {
                    if (e.code === "Space" || e.code === "Enter") {
                        e.preventDefault();
                        handleSubmit("create")
                    }
                }}>Submit</button>
        </div>
    )
}

export default CreateUser;
