import { useEffect, useState } from 'react';
import './DropDown.css';

function DropDown(props) {
    const options = [];
    const [expandSortDropDown, setExpandSortDropDown] = useState(false);

    const getSelectionClass = (opt, index) => {
        let classes = ``;
        if (props.toggleAscending) {
            if (props.fieldMap[opt] === props.field) {
                if (opt === 'Oldest' || opt === 'Newest') {
                    if ((props.ascending && opt === 'Oldest') || (!props.ascending && opt === 'Newest')) {
                        classes += `current`;
                   } 
                } else if (props.ascending && (index === 0 || !(index % 2))) {
                    classes += `current`;
                } else if (!props.ascending && ( index % 2)) {
                    classes += `current`;
                }
            }
        } else {
            if (props.fieldMap[opt] === props.field) {
                classes += `current`;
            }
        }
        return classes;
    }

    props.options.forEach((opt, index) => {
        options.push(
            <div
                id={`${index}-opt`} 
                key={`${index}-opt`} 
                className={getSelectionClass(opt, index)}
                tabIndex="0" 
                onClick={() => { update(opt) }}
                onBlur={() => {
                    if (index === props.options.length - 1) {
                        setExpandSortDropDown(false);
                        setTimeout(() => {
                            document.getElementById("tile-0").focus();
                            setExpandSortDropDown(false);
                        }, 0)
                    }
                }}
                onKeyPress={(e) => {
                    if (e.code === "Space" || e.code === "Enter") {
                        e.preventDefault();
                        update(opt);
                    }
                }}
            >
                {opt}
            </div>
        );
    })

    useEffect(() => {
        if (!props.basic) {
            props.setChange(props.change+1);
            setExpandSortDropDown(false);
        }
      }, [props.field, props.ascending, props.query]);

    function update(field) {
        setExpandSortDropDown(!expandSortDropDown);
        
        if (props.options.indexOf(field) % 2 && props.options.indexOf(field) > 0) {
            props.setAscending(false)
        } else {
            props.setAscending(true);
        }
        if (field === 'Oldest') {
            props.setAscending(true);
        }
        if (field === 'Newest') {
            props.setAscending(false);
        }
        props.setField(props.fieldMap[field]);
        
    }

    return (
        <>
            {props.basic ?
                <div className="_dropdown-wrapper-basic">
                    <span className="label">{`${props.label}:`}</span>
                    <div
                        className={expandSortDropDown ? '_drop-down expanded' : '_drop-down collapsed'} 
                        id={`${props.id}-drop-down`}
                        data-type={props.basic ? 'basic' : ''}
                        onClick={() => {
                            setExpandSortDropDown(!expandSortDropDown);
                        }}
                        onKeyPress={(e) => {
                            if (e.code === "Space" || e.code === "Enter") {
                                e.preventDefault();
                                setExpandSortDropDown(!expandSortDropDown);
                                const optionFocus = document.getElementById('selected-option');
                                optionFocus.focus();
                            }
                        }}
                    >
                        
                        <div className="basic-dropdown-wrapper" tabIndex="0">
                            <span id="selected-option" className="selected-option">
                                {props.toggleAscending ? props.fieldMap[props.field][props.ascending] : props.fieldMap[props.field]}
                            </span>
                            <span id="dropdown-arrow-icon" className="icon">
                                { !expandSortDropDown ? 
                                    <img src="/icons/chevron.svg" alt="chevron" /> 
                                : 
                                    <img src="/icons/chevron.svg" className="flip" alt="chevron" />
                                }
                            </span>
                        </div>
                        { expandSortDropDown &&
                            <div className="dropdown-selection" id="dropdown-options">
                                <div id="dropdown-options-inner-wrapper" className='dropdown-options-inner-wrapper'>
                                    {options}
                                </div>
                            </div>
                        }
                        </div>
                    </div>
                : 
                <div className="_dropdown-wrapper">
                    <span className="label">{`${props.label}:`}</span>
                    <div 
                        className={expandSortDropDown ? '_drop-down expanded' : '_drop-down collapsed'} 
                        id={`${props.id}-drop-down`}
                        data-type={props.basic ? 'basic' : ''}
                        onClick={() => {
                            setExpandSortDropDown(!expandSortDropDown);
                        }}
                        onKeyPress={(e) => {
                            if (e.code === "Space" || e.code === "Enter") {
                                e.preventDefault();
                                setExpandSortDropDown(!expandSortDropDown);
                                const optionFocus = document.getElementById('0-opt');
                                optionFocus.focus();
                            }
                        }}
                    >
                        <span className="expanded-left">
                            
                            <span id="selected-option" className="selected-option" tabIndex="0">
                                {props.toggleAscending ? props.fieldMap[props.field]?.[props.ascending] : props.fieldMap[props.field]}
                            </span>
                        </span>
                        <span id="dropdown-arrow-icon" className="icon" tabIndex="0">
                            {expandSortDropDown ? <img src="/icons/chevron-dark-blue-wide.svg" className="flip" alt="chevron" /> : <img src="/icons/chevron-dark-blue-wide.svg" alt="chevron" />}
                        </span>
                        { expandSortDropDown &&
                            <div className="dropdown-selection" id="dropdown-options">
                                {options}
                            </div>
                        }
                    </div>
                </div>
            } 
        </>
    );
}

export default DropDown;
