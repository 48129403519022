import './Footer.css';
import { USER_GUIDE_URL } from '../../constants';

const Footer = () => (
  <>
    <footer className="App-footer">
      <div className="App-inner-footer">
        <div className="summary">
          The Ohio Professional Learning Directory has been proudly curated by the Ohio ESC
          Association and supported by the Ohio Department of Education.
        </div>
        <div className="footer-content-wrapper">
          <div className="inner-footer-content-wrapper">
            <div className="footer-address">
              <div className="icon">
                <img src="/icons/location.svg" alt="location indicator" />
              </div>
              <div className="info">
                <span>Ohio ESC Association</span>
                <div className="mobile-inline">
                  <span>8050 North High St.,</span>
                  <span>Suite 150,</span>
                </div>
                <span>Columbus, OH 43235</span>
              </div>
            </div>
            <div className="footer-email">
              <div className="icon">
                <img src="/icons/mail.svg" alt="envelope" />
              </div>
              <div className="info">ADMINOPLD@OESCA.ORG</div>
            </div>
          </div>
          <div className="footer-links">
            <a
              className="footer-link"
              href={USER_GUIDE_URL}
              target="_blank"
              rel="noreferrer"
              onKeyPress={(e) => {
                if (e.code === 'Space') {
                  e.preventDefault();
                  window.open(USER_GUIDE_URL, '_blank');
                }
              }}>
              Download User Guide
            </a>
            <a className="footer-link" href="/Admin/Login">
              Admin Login
            </a>
          </div>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
