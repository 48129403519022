import './App.css';
import {
  Routes,
  Route,
  useNavigate,
  useLocation
} from "react-router-dom";
import Home from './Components/Home/Home';
import Results from './Components/Results/Results';
import Details from './Components/Details/Details';
import QueryBar from './Components/QueryBar/QueryBar';
import React, { useEffect, useState, useMemo } from 'react';
import QueryService from './Services/QueryService';
import NewContentService from './Services/NewContentService';
import { switchFilters, multiFilters, RESOURCE_FIELDS, ROLES, ENV_DOMAINS, ANALYTICS_TAG_IDS } from './constants';
import BrowseModal from './Components/Modals/BrowseModal/BrowseModal';
import FilterModal from './Components/Modals/FilterModal/FilterModal';
import OrganizationsService from './Services/organizationsService';
import ResourceCreate from './Components/ResourceCreate/ResourceCreate';
import ResourceReview from './Components/ResourceReview/ResourceReview';
import ResourceEndorse from './Components/ResourceEndorse/ResourceEndorse';
import AdminBar from './Components/AdminBar/AdminBar';
import ChangePassword from './Components/ChangePassword/ChangePassword';
import Login from './Components/Login/Login';
import utilityService from "./Services/utilityService";
import UserSurvey from './Components/UserSurvey/UserSurvey';
import Footer from './Components/Footer/Footer';
import ManageDashboard from './Components/ManageDashboard/ManageDashboard';
const App = () => {
  //Analytics function
  function gtag() { window.dataLayer?.push(arguments); }

  // State setters
  const [organizations, setOrganizations] = useState([]);
  const [orgLogo, setOrgLogo] = useState('');
  const [newContent, setNewContent] = useState(() => []);
  const [results, setResults] = useState(() => []);
  const [filters, setFilters] = useState(() => []);
  const [facetCounts, setFacetCounts] = useState(() => []);
  const [result, setResult] = useState({});
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [total, setTotal] = useState(1);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [previous, setPrevious] = useState(() => '/');
  const [filterChange, setFilterChange] = useState(0);
  const [sortField, setSortField] = useState(RESOURCE_FIELDS.SCORE);
  const [viewField, setViewField] = useState('');
  const [orgField, setOrgField] = useState('');
  const [adminSortField, setAdminSortField] = useState(RESOURCE_FIELDS.UPLOADED_DATE);
  const [sortAscending, setSortAscending] = useState(true);
  const [backDrop, setBackDrop] = useState(false);
  const [modalContentTitle, setModalContentTitle] = useState(() => '');
  const [showFilters, setShowFilters] = useState(() => false);
  const [isEndorsed, setIsEndorsed] = useState(false);
  const [user, setUser] = useState({});
  const [disableUnpublish, setDisableUnpublish] = useState(false);
  const [postResults, setPostResults] = useState(() => []);
  const [uploadResult, setUploadResult] = useState(() => []);
  const [detailsViewCount, setDetailsViewCount] = useState(0);
  const [logoLink, setLogoLink] = useState('');
  const [tileId, setTileId] = useState('');
  const [pageUpdate, setPageUpdate] = useState(false);
  const [leavingForm, setLeavingForm] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [dialogRedirect, setDialogRedirect] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadedFile, setUploadedFile] = useState('');
  const [replaceFile, setReplaceFile] = useState(false);
  const [listingsViewsCounts, setListingsViewsCounts] = useState([]);
  const [listingsPublishedCount, setListingsPublishedCount] = useState(0);
  const [resizeListener, setResizeListener] = useState(false);
  const [windowSize, setWindowSize] = useState(0);

  // content provider states
  const [selectedOrgType, setSelectedOrgType] = useState(null)
  const [dropdownOrgTypes, setDropdownOrgTypes] = useState(null)
  const [dropdownOrgNames, setDropdownOrgNames] = useState(null);
  const [organizationFacets, setOrganizationFacets] = useState(null)
  const [filtersToFetchOrganizations, setFiltersToFetchOrganizations] = useState(null)
  const [activeOrganizations, setActiveOrganizations] = useState(null)

  // Misc variables
  const referrer = document.referrer;
  const authCookie = utilityService.getCookie('auth');
  const navigate = useNavigate();
  const location = useLocation();
  const isSuperAdmin = useMemo(() => user?.roles?.includes(ROLES.SUPERADMIN), [user?.roles]);
  const isOrganizationAdmin = useMemo(() => user?.roles?.includes(ROLES.ORGANIZATION_ADMIN) && !user?.roles?.includes(ROLES.SUPERADMIN), [user?.roles]);

  const queryService = QueryService({
    setter: setResults,
    currentData: results,
    pageSetter: setPage,
    querySetter: setQuery,
    totalSetter: setTotal,
    resultsTotalSetter: setResultsTotal,
    sortField: sortField,
    sortAscending: sortAscending,
    filters: filters,
    facetCounts: setFacetCounts,
  });

  // Navigate away button functions

  const navigateAwayYes = () => {
    setLeavingForm(false);
    setFormChanged(false);
    navigate(dialogRedirect);
  }

  const navigateAwayNo = () => {
    setLeavingForm(false);
    setFormChanged(false);
  }

  const handleReplaceFile = () => {
    setReplaceFile(true);
    document.getElementById('upload-evaluation').click();
    setOpenDialog(false);
  }

  const handleCancelUpload = () => {
    setOpenDialog(false);
  }

  // Effects 
  useEffect(() => {
    const organizationsService = OrganizationsService({
      setter: setOrganizations,
      pageSetter: () => { },
      pageTotalSetter: () => { },
    });
    organizationsService.fetchOrganizations();
  }, [])

  useEffect(() => {
    setTimeout(() => {
      gtag("event", "page_view", { page_location: document.location.href, page_referrer: document.referrer, send_to: process.env.REACT_APP_APIG_ROOT_URL === ENV_DOMAINS.PROD ? ANALYTICS_TAG_IDS.PROD : ANALYTICS_TAG_IDS.STAGE });
      gtag('event', 'referred_from', { 'referrer': document.referrer });
    }, 100)
  }, []);

  useEffect(() => {
    if (organizations.length > 0) {
      const newContentService = NewContentService(setNewContent, setOrgLogo);
      newContentService.fetchNewContent(referrer, organizations);
    }
  }, [organizations.length]);

  useEffect(() => {
    if (authCookie) {
      const userData = utilityService.parseJwt(authCookie);
      setUser(userData);
    }
  }, [authCookie]);

  useEffect(() => {
    let anonymousUser = !authCookie;

    // Increase view count by one
    if (location.pathname.includes('/details')) {
      setDetailsViewCount(detailsViewCount + 1);
    }

    // If user has not completed survey AND details view count 
    // is divisible by 5 AND details view count does not equal 0 AND
    // user is anonymous, then navigate to survey
    if ((!utilityService.getCookie("completedSurvey")) && (detailsViewCount % 5 === 0 && detailsViewCount !== 0) && (anonymousUser)) {
      navigate("/UserSurvey");
    }

  }, [location.pathname]);

  useEffect(() => {
    setResizeListener(window.addEventListener("resize", (event) => {
      setWindowSize(window.innerWidth);
    }));
    setWindowSize(window.innerWidth);
  }, []);

  return (
    <div className="app">
      {leavingForm &&
        <div id="dialog-wrapper" className="dialog-wrapper">
          <dialog
            id="navigate-away-dialog"
            className="navigate-away-dialog">
            <div className="dialog-content">
              <p>You have unsaved changes that will be lost.</p>
              <p>Are you sure you want to leave?</p>
            </div>
            <div id="dialog-buttons" className="dialog-buttons">
              <button id="dialog-button-yes" className="dialog-button-yes" onClick={() => navigateAwayYes()}>Yes</button>
              <button id="dialog-button-no" className="dialog-button-no" onClick={() => navigateAwayNo()}>No</button>
            </div>
          </dialog>
        </div>
      }
      {openDialog &&
        <div id="dialog-wrapper-upload" className="dialog-wrapper-upload">
          <dialog
            id="navigate-away-dialog"
            className="navigate-away-dialog">
            <div className="back-from-replace-file-modal" onClick={() => handleCancelUpload()}>{"< Back"}</div>
            <div className="dialog-content-upload-modal">
              <p>This action will replace your current file attachment, </p>
              <p>are you sure you'd like to do make this change?</p>
            </div>
            <div id="dialog-buttons" className="dialog-buttons">
              <button id="dialog-button-upload-yes" className="dialog-button-upload-yes" onClick={() => handleReplaceFile()}>Yes</button>
              <button id="dialog-button-upload-no" className="dialog-button-upload-no" onClick={() => handleCancelUpload()}>No, Cancel</button>
            </div>
          </dialog>
        </div>}
      <div id="app-container" className={backDrop ? 'backdrop' : ''} onClick={() => { if (backDrop) { setBackDrop(!backDrop) } }}>
        <header className="App-header">
          {document.location.pathname.match(/Admin/) && authCookie ?
            <AdminBar
              user={user}
              setLeavingForm={setLeavingForm}
              formChanged={formChanged}
              setDialogRedirect={setDialogRedirect}
              setPostResults={setPostResults}
              setUploadedFile={setUploadedFile}
            /> :
            <QueryBar
              gtag={gtag}
              orgLogo={orgLogo}
              search={search}
              navHome={navHome}
              backDrop={backDrop}
              setBackDrop={setBackDrop}
              setModalContentTitle={setModalContentTitle}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              filters={filters}
              setFilters={setFilters}
              filterChange={filterChange}
              setFilterChange={setFilterChange}
              setQuery={setQuery}
              setPrevious={setPrevious}
              navigate={navigate}
            />
          }
        </header>
        <div className="content">
          <Routes>
            <Route
              path="/details/:docID"
              element={
                <Details
                  details={result}
                  setResult={setResult}
                  previous={previous}
                  setPrevious={setPrevious}
                  organizations={organizations}
                  setFilterChange={setFilterChange}
                  filterChange={filterChange}
                  setPageUpdate={setPageUpdate}
                  page={page}
                  setPage={setPage}
                  gtag={gtag}
                />
              }
            />
            <Route
              path="/results"
              element={
                <Results
                  gtag={gtag}
                  results={results}
                  search={search}
                  setResult={setResult}
                  setResults={setResults}
                  page={page}
                  setPage={setPage}
                  query={query}
                  querySetter={setQuery}
                  total={total}
                  totalSetter={setTotal}
                  resultsTotal={resultsTotal}
                  resultsTotalSetter={setResultsTotal}
                  setPrevious={setPrevious}
                  filters={filters}
                  setFilters={setFilters}
                  filterChange={filterChange}
                  setFilterChange={setFilterChange}
                  switchFilters={switchFilters}
                  multiFilters={multiFilters}
                  sortField={sortField}
                  sortAscending={sortAscending}
                  setSortField={setSortField}
                  setSortAscending={setSortAscending}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                  setModalContentTitle={setModalContentTitle}
                  backDrop={backDrop}
                  setIsEndorsed={setIsEndorsed}
                  isEndorsed={isEndorsed}
                  setTileId={setTileId}
                  tileId={tileId}
                  pageUpdate={pageUpdate}
                  setPageUpdate={setPageUpdate}
                  organizations={organizations}
                  organizationsWithListings={facetCounts}
                  setOrganizationsWithListings={setFacetCounts}
                  organizationFacets={organizationFacets}
                  setOrganizationFacets={setOrganizationFacets}
                  selectedOrgType={selectedOrgType}
                  setSelectedOrgType={setSelectedOrgType}
                  dropdownOrgTypes={dropdownOrgTypes}
                  setDropdownOrgTypes={setDropdownOrgTypes}
                  dropdownOrgNames={dropdownOrgNames}
                  setDropdownOrgNames={setDropdownOrgNames}
                  filtersToFetchOrganizations={filtersToFetchOrganizations}
                  setFiltersToFetchOrganizations={setFiltersToFetchOrganizations}
                  activeOrganizations={activeOrganizations}
                  setActiveOrganizations={setActiveOrganizations}
                />
              }
            />
            <Route
              path="/"
              element={
                <Home
                  referrer={referrer}
                  organizations={organizations}
                  newContent={newContent}
                  setResult={setResult}
                  search={search}
                  setPrevious={setPrevious}
                  sortField={sortField}
                  sortAscending={sortAscending}
                  setFilters={setFilters}
                  filterChange={filterChange}
                  setTileId={setTileId}
                  backDrop={backDrop}
                  setBackDrop={setBackDrop}
                  windowSize={windowSize}
                />
              }
            />
            <Route
              path="/Admin/Create"
              element={
                <ResourceCreate
                  user={user}
                  organizations={organizations}
                  authCookie={authCookie}
                  setResults={setResults}
                  setResult={setResult}
                  results={results}
                  result={result}
                  page={page}
                  setPage={setPage}
                  querySetter={setQuery}
                  totalSetter={setTotal}
                  sortField={adminSortField}
                  setSortField={setAdminSortField}
                  viewField={viewField}
                  setViewField={setViewField}
                  sortAscending={sortAscending}
                  setSortAscending={setSortAscending}
                  filters={filters}
                  setFacetCounts={setFacetCounts}
                  facetCounts={facetCounts}
                  total={total}
                  setDisableUnpublish={setDisableUnpublish}
                  disableUnpublish={disableUnpublish}
                  postResults={postResults}
                  setPostResults={setPostResults}
                  logoLink={logoLink}
                  setLogoLink={setLogoLink}
                  leavingForm={leavingForm}
                  setLeavingForm={setLeavingForm}
                  formChanged={formChanged}
                  setFormChanged={setFormChanged}
                  setDialogRedirect={setDialogRedirect}
                  setTileId={setTileId}
                  tileId={tileId}
                  isSuperAdmin={isSuperAdmin}
                  isOrganizationAdmin={isOrganizationAdmin}
                  listingsViewsCounts={listingsViewsCounts}
                  setListingsViewsCounts={setListingsViewsCounts}
                  listingsPublishedCount={listingsPublishedCount}
                  setListingsPublishedCount={setListingsPublishedCount}
                />
              }
            />
            <Route
              path="/Admin/Review"
              element={
                <ResourceReview
                  user={user}
                  organizations={organizations}
                  authCookie={authCookie}
                  setResults={setResults}
                  setResult={setResult}
                  results={results}
                  result={result}
                  page={page}
                  setPage={setPage}
                  querySetter={setQuery}
                  totalSetter={setTotal}
                  sortField={adminSortField}
                  setSortField={setAdminSortField}
                  viewField={orgField}
                  setViewField={setOrgField}
                  sortAscending={sortAscending}
                  setSortAscending={setSortAscending}
                  filters={filters}
                  setFacetCounts={setFacetCounts}
                  facetCounts={facetCounts}
                  total={total}
                  organizationNames={organizations?.map(org => org.org_name?.raw)}
                  postResults={postResults}
                  setPostResults={setPostResults}
                  logoLink={logoLink}
                  setLogoLink={setLogoLink}
                  setTileId={setTileId}
                  tileId={tileId}
                  isSuperAdmin={isSuperAdmin}
                  isOrganizationAdmin={isOrganizationAdmin}
                  listingsViewsCounts={listingsViewsCounts}
                  setListingsViewsCounts={setListingsViewsCounts}
                  listingsPublishedCount={listingsPublishedCount}
                  setListingsPublishedCount={setListingsPublishedCount}
                />
              }
            />
            <Route
              path="/Admin/Endorse"
              element={
                <ResourceEndorse
                  user={user}
                  organizations={organizations}
                  authCookie={authCookie}
                  setResults={setResults}
                  setResult={setResult}
                  results={results}
                  result={result}
                  page={page}
                  setPage={setPage}
                  querySetter={setQuery}
                  totalSetter={setTotal}
                  sortField={adminSortField}
                  setSortField={setAdminSortField}
                  viewField={orgField}
                  setViewField={setOrgField}
                  sortAscending={sortAscending}
                  setSortAscending={setSortAscending}
                  filters={filters}
                  setFacetCounts={setFacetCounts}
                  facetCounts={facetCounts}
                  total={total}
                  organizationNames={organizations?.map(org => org.org_name?.raw)}
                  postResults={postResults}
                  setPostResults={setPostResults}
                  uploadResult={uploadResult}
                  setUploadResult={setUploadResult}
                  logoLink={logoLink}
                  setLogoLink={setLogoLink}
                  setTileId={setTileId}
                  tileId={tileId}
                  isSuperAdmin={isSuperAdmin}
                  isOrganizationAdmin={isOrganizationAdmin}
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
                  setUploadedFile={setUploadedFile}
                  uploadedFile={uploadedFile}
                  replaceFile={replaceFile}
                  setReplaceFile={setReplaceFile}
                  listingsViewsCounts={listingsViewsCounts}
                  setListingsViewsCounts={setListingsViewsCounts}
                  listingsPublishedCount={listingsPublishedCount}
                  setListingsPublishedCount={setListingsPublishedCount}
                />
              }
            />
            <Route
              path="/Admin/Manage"
              element={
                <ManageDashboard
                  total={total}
                  user={user}
                  authCookie={authCookie}
                  logoLink={logoLink}
                  setLogoLink={setLogoLink}
                  organizations={organizations}
                  sortAscending={sortAscending}
                  setSortAscending={setSortAscending}
                  postResults={postResults}
                  setPostResults={setPostResults}
                  uploadResult={uploadResult}
                  setUploadResult={setUploadResult}
                  setResults={setResults}
                  listingsViewsCounts={listingsViewsCounts}
                  setListingsViewsCounts={setListingsViewsCounts}
                  listingsPublishedCount={listingsPublishedCount}
                  setListingsPublishedCount={setListingsPublishedCount}
                />
              }
            />
            <Route
              path="/Admin/Login"
              element={
                <Login user={user} setUser={setUser} authCookie={authCookie} />
              }
            />
            <Route
              path="/Admin/ChangePassword"
              element={
                <ChangePassword authCookie={authCookie} />
              }
            />
            <Route
              path="/UserSurvey"
              element={
                <UserSurvey previous={previous} setDetailsViewCount={setDetailsViewCount} />
              }
            />
          </Routes >
        </div >
        <Footer />
      </div>
      {backDrop &&
        <div className="modal">
          {modalContentTitle === 'Browse' && <BrowseModal gtag={gtag} mobile={true} backDrop={backDrop} setBackDrop={setBackDrop} setFilters={setFilters} search={search} filterChange={filterChange} setFilterChange={setFilterChange} setQuery={setQuery} />}
          {modalContentTitle === 'Filter' && <FilterModal gtag={gtag} backDrop={backDrop} setBackDrop={setBackDrop} filters={filters} setFilters={setFilters} search={search} filterChange={filterChange} setFilterChange={setFilterChange} multiFilters={multiFilters} switchFilters={switchFilters} isEndorsed={isEndorsed} setIsEndorsed={setIsEndorsed} />}
        </div>
      }
      {leavingForm &&
        <div className='modal-backdrop'></div>
      }
      {openDialog &&
        <div className='modal-backdrop'></div>
      }
    </div>
  );

  function search(query) {
    if (window.location.pathname.match('results')) {
      queryService.performBasicSearch(query);
    }
    if (!window.location.pathname.match('results')) {
      setQuery(query);
    }
    setPrevious(document.location.pathname);
    gtag('event', 'search_query', { 'query': query.toLowerCase() })
    navigate('/results');
  }

  function navHome() {
    setPrevious(document.location.pathname);
    setFilterChange(0);
    navigate('/');
  }

}

export default App;
