import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LISTING_CREATED, RESOURCE_FIELDS, RESOURCE_STATES, ROLES } from "../../constants";
import QueryService from "../../Services/QueryService";
import utilityService from "../../Services/utilityService";
import DropDown from "../common/DropDown";
import Tile from "../Tile/Tile";
import UtilityBar from "../UtilityBar/UtilityBar";
import "./ResourceCreate.css";
import CreateResourceForm from './CreateResourceForm/CreateResourceForm'
import ResourceConfirmationView from "../ResourceConfirmationView/ResourceConfirmationView";
import DropdownField from "../common/DropdownField";
import {
    RESOURCES, AUDIENCE_OPTIONS,
    TOPIC_OPTIONS,
    GRADE_SPAN_OPTIONS,
    CREDIT_TYPE_OPTIONS,
    OH_TEACHING_STANDARD_OPTIONS,
    RESOURCE_STATE,
    UNPUBLISH_RESOURCE_STATES,
    MODIFY_RESOURCE_STATES
} from '../../constants'

const initialFormData = {
    cost: '',
    cost_display: '',
    title: "",
    shortDescription: "",
    longDescription: "",
    listingURL: "",
    publishDate: '',
    unpublishDate: '',
    learningObjectives: "",
    searchTags: "",
    requestEndorsement: false,
    listingReviewerInstructions: "",
    contentProvider: null,
    mediaType: null,
    topic: [],
    audience: [],
    ohTeachingStandards: [],
    gradeSpan: [],
    creditType: [],
    contactOrCreditHours: '',
}

function ResourceCreate(props) {
    const navigate = useNavigate();
    const [sortChange, setSortChange] = useState(0);
    const [createState, setCreateState] = useState(RESOURCES.MAIN);
    const [viewChange, setViewChange] = useState(0);
    const [viewField, setViewField] = useState('');
    const [allResourcesCount, setAllResourcesCount] = useState(0);
    const [requestEndorsement, setRequestEndorsement] = React.useState(false);
    const [formErrorMessage, setFormErrorMessage] = React.useState('');
    const [isEditingDisabled, setIsEditingDisabled] = React.useState(false);
    const [resourceState, setResourceState] = React.useState(RESOURCE_STATE.VIEW);
    const [postResultsMsg, setPostResultsMsg] = React.useState('');
    const [uploadMessage, setUploadMessage] = React.useState('');
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [isSubmittedForReview, setIsSubmittedForReview] = useState(false);
    const [formData, setFormData] = useState({
        cost: '',
        cost_display: '',
        title: "",
        shortDescription: "",
        longDescription: "",
        listingURL: "",
        publishDate: "",
        unpublishDate: "",
        learningObjectives: "",
        searchTags: "",
        requestEndorsement: false,
        listingReviewerInstructions: "",
        contentProvider: null,
        mediaType: null,
        topic: null,
        audience: null,
        ohTeachingStandards: null,
        gradeSpan: null,
        creditType: null,
        contactOrCreditHours: '',
    });
    const [viewOptionsWithCountList, setViewOptionsWithCountList] = useState([
        { label: 'All', value: '' },
        { label: 'Published (0)', value: 'published' },
        { label: 'Endorsed (0)', value: 'endorsed' },
        { label: 'For Review (0)', value: 'submitted_review' },
        { label: 'For Endorsement (0)', value: 'submitted_endorsement' },
        { label: 'Rejected for Publication (0)', value: 'rejected_review' },
        { label: 'Rejected for Endorsement (0)', value: 'rejected_endorsement' },
        { label: 'Unpublished (0)', value: 'unpublished' }
    ]);

    const queryService = QueryService({
        setter: props.setResults,
        currentData: props.results,
        pageSetter: props.setPage,
        querySetter: props.querySetter,
        totalSetter: props.totalSetter,
        sortField: props.sortField,
        viewField: viewField,
        sortAscending: props.sortAscending,
        filters: props.filters,
        setPostResults: props.setPostResults,
        postResults: props.postResults,
        include_org: !props.isSuperAdmin && props.user.org_id,
        facetCounts: props.setFacetCounts
    });

    const sortFieldMap = {
        'Newest': RESOURCE_FIELDS.UPLOADED_DATE,
        'Oldest': RESOURCE_FIELDS.UPLOADED_DATE,
        [RESOURCE_FIELDS.UPLOADED_DATE]: { true: 'Oldest', false: 'Newest' },
        'Listing Title (A-Z)': RESOURCE_FIELDS.TITLE,
        'Listing Title (Z-A)': RESOURCE_FIELDS.TITLE,
        [RESOURCE_FIELDS.TITLE]: { true: 'Listing Title (A-Z)', false: 'Listing Title (Z-A)' },
    };

    const viewFieldMap = {
        'All': '',
        '': 'All',
        'Published': RESOURCE_STATES.PUBLISHED,
        [RESOURCE_STATES.PUBLISHED]: 'Published',
        'For Review': RESOURCE_STATES.REVIEW,
        [RESOURCE_STATES.REVIEW]: 'For Review',
        'For Endorsement': RESOURCE_STATES.ENDORSE,
        [RESOURCE_STATES.ENDORSE]: 'For Endorsement',
        'Rejected for Publication': RESOURCE_STATES.REJECTED_REVIEW,
        [RESOURCE_STATES.REJECTED_REVIEW]: 'Rejected for Publication',
        'Rejected for Endorsement': RESOURCE_STATES.REJECTED_ENDORSEMENT,
        [RESOURCE_STATES.REJECTED_ENDORSEMENT]: 'Rejected for Endorsement',
        'Unpublished': RESOURCE_STATES.UNPUBLISHED,
        [RESOURCE_STATES.UNPUBLISHED]: 'Unpublished',
        'Endorsed': RESOURCE_STATES.ENDORSED,
        [RESOURCE_STATES.ENDORSED]: 'Endorsed',
    };

    const sortOptions = ['Newest', 'Oldest', 'Listing Title (A-Z)', 'Listing Title (Z-A)'];

    if (props.isSuperAdmin || props.isOrganizationAdmin) {
        sortFieldMap['Organizations'] = RESOURCE_FIELDS.ORGANIZATION_NAME;
        sortFieldMap[[RESOURCE_FIELDS.ORGANIZATION_NAME]] = { true: 'Organizations' };
        sortOptions.push('Organizations')
    }

    useEffect(() => {
        const viewOptions = props.facetCounts && props.facetCounts[0]?.map((facet, index) => ({ label: `${viewFieldMap[facet.value]} (${facet.count})`, value: facet.value }));
        if (viewOptions) {
            setViewOptionsWithCountList(viewOptionsWithCountList.map(option => viewOptions.find(viewOption => viewOption.value === option.value) || option));
        }
    }, [props.facetCounts]);

    useEffect(() => {
        if (!props.authCookie) {
            navigate('/');
        }
    }, [props.authCookie, navigate]);

    useEffect(() => {
        if (props.user.roles && !props.user.roles.includes(ROLES.CREATOR)) {
            navigate('/');
        }
    }, [props.user]);

    useEffect(() => {
        if (props.user.roles && sortChange === 1) {
            queryService.performAdminSearch('', RESOURCE_FIELDS.RESOURCE_STATE);
        }
        else if (props.user.roles && sortChange > 1) {
            queryService.performAdminSearch('');
        }
    }, [sortChange]);

    useEffect(() => {
        if (props.user.roles && viewChange > 0) {
            queryService.performAdminSearch('');
        }
    }, [viewField]);

    useEffect(() => {
        if (props?.postResults?.id) {
            setPostResultsMsg('Listing successfully unpublished.');
        } else {
            setPostResultsMsg(props?.postResults?.errors?.join(','));
        }
    }, [props.postResults]);

    useEffect(() => {
        if (uploadMessage) {
            setTimeout(() => setUploadMessage(''), 3000);
        }
    }, [uploadMessage])

    useEffect(() => {
        utilityService.focusNextCardOnLoadMore(props);
    }, [props.results.length]);

    useEffect(() => {
        validateForm();
    }, [props.formChanged]);

    function fetchMoreResults() {
        queryService.fetchNextPage('', props.page, props.results);
    }

    //TODO: update the target location below to point to proper path when available.
    function renderResult(result, groupAmt) {
        return new Array(groupAmt).fill(0, 0, groupAmt).map((n, i) => {
            return (result.length > i ? <Tile key={i} index={i} tile={result[i]} search={props.search} setResult={props.setResult} setPrevious={props.setPrevious} targetLocation="/Admin/" setCreateState={setCreateState} resourceTab={RESOURCES.CREATE_RESOURCE} populateFields={populateFields} setIsEditingDisabled={setIsEditingDisabled} setTileId={props.setTileId} tileId={props.tileId} /> : <div key={i} className="_results _tile void"></div>);
        })
    }

    const getResults = (results) => {
        let cnt = 0;
        if (!results?.length) {
            return (
                <div className="no-results">
                    No results found.
                </div>
            )
        }
        results.forEach((result) => {
            result.index = cnt++;
        });
        const groupAmt = 3;
        const groupedResults = utilityService.groupResults(results, groupAmt);
        const renderedResults = groupedResults.map((result, index) => {
            return (
                <div className="tile-row" key={index} index={index}>
                    {renderResult(result, groupAmt)}
                </div>
            )
        });
        return (
            <div>
                {renderedResults}
            </div>
        )
    }

    const getUpdatedFormData = () => {
        const orgName = formData?.contentProvider?.value;
        const orgId = props.organizations?.find(organization => { return organization.org_name?.raw === orgName })?.id?.raw;
        const reviewer_comments = props?.result?.reviewer_comments?.raw;
        const endorser_comments = props?.result?.endorser_comments?.raw;
        const requestBody = {
            title: encodeURIComponent(formData?.title),
            short_description: encodeURIComponent(formData?.shortDescription),
            long_description: encodeURIComponent(formData?.longDescription),
            resource_url: formData?.listingURL,
            org_id: orgId,
            org_name: orgName,
            media_type: formData?.mediaType?.value,
            topic: getMultiSelectValues(formData?.topic),
            audience: getMultiSelectValues(formData?.audience),
            grade_span: getMultiSelectValues(formData?.gradeSpan),
            cost: (formData?.cost === '') ? 0 : parseInt(formData?.cost),
            cost_display: formData?.cost,
            oh_teaching_standards: getMultiSelectValues(formData?.ohTeachingStandards),
            learning_objectives: encodeURIComponent(formData?.learningObjectives),
            endorsement_requested: formData?.requestEndorsement,
            uploaded_date: new Date().toISOString(),
            uploaded_by: props?.user?.name,
            uploaded_by_id: props?.user?.id,
            completion_credit: getMultiSelectValues(formData?.creditType),
            starting_date: formData?.publishDate === '' ? null : new Date(formData?.publishDate).toISOString(),
            expiration_date: formData?.unpublishDate === '' ? null : new Date(formData?.unpublishDate).toISOString(),
            search_tags: formData?.searchTags,
            creater_comments: formData?.listingReviewerInstructions,
            resource_state: "submitted_review",
            credit_hours: formData?.contactOrCreditHours === '' ? 0 : formData?.contactOrCreditHours,
            reviewer_comments: reviewer_comments,
            endorser_comments: endorser_comments,
            lock_status: "false",
            lock_by: ""
        }
        return requestBody;
    }

    const getAnalyticsEventPayload = (reqBody) => {
        const analyticsEvent = {
            "org_id": reqBody?.org_id,
            "org_name": reqBody?.org_name,
            "org_type": props?.organizations?.filter((org) => org.id?.raw === reqBody?.org_id)[0]?.org_type?.raw,
            "listing_id": reqBody?.uploaded_by_id,
            "listing_name": reqBody?.title,
            "listing_mediatype": reqBody?.media_type,
            "listing_event_type": LISTING_CREATED,
            "event_date": new Date().toISOString(),
            "event_user": reqBody?.uploaded_by
        };

        return analyticsEvent;
    }

    const handleCreateResourceSubmit = () => {
        let reqBody = {};

        if (resourceState === "modify") {
            var requestBody = getUpdatedFormData();
            requestBody.id = props.result?.id?.raw;
            reqBody = requestBody;
        } else {
            reqBody = getUpdatedFormData();
        }
        queryService.performPostDocuments([reqBody]);

        setIsSubmittedForReview(true)
        setFormErrorMessage('');
        setTimeout(() => {
            setResourceState(RESOURCE_STATE.VIEW);
            setCreateState(RESOURCES.SUBMIT_RESOURCE_CONFIRMED);
            utilityService.triggerAnalyticsEvent(getAnalyticsEventPayload(reqBody))
            props.setFormChanged(false);
            window.scrollTo(0, 0);
        }, 2300);
    }

    const getMultiSelectValues = (mutliSelectValues) => {
        let values = [];
        mutliSelectValues?.forEach((valObj) => {
            values.push(valObj?.value)
        })
        return values;
    }

    const handleSelectDropdowns = (event, name) => {
        setFormData({
            ...formData,
            [name]: event
        });
        setFormErrorMessage('');
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value.replace(/[^a-zA-Z0-9\[\]\!\@\#\$\%\^\&\*\(\)\-\_\,\.\{\}\[\]\+\=\;\:\"\'\~\`\<\>\?\/\s]/gi, '')
        });
        setFormErrorMessage('');
    }

    const handleTextAreaChange = (e) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value.replace(/[^a-zA-Z0-9\[\]\!\@\#\$\%\^\&\*\(\)\-\_\,\.\{\}\[\]\+\=\;\:\"\'\~\`\<\>\?\/\s]/gi, '')
        });
        setFormErrorMessage('');
    }

    const handleResourceURLChange = (e) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value
        });
        setFormErrorMessage('');
    }

    const handleSearchTagsChange = (e) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value.replace(/[^a-zA-Z0-9\[\]\!\@\#\$\%\^\&\*\(\)\-\_\,\.\{\}\[\]\+\=\;\:\"\'\~\`\<\>\s]/gi, '')
        });
        setFormErrorMessage('');
    }

    const handleDateChange = (e, label) => {
        setFormData({
            ...formData,
            [label]: e
        });
        setFormErrorMessage('');
    }

    const handleRequestEndorsement = (e) => {
        setRequestEndorsement(!requestEndorsement);
        setFormData({
            ...formData,
            [e.target.name]: !requestEndorsement
        })
        setFormErrorMessage('');
    }

    const validateForm = () => {
        const errorList = [];
        const requiredFields = [
            { value: formData.title, label: "Title" },
            { value: formData.shortDescription, label: "Short Description" },
            { value: formData.longDescription, label: "Long Description" },
            { value: formData.listingURL, label: "Listing URL" },
            { value: formData.mediaType, label: "Media Type" },
            { value: formData.topic, label: "Topic" },
            { value: formData.audience, label: "Audience" },
            { value: formData.gradeSpan, label: "Grade Span" },
        ]

        if (formData?.mediaType?.value.includes('Event')) {
            requiredFields.push(
                { value: formData.publishDate, label: "Start Date" },
                { value: formData.unpublishDate, label: "Unpublish Date" }
            )
        }

        requiredFields.forEach((ele) => {
            if (!ele?.value || ele.value === '' || ele?.value?.length < 1) {
                errorList.push(ele?.label)
            }
        })

        if (errorList.length) {
            const msg = errorList.length > 1 ? 'are required fields.' : 'is a required field.';
            return { isError: true, errorMessage: `"${errorList.join(', ')}" ${msg} Please add and try again.` }
        } else {
            return { isError: false, errorMessage: null }
        }
    }

    const handleOnSave = () => {
        const formValidation = validateForm();
        if (formValidation.isError) {
            setFormErrorMessage(formValidation?.errorMessage);
            setSubmitAttempted(true);
        } else {
            setFormErrorMessage('');
            setCreateState(RESOURCES.SUBMIT_RESOURCE)
            setSubmitAttempted(false);
            window.scrollTo(0, 0)
        }
    }

    const getFileName = (url) => {
        if (url) {
            const pathname = new URL(url).pathname;
            const index = pathname.lastIndexOf('/');
            const fileName = pathname.substring(index + 1);
            const formattedFileName = fileName.replaceAll("%20", " ");
            return formattedFileName
        }
    }


    const populateFields = (result) => {
        const updateFormData = {
            cost: result?.cost?.raw,
            title: decodeIfNecessary(constructInputValues(result?.title?.raw)),
            shortDescription: decodeIfNecessary(constructInputValues(result?.short_description?.raw)),
            longDescription: decodeIfNecessary(constructInputValues(result?.long_description?.raw)),
            listingURL: constructInputValues(result?.resource_url?.raw),
            publishDate: getDateValues(result?.starting_date?.raw),
            unpublishDate: getDateValues(result?.expiration_date?.raw),
            learningObjectives: decodeIfNecessary(constructInputValues(result?.learning_objectives?.raw)),
            searchTags: constructInputValues(result?.search_tags?.raw),
            listingReviewerInstructions: constructInputValues(result?.creater_comments?.raw),
            contentProvider: { value: result?.org_name?.raw, label: result?.org_name?.raw },
            mediaType: { value: result?.media_type?.raw, label: result?.media_type?.raw },
            topic: constructDropdownValues(result?.topic?.raw, TOPIC_OPTIONS),
            audience: constructDropdownValues(result?.audience?.raw, AUDIENCE_OPTIONS),
            ohTeachingStandards: constructDropdownValues(result?.oh_teaching_standards?.raw, OH_TEACHING_STANDARD_OPTIONS),
            gradeSpan: constructDropdownValues(result?.grade_span?.raw, GRADE_SPAN_OPTIONS),
            creditType: constructDropdownValues(result?.completion_credit?.raw, CREDIT_TYPE_OPTIONS),
            contactOrCreditHours: constructInputValues(result?.credit_hours?.raw),
        }
        setRequestEndorsement(result?.endorsement_requested?.raw === "true");
        setFormData(updateFormData);
        setFormErrorMessage('');
        if (UNPUBLISH_RESOURCE_STATES.includes(result?.resource_state?.raw)) {
            props.setDisableUnpublish(false);
        } else {
            props.setDisableUnpublish(true);
        }
    }

    const getDateValues = (value) => {
        let date = new Date(value);
        return value ? date : '';
    }

    const constructInputValues = (value) => {
        return value ? value : '';
    }

    const decodeIfNecessary = (content) => {
        return content ? content.includes(' ') ? content : decodeURIComponent(content) : '';
    }

    const constructDropdownValues = (values, options) => {
        let val = [];
        let optionsKeys = [];
        Object.keys(options).forEach((element) => optionsKeys.push(options[element]?.label))

        if (typeof values === "object") {
            values?.forEach((ele) => {
                if (optionsKeys.includes(ele)) {
                    val.push({ value: ele, label: ele })
                }
            })
        } else {
            if (optionsKeys.includes(values)) {
                val.push({ value: values, label: values })
            }
        }
        return val;
    }

    const handleCreateListing = () => {
        const orgName = props?.organizations?.filter(org => org?.id?.raw === props?.user.org_id)[0];
        setFormData({
            ...initialFormData,
            contentProvider: { value: orgName?.org_name?.raw, label: orgName?.org_name?.raw },
        })
        setCreateState(RESOURCES.CREATE_RESOURCE);
        setIsSubmittedForReview(false);
    }

    const handleBackToListings = () => {
        setIsEditingDisabled(false);
        setFormData(initialFormData);
        setCreateState(RESOURCES.MAIN)
        setRequestEndorsement(false);
        setFormErrorMessage('');
        props.setPostResults([]);
        props.setResult([]);
    }

    const handleBackFromCreate = () => {
        if (props.formChanged) {
            props.setLeavingForm(true);
            props.setDialogRedirect(0);
            setTimeout(() => {
                document.getElementById('dialog-button-yes').focus();
            }, 0);
        } else {
            setSubmitAttempted(false);
            setFormData(initialFormData);
            setCreateState(RESOURCES.MAIN)
            setResourceState(RESOURCE_STATE.VIEW);
            setFormErrorMessage('');
        }
    }

    const handleModifyResource = () => {
        setIsEditingDisabled(false);
        setResourceState(RESOURCE_STATE.MODIFY);
        setIsSubmittedForReview(false);
    }

    const handleUnpublishListing = () => {
        var requestBody = {};
        props.setDisableUnpublish(true);
        setResourceState(RESOURCE_STATE.VIEW);
        requestBody.resourceId = props.result?.id?.raw;
        requestBody.user = 'user' // TODO: update user from token 
        queryService.performUnpublishResource(requestBody);
    }

    const handleBackFromModify = () => {
        setIsEditingDisabled(true);
        setFormErrorMessage('');
        setResourceState(RESOURCE_STATE.VIEW);
    }

    const uploadFile = (e) => {
        queryService.performPostResourceUpload(e.target.files[0], props.user)
            .then((res) => {
                if (res.status === 200) {
                    setUploadMessage('File Successfully uploaded.');
                } else if (res.status === 400) {
                    setUploadMessage('File upload failed on validation.')
                } else {
                    setUploadMessage('File failed to upload.')
                }
            })
            .catch(() => {
                setUploadMessage('File failed to upload.')
            })
    }

    const handleSelectDropdown = (event) => {
        setViewField(event.value);
        setViewChange(viewChange + 1);
    }

    return (
        <div className="_create">
            {props.authCookie &&
                <>
                    {createState !== RESOURCES.SUBMIT_RESOURCE_CONFIRMED && (
                        <div className="main">
                            <>
                                <div className="utility">
                                    {createState !== RESOURCES.SUBMIT_RESOURCE && (
                                        <UtilityBar logoLink={props.logoLink} setLogoLink={props.setLogoLink} user={props.user} organizations={props.organizations} setListingsViewsCounts={props.setListingsViewsCounts} listingsViewsCounts={props.listingsViewsCounts} listingsPublishedCount={props.listingsPublishedCount} setListingsPublishedCount={props.setListingsPublishedCount} />
                                    )}
                                </div>
                                {createState === RESOURCES.MAIN && (
                                    <div className="content">
                                        {props.user?.org_id &&
                                            <div className="actions">
                                                <button className="btn-secondary primary" type="button" onClick={() => handleCreateListing()}><img src="/icons/cross.svg" alt="cross" /><span>Create New Listing</span></button>
                                                <form hidden encType="multipart/form-data" method="POST" action="/fileParserJson">
                                                    <label htmlFor="resource-upload" />
                                                    <input hidden type="file" id="resource-upload" onChange={(e) => uploadFile(e)} />
                                                </form>
                                                <button className="btn-secondary alternate" type="button"><label htmlFor="resource-upload"><img src="/icons/upload.svg" alt="upload symbol" /><span>Upload one or more listings as CSV</span></label></button>
                                                <div className={uploadMessage.match('Success') ? 'success spacer' : 'failure spacer'}>{uploadMessage}</div>
                                            </div>
                                        }
                                        <div className="results-control">
                                            <div className="_dropdown-wrapper">
                                                <span className="label">{`View:`}</span>
                                                <DropdownField
                                                    id={"create-view"}
                                                    options={viewOptionsWithCountList}
                                                    onChange={handleSelectDropdown}
                                                    placeholder="All"
                                                    isRequired={false}
                                                />
                                            </div>
                                            <div className="sort">
                                                <DropDown label="Sort by" options={sortOptions} toggleAscending={true} ascending={props.sortAscending} setAscending={props.setSortAscending} field={props.sortField} setField={props.setSortField} fieldMap={sortFieldMap} change={sortChange} setChange={(val) => setSortChange(val)} id="create" />
                                            </div>
                                        </div>
                                        <div className="results">
                                            {getResults(props.results)}
                                        </div>
                                        {
                                            props.page < props.total &&
                                            (
                                                <div className="load-more">
                                                    <div className="void"></div>
                                                    <button className="btn-secondary" onClick={() => fetchMoreResults()}>Load More</button>
                                                    <div className="void"></div>
                                                </div>
                                            )
                                        }
                                    </div >
                                )
                                }

                                {createState === RESOURCES.CREATE_RESOURCE && (
                                    <div className="content">
                                        {resourceState === RESOURCE_STATE.VIEW && isEditingDisabled ? (
                                            <>
                                                <div className="back" tabIndex="0"
                                                    onClick={() => handleBackToListings()}
                                                    onKeyPress={(e) => {
                                                        if (e.code === "Space" || e.code === "Enter") {
                                                            e.preventDefault();
                                                            handleBackToListings();
                                                        }
                                                    }}
                                                >
                                                    {"< Back to Listings"}
                                                </div>
                                                <div className="modify-resource">
                                                    <button className="btn-secondary" id="modify"
                                                        onClick={() => handleModifyResource()}
                                                        onKeyPress={(e) => {
                                                            if (e.code === "Space" || e.code === "Enter") {
                                                                e.preventDefault();
                                                                handleModifyResource();
                                                            }
                                                        }}
                                                        disabled={!MODIFY_RESOURCE_STATES.includes(props?.result?.resource_state?.raw)}
                                                    >
                                                        Modify Listing
                                                    </button>
                                                    <button className="btn-secondary alternate" id="unpublish"
                                                        onClick={() => handleUnpublishListing()}
                                                        disabled={props.disableUnpublish}
                                                        onKeyPress={(e) => {
                                                            if (e.code === "Space" || e.code === "Enter") {
                                                                e.preventDefault();
                                                                handleUnpublishListing();
                                                            }
                                                        }}
                                                    >
                                                        Unpublish Listing
                                                    </button>
                                                    {props.disableUnpublish && (<div className="successMessage">{postResultsMsg}</div>)}
                                                </div>
                                            </>
                                        ) : (
                                            resourceState === RESOURCE_STATE.MODIFY ? (
                                                <>
                                                    <div className="back" tabIndex="0"
                                                        onClick={() => handleBackFromModify()}
                                                        onKeyPress={(e) => {
                                                            if (e.code === "Space" || e.code === "Enter") {
                                                                e.preventDefault();
                                                                handleBackFromModify();
                                                            }
                                                        }}
                                                    >
                                                        {"< Back"}
                                                    </div>
                                                    <div className="title">Modify Listing</div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="back" tabIndex="0"
                                                        onClick={() => { handleBackFromCreate() }}
                                                        onKeyPress={(e) => {
                                                            if (e.code === "Space" || e.code === "Enter") {
                                                                e.preventDefault();
                                                                handleBackFromCreate();
                                                            }
                                                        }}
                                                    >
                                                        {"< Back"}
                                                    </div>
                                                    <div className="title">Create a New Listing</div>
                                                    <hr className="divider" />
                                                </>
                                            )

                                        )}
                                        <CreateResourceForm
                                            result={props.result}
                                            setFormChanged={props.setFormChanged}
                                            formChanged={props.formChanged}
                                            setLeavingForm={props.setLeavingForm}
                                            leavingForm={props.leavingForm}
                                            formData={formData}
                                            setFormData={setFormData}
                                            formErrorMessage={formErrorMessage}
                                            setFormErrorMessage={setFormErrorMessage}
                                            handleOnSave={handleOnSave}
                                            handleSelectDropdowns={handleSelectDropdowns}
                                            handleInputChange={handleInputChange}
                                            handleDateChange={handleDateChange}
                                            handleResourceURLChange={handleResourceURLChange}
                                            handleTextAreaChange={handleTextAreaChange}
                                            handleSearchTagsChange={handleSearchTagsChange}
                                            handleRequestEndorsement={handleRequestEndorsement}
                                            isEditingDisabled={isEditingDisabled}
                                            requestEndorsement={requestEndorsement}
                                            user={props.user}
                                            organizations={props.organizations}
                                            getFileName={getFileName(props.result?.endorsement_evaluation_link?.raw)}
                                            submitAttempted={submitAttempted} />
                                    </div>
                                )
                                }

                                {createState === RESOURCES.SUBMIT_RESOURCE && (
                                    <div className="submit-resource">
                                        <div className="content">
                                            <div className="back-to-listing" tabIndex="0"
                                                onClick={() => setCreateState(RESOURCES.CREATE_RESOURCE)}
                                                onKeyPress={(e) => {
                                                    if (e.code === "Space" || e.code === "Enter") {
                                                        e.preventDefault();
                                                        setCreateState(RESOURCES.CREATE_RESOURCE);
                                                    }
                                                }}
                                            >
                                                {"< Back to Listing Form"}
                                            </div>
                                            <div className="submit-title">
                                                By clicking ‘submit’ below,  the content provider agrees that this resource meets the following criteria:
                                            </div>
                                            <ul className="submit-content">
                                                <li className="submit-list-item">The content provider has full rights to publish the resource content and fees(if applicable) are clearly indicated.The content provider has full rights to publish the resource content and fees(if applicable) are clearly indicated.</li>
                                                <li className="submit-list-item">The resource is aligned to appropriate Ohio educator standards, needs of Ohio educators, and if appropriate, student learning standards. The resource is aligned to Ohio Standards for Professional Development.</li>
                                                <li className="submit-list-item">Objectives are clearly stated and appropriate for the intended audience. The resource supports objectives and (where appropriate) objectves are assessed.</li>
                                                <li className="submit-list-item">The resource is free of sales or marketing messages or discloses such in advance of registration. The resource does not collect or retain any personal information from participants except as disclosed and as required to deliver the service.</li>
                                                <li className="submit-list-item">Provider has made appropriate efforts to support assistive technologies and align to accessibility standards or educational resources. Images, video and audio content have alternative text such as captioning, transcripts, or descriptive text. Visual design is consistent, not reliant on color as a navigation guide, and does not contain any flashing visual elements.</li>
                                                <li className="submit-list-item">The resource content and visual design is organized, coherent, and free of spelling errors and jargon.</li>
                                                <li className="submit-list-item">The resource is relevant to Ohio educators and is free of violent or adult content, inappropriate language, or biased content.</li>
                                            </ul>
                                            <button className="btn-secondary" id="submit" onClick={() => handleCreateResourceSubmit()} disabled={isSubmittedForReview}>Submit for Review</button>
                                        </div>
                                    </div>
                                )
                                }
                            </>
                        </div>
                    )}

                    {createState === RESOURCES.SUBMIT_RESOURCE_CONFIRMED && (
                        <ResourceConfirmationView handleBackToListings={handleBackToListings}
                            heading={"Your listing has been submitted for review."}
                            subTextOne={"You will receive an email update when an action has been taken by Reviewer / Endorser."}
                            subTextTwo={'Click on the button below to go back to dashboard.'}
                            postResults={props?.postResults}
                        />
                    )
                    }
                </>

            }
        </div >
    );
}

export default ResourceCreate;
