import React from "react";
import './ViewUser.css';
import DropdownField from '../../common/DropdownField';
import InputComponent from '../../common/InputComponent'

const showDeleteUser = false;

function ViewUser(props) {
    const toggleButtonName = props?.formData?.is_active && JSON.parse(props?.formData?.is_active) ? "Deactivate" : "Activate";
    return (
        <div className="_view-user" id="view-user-form">
            <button className="btn-secondary" id="modifyUser" onClick={() => props.handleOnClickModifyUser()}
                onKeyPress={(e) => {
                    if (e.code === "Space" || e.code === "Enter") {
                        e.preventDefault();
                        props.handleOnClickModifyUser();
                    }
                }}
                disabled={!props.isFormDisabled}
            >Modify User
            </button>

            <button className="btn-primary deactivate-user" id="deactivateUser" onClick={() => props.deactivatUser(toggleButtonName)}
                onKeyPress={(e) => {
                    if (e.code === "Space" || e.code === "Enter") {
                        e.preventDefault();
                        props.deactivatUser(toggleButtonName);
                    }
                }}>{toggleButtonName} User</button>
            {showDeleteUser &&
                <>
                    <button className="btn-primary" id="deleteUser" onClick={() => { }}
                        onKeyPress={(e) => {
                            if (e.code === "Space" || e.code === "Enter") {
                                e.preventDefault();
                                // TODO
                            }
                        }}>Delete User</button>
                </>
            }
            <div className="form-data">
                {props.formErrorMsg && <span className="error">{props.formErrorMsg}</span>}
                <div className="section-row">
                    <div className="first-name-input">
                        <InputComponent
                            id={"firstName"}
                            label="First Name"
                            name="firstName"
                            type={"text"}
                            placeholder={props?.formData?.firstName}
                            value={props?.formData?.firstName}
                            onChange={(e) => props?.handleInputChange(e)}
                            className="md-input-field"
                            disabled={props.isFormDisabled}
                            isRequired
                        />
                    </div>
                    <div className="last-name-input">
                        <InputComponent
                            id={"lastName"}
                            label="Last Name"
                            name="lastName"
                            type={"text"}
                            placeholder={props?.formData?.lastName}
                            value={props?.formData?.lastName}
                            onChange={(e) => props?.handleInputChange(e)}
                            className="md-input-field"
                            disabled={props.isFormDisabled}
                            isRequired
                        />
                    </div>
                </div>
                <div className="section-row">
                    <div>
                        <div className="title">Work Email Address</div>
                        <div className="field-value">{props?.formData?.emailAddress}</div>
                    </div>
                </div>
                <div className="section-row">
                    <DropdownField
                        title={"Content Provider"}
                        id={"contentProvider"}
                        className="md-content-provider"
                        value={props?.formData?.contentProvider}
                        onChange={(e) => props.handleSelectDropdowns(e, "contentProvider")}
                        options={props.organizationOptions}
                        placeholder="Select content provider"
                        disabled={props.isFormDisabled}
                        isRequired
                        isMulti
                    />
                </div>
                <div className="roles-section" >
                    <label id="roles" className="title">Roles</label>
                    {props.availableRolesToCreate.map((name, index) => {
                        return (
                            <div className="checkbox-section" key={index}>
                                <input
                                    className={((props.checkedState[3] === true && index < 3) || (props.checkedState[4] === true && index < 4)) ? 'disabled-checkbox' : ""}
                                    type="checkbox"
                                    id={`custom-checkbox-${index}`}
                                    name={name}
                                    value={name}
                                    checked={props.checkedState[index]}
                                    onChange={() => props.handleCheckbox(index)}
                                    onKeyPress={(e) => {
                                        if (e.code === "Space" || e.code === "Enter") {
                                            e.preventDefault();
                                            props.handleCheckbox(index)
                                        }
                                    }}
                                    disabled={props.isFormDisabled}
                                />
                                <label htmlFor={`custom-checkbox-${index}`} className="checkbox-label">{name}</label>
                            </div>
                        );
                    })}
                </div>
                <button className="btn-secondary submit" id="submit" onClick={() => props.handleSubmit("modify")}
                    onKeyPress={(e) => {
                        if (e.code === "Space" || e.code === "Enter") {
                            e.preventDefault();
                            props.handleSubmit("modify",)
                        }
                    }}
                    disabled={props.isFormDisabled}
                >Submit</button>
            </div>

        </div>
    )
}

export default ViewUser;
