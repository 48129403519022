import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import './DropdownField.css';

const DropdownField = ({ title, value, onChange, options, isMulti, placeholder, isRequired, className, limitOptions, maxLimit, disabled = false, id, submitAttempted = false, filterOption, closeMenuOnSelect, dynamicDropdownHeight }) => {
    const [isDropdownValid, setIsDropdownValid] = useState(!submitAttempted);
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
    const [dropdownHeight, setDropdownHeight] = useState(0);
    const errorMessage = `* ${title} is required`;

    useEffect(() => {
        if (!dynamicDropdownHeight) return;

        if (isDropdownMenuOpen) {
            updateDropdownHeight();
        } else {
            setDropdownHeight(0);
        }
    }, [isDropdownMenuOpen]);

    useEffect(() => {
        if (submitAttempted) {
            if (!isMulti) {
                isRequired && setIsDropdownValid(value?.value?.length > 0)
            } else {
                isRequired && setIsDropdownValid(value?.length > 0)
            }
        } else {
            setIsDropdownValid(true);
        }
    }, [submitAttempted]);

    const updateDropdownHeight = () => {
        // a slight delay to ensure the DOM has updated
        setTimeout(() => {
            const dropdownMenuElement = document.getElementById(`react-select-${id}-listbox`);

            if (dropdownMenuElement) {
                setDropdownHeight(dropdownMenuElement.offsetHeight);
            }
        }, 0);
    };

    const onMenuOpen = () => {
        if (!dynamicDropdownHeight) return;
        setIsDropdownMenuOpen(true);
    }

    const onMenuClose = () => {
        if (!dynamicDropdownHeight) return;
        setIsDropdownMenuOpen(false);
    }

    const onInputChange = () => {
        if (!dynamicDropdownHeight) return;
        isDropdownMenuOpen && updateDropdownHeight()
    }

    const onBlur = () => {
        if (!isMulti) {
            isRequired && setIsDropdownValid(value?.value?.length > 0)
        } else {
            isRequired && setIsDropdownValid(value?.length > 0)
        }
    }

    const onFocus = () => {
        setIsDropdownValid(true)
    }

    const customStyles = (value) => {
        var bodyStyles = window.getComputedStyle(document.body);


        const customizedStyles = {
            option: (provided, state) => ({
                ...provided,
                padding: filterOption ? "0px" : provided.padding,
                backgroundColor: state.isSelected ? bodyStyles.getPropertyValue('--brand-md-blue') : bodyStyles.getPropertyValue('--white'),
                '&:hover': { backgroundColor: state.isSelected ? bodyStyles.getPropertyValue('--brand-md-blue') : bodyStyles.getPropertyValue('--extended-light-blue') }
            }),
            control: (base, state) => ({
                ...base,
                border: (isRequired && !isDropdownValid) ? `1px solid ${bodyStyles.getPropertyValue('--brand-red')}` : `1px solid ${bodyStyles.getPropertyValue('--brand-md-grey')}`,
                borderRadius: '5px',
                minHeight: '44px',
                boxShadow: 'none',
                '&:hover': {
                    border: `2px solid ${bodyStyles.getPropertyValue('--brand-md-blue')}`,
                },
                backgroundColor: bodyStyles.getPropertyValue('--white')
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                minHeight: '40px',
                maxHeight: value?.length > 2 ? '60px' : '44px',
                padding: '0px 16px',
                fontSize: '14px',
                overflowY: value?.length > 2 ? "scroll" : "none",
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                minHeight: '40px',
            }),
            dropdownIndicator: base => ({
                ...base,
                color: bodyStyles.getPropertyValue('--brand-md-blue')
            }),
            singleValue: (provided) => ({
                ...provided,
                color: bodyStyles.getPropertyValue('--black'),
            })
        }
        return customizedStyles
    }

    return (
        <div className="_dropdownField" >
            <div className={title ? "dropdown-title" : ""}>{title}</div>
            <Select
                id={id}
                inputId={id}
                instanceId={id}
                className={className}
                value={value}
                onChange={onChange}
                options={options}
                isMulti={isMulti}
                placeholder={placeholder}
                isOptionDisabled={() => limitOptions ? value?.length >= maxLimit : null}
                styles={customStyles(value)}
                onBlur={onBlur}
                onFocus={onFocus}
                isDisabled={disabled}
                filterOption={filterOption}
                closeMenuOnSelect={closeMenuOnSelect}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                onInputChange={onInputChange
                }
            />
            {dynamicDropdownHeight && <div className="container-below-dropdown" style={{ marginTop: `${dropdownHeight}px` }}>
            </div>}
            {(!isDropdownValid && isRequired) && (<div id="error-msg" className="error-message">{errorMessage}</div>)}
        </div>
    );
};

export default DropdownField;
