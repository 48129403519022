import {
    ORGANIZATIONS_URL,
    DEACTIVATE_ORG_URL,
    ORGANIZATION_PAGE_SIZE,
    CREATE_ORG_URL,
} from "../constants";

const DEFAULT_SORT = [
    { type_group: 'asc' },
    { org_name: 'asc' },
];

const OrganizationsService = (props) => {
    const displayOrganizations = (body, isInitial, currentResults) => {
        if (isInitial) {
            props?.setter?.(body.results);
            props?.setOrganizationFacets?.(body.facets)
            props?.pageTotalSetter?.(body.meta?.page?.total_pages);
            props?.resultsTotalSetter?.(body.meta?.page?.total_results);
        } else {
            displayContinuedOrganizations(currentResults, body.results, body.meta?.page)
        }
    }
    const handleOrganizationsError = (err) => {
        //TODO: handle fetch organizations error
    };
    const displayContinuedOrganizations = (current, res, page) => {
        while (res.length) {
            current.push(res.shift());
        }
        props.setter(current);
        props.pageSetter(page?.current);
        props.pageTotalSetter(page?.total_pages);
    }

    const postOrgData = (res) => {
        if (res.statusCode) {
            props.setPostResults(res?.body?.message);
        } else {
            props.setPostResults(res[0]);
        }
    }

    const updateRequestWithFacets = (requestBody, facetType) => {
        const facetsName = `${facetType}_facet`;
        const facets = {
            [facetType]: {
                type: "value",
                name: facetsName,
                sort: {
                    value: "asc"
                },
                size: 100
            }
        };
        requestBody.facets = facets;
    }

    const updateRequestWithFilters = (requestBody, filters) => {
        const filter =
        {
            "all": filters
        };
        requestBody.filters = filter;
    }

    const organizationService = {
        fetchOrganizations: (page = 1, results = 1000, sort = DEFAULT_SORT, isInitial = true, currentResults = [], facetType) => {
            const requestBody = {
                query: "",
                page: {
                    current: page,
                    size: results,
                },
                sort: sort
            }

            props?.filters && updateRequestWithFilters(requestBody, props?.filters)

            facetType && updateRequestWithFacets(requestBody, facetType)

            fetch(ORGANIZATIONS_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(
                    {
                        body: requestBody
                    }
                ),
            })
                .then((response) => response.json())
                .then((data) => displayOrganizations(JSON.parse(data.body), isInitial, currentResults))
                .catch((e) => {
                    handleOrganizationsError(e);
                });
        },
        fetchSomeOrganizations: (isAsc, currentResults, facetType) => {
            let sort = { org_name: isAsc ? 'asc' : 'desc' };
            organizationService.fetchOrganizations(1, ORGANIZATION_PAGE_SIZE, sort, true, currentResults, facetType);
        },
        fetchMoreOrganizations: (page, isAsc, currentResults) => {
            let sort = { org_name: isAsc ? 'asc' : 'desc' };
            organizationService.fetchOrganizations(page + 1, ORGANIZATION_PAGE_SIZE, sort, false, currentResults);
        },
        performPostOrg: (requestBody) => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Access-Control-Request-Headers': '*',
                    'Content-type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(requestBody)
            }
            fetch(CREATE_ORG_URL, requestOptions)
                .then((response) => response.json())
                .then((data) => postOrgData(JSON.parse(data.body)))
                .catch((e) => {
                    handleOrganizationsError(e);
                });
        },
        performDeactivateOrg: (requestBody, callback = () => { }) => {
            fetch(DEACTIVATE_ORG_URL, {
                method: "POST",
                headers: {
                    "Access-Control-Request-Headers": "*",
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(
                    {
                        body: requestBody
                    }
                ),
            })
                .then((response) => response.json())
                .then((data) => {
                    callback();
                })
                .catch((e) => {
                    handleOrganizationsError(e);
                });
        },
    };

    return organizationService;
};

export default OrganizationsService;

