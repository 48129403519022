import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import './DateComponent.css';

const DateComponent = (props) => {
    const { id, label, className, onChange, selected, disabled, required, submitAttempted, formData } = props;
    const [isInputValid, setIsInputValid] = useState(!submitAttempted);
    const [inputClassName, setInputClassName] = useState(className);
    const displayLabel = label.includes("(") ? label.substring(0, label.indexOf("(")) : label;
    const errorMessage = `* ${displayLabel} is required`;

    useEffect(() => {
        if (!selected && required) {
            setInputClassName(`${className} invalid`)
        } else {
            setInputClassName(className);
        }
    }, [submitAttempted, onChange]);

    useEffect(() => {
        if (formData?.mediaType?.value?.includes('Event')) {
            setIsInputValid(false);
        } else {
            setIsInputValid(true);
        }
    }, [formData]);


    const onBlur = (e) => {
        required && setIsInputValid(selected?.length);
    }

    return (
        <div className="date">
            {label}
            <DatePicker
                id={id}
                className={inputClassName}
                showPopperArrow={false}
                placeholderText="MM/DD/YYYY"
                onChange={onChange}
                onBlur={onBlur}
                selected={selected}
                disabled={disabled}
                required={required}
            />
            {(!selected && required) && (<span id="error-msg" className="error-message">{errorMessage}</span>)}

        </div>
    );
};

export default DateComponent;
