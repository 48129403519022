import { useEffect, useState } from 'react';
import BrowseModal from '../Modals/BrowseModal/BrowseModal';
import './QueryBar.css';


function QueryBar(props) {
    const [browsing, setBrowsing] = useState(false);
    const querySearchBarId = "query-search-bar";

    useEffect(() => {
        if (props.backDrop && browsing) {
            props.setModalContentTitle('Browse');
        } else if (browsing) {
            props.setModalContentTitle('');
            setBrowsing(!browsing);
        }
    }, [props.backDrop, browsing]);

    const doSearch = () => {
        const query = document.getElementById(querySearchBarId).value;
        props.setFilters([]);
        props.setFilterChange(0);
        props.search(query);
    }

    const toggleBrowse = () => {
        if (document.getElementById(querySearchBarId)) {
            document.getElementById(querySearchBarId).value = '';
        }
        setBrowsing(!browsing);
        props.setBackDrop(!props.backDrop);
    }

    const toggleFilters = () => {
        props.setShowFilters(!props.showFilters);
        props.setBackDrop(!props.backDrop);
    }
    return (
        <div className="_query-bar">
            <div className="_query-bar__content">
                <div className="logo">
                    {props.orgLogo &&
                        <img onClick={props.navHome} id='branding-logo-image' className="logo-img" src={props.orgLogo} alt="dynamic logo" />
                    }
                    <img id='logo-content' tabIndex="0" alt="OPLD Logo" src="/logos/OPLD_logo.svg"
                        onClick={props.navHome}
                        onKeyPress={(e) => {
                            if (e.code === "Space" || e.code === "Enter") {
                                e.preventDefault();
                                props.setPrevious(document.location.pathname);
                                props.setFilterChange(0);
                                props.navigate('/');
                            }
                        }}
                    />
                    <div className="filters">
                        <button id="browse-dropdown-mobile" className="btn-secondary" onClick={() => toggleBrowse()}>
                            <div className="browse-dropdown-wrapper">
                                <img src="/icons/chevron.svg" alt="chevron icon" />Browse
                            </div>
                        </button>
                    </div>

                </div>
                {document.location.pathname !== '/' ?
                    (
                        <div className="search-bar">
                            <label htmlFor={querySearchBarId} />
                            <div className="query-bar-search-wrapper">
                                <input placeholder="Search for listings..." type="text" id={querySearchBarId} className={querySearchBarId} onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        doSearch();
                                    }
                                }} />
                                <button className="submit" id="query-submit" onClick={doSearch}><img src="/icons/search.svg" alt="magnifying glass" /></button>
                            </div>
                            {document.location.pathname === '/results' &&
                                <div className='filter-btn'>
                                    <img onClick={() => toggleFilters()} className="filter-icon" src="/icons/filter-dark.svg" alt="toggle board" />
                                </div>
                            }

                            <div className="filters">
                                <button id="browse-dropdown" className="btn-secondary" onClick={() => toggleBrowse()}>
                                    <div className="browse-dropdown-wrapper">
                                        <img src="/icons/chevron.svg" alt="chevron icon" />Browse
                                    </div>
                                </button>
                            </div>

                            {browsing &&
                                <BrowseModal gtag={props.gtag} mobile={false} backDrop={props.backDrop} setBackDrop={props.setBackDrop} setFilters={props.setFilters} filterChange={props.filterChange} setFilterChange={props.setFilterChange} setQuery={props.setQuery} />
                            }
                        </div>
                    ) :
                    (
                        <div className="search-bar desktop-only">
                            <div className="filters">
                                <button id="browse-dropdown" className="btn-secondary" onClick={() => toggleBrowse()}>
                                    <div className="browse-dropdown-wrapper">
                                        <img src="/icons/chevron.svg" alt="chevron icon" />Browse
                                    </div>
                                </button>
                            </div>
                            {browsing &&
                                <BrowseModal gtag={props.gtag} mobile={false} backDrop={props.backDrop} setBackDrop={props.setBackDrop} setFilters={props.setFilters} filterChange={props.filterChange} setFilterChange={props.setFilterChange} setQuery={props.setQuery} />
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default QueryBar;
