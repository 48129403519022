import { useEffect, useState } from "react";
import { TOOL_USER_GUIDE_URL, LISTING_CRITERIA_URL, ENDORSEMENT_CRITERIA_URL, RESOURCE_TEMPLATE} from '../../constants';
import QueryService from '../../Services/QueryService';
import "./UtilityBar.css";
import DropDown from "../common/DropDown";
import DropdownField from "../common/DropdownField";

function UtilityBar(props) {
    const [orgNames, setOrgNames] = useState([]);
    const [orgIDs, setOrgIDs] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('topic');
    const [downloadResourceTemplate, setDownloadResourceTemplate] = useState(false);
    const [reportField, setReportField] = useState('');
    const [downloadBtn, setDownloadBtn] = useState('');
    const queryService = QueryService({
        setter: props.setListingsViewsCounts,
        currentData: props.listingsViewsCounts,
        resultsTotalSetter: props.setListingsPublishedCount
    });

    let exportLink;
    const displayFilters = ["Topic", "Audience", "Media Type"]

    const viewFieldMap = {
        'Topic': 'topic',
        'Audience': 'audience',
        'Media Type': 'media_type',
        'topic': 'Topic',
        'audience': 'Audience',
        'media_type': 'Media Type',
    };

    const [reportDownloadOptions, setReportDownloadOptions] = useState([
        { label: 'All Listings', value: 'all_listings' },
        { label: 'Listings by Provider', value: 'listings_by_provider' },
    ]);

    useEffect(() => {
        if (props.user?.org_name && typeof (props.user?.org_name) !== 'string') {
            setOrgNames(props.user?.org_name);
            setOrgIDs(props.user?.org_id);
        } else {
            setOrgNames([props.user?.org_name]);
            setOrgIDs([props.user?.org_id]);
        }
    }, [props.user, props.organizations]);

    useEffect(() => {
        const roleObj = props?.user?.roles ? props?.user?.roles : '';
        if ((Object.values(roleObj).includes('creator')) || (Object.values(roleObj).includes('super-admin'))) {
            setDownloadResourceTemplate(true);
        }
    }, [props?.user?.roles]);

    useEffect(() => {
        if ((orgIDs.length > 0) && props.user?.roles && props.organizations.length > 0 && props.logoLink.length === 0) {
            props.setLogoLink(props?.organizations?.filter(org => org.id?.raw === orgIDs[0])[0]?.logo_link?.raw || props.logoLink);
        }
    }, [orgIDs, props]);

    useEffect(() => {
        if(orgIDs[0]) {
            dropdownListingsViewCount(selectedFilter, orgIDs);
        }
    }, [selectedFilter, orgIDs]);

    useEffect(() => {
        if(orgIDs[0]) {
            publishedListingsCount(orgIDs);
        }
    }, [orgIDs]);

    const getOrgs = () => {
        return <div className="org-name">
            {orgNames.map((org, index) => {
                return <div key={`org-${index}`}>{org}</div>;
            })}
        </div>
    }
    const dropdownListingsViewCount = (selectedFilter, organizationIds) => {
        if(props.user?.roles?.includes('super-admin')) {
            organizationIds = props?.organizations?.map(org => org.id?.raw);
        }
        queryService.performAdminResourceSearch(organizationIds, selectedFilter);
    }
    const publishedListingsCount = (organizationIds) => {
        if(props.user?.roles?.includes('super-admin')) {
            organizationIds = props?.organizations?.map(org => org.id?.raw);
        }
        queryService.performAdminResourcePublishedCount(organizationIds);
    }
    const exportOrganization = (organizationIds) => {
        if (props.user?.roles.includes('super-admin')) {
            organizationIds = props?.organizations?.map(org => org.id?.raw);
        }
        setDownloadBtn('');
        if (reportField === "all_listings") {
            return queryService.performAllListingsExport(organizationIds);
        } else {
            return queryService.performListingByProviderExport(organizationIds);
        }
    }

    const handleSelectDropdown = (event) => {
        setReportField(event.value);
        setDownloadBtn('active');
    }

    return (
        <div className="_utility-bar">
            <div className="org-summary">
                <div className="logo"> <img src={props.logoLink} alt="logo" /></div>
                {getOrgs()}
            </div>
            <div className="statistics">
                <div className="lisitngStatistics">
                    <div className="filter">
                        {props.listingsPublishedCount > 0 && <span className="title"><span className="listingCount">{props.listingsPublishedCount}</span> Listings Published</span>}
                        <div className="_dropdown">
                            <DropdownField
                                id={"report-download"}
                                options={reportDownloadOptions}
                                onChange={handleSelectDropdown}
                                placeholder="Select report to download"
                                isRequired={false}
                            />
                        </div>
                        <a download tabIndex="0" target="_blank" className={`download ${downloadBtn}`} rel="noreferrer"
                        id="exportOrganization" onClick={async (e) =>{
                            exportLink = await exportOrganization(orgIDs);
                            window.open(exportLink, '_blank');
                        }}
                        onKeyPress={async (e) => {
                            if (e.code === "Space") {
                                e.preventDefault();
                                exportLink = await exportOrganization(orgIDs)
                                window.open(exportLink, '_blank');
                            }
                        }}
                        ><img src="/icons/download-white.svg" alt="download" /> Download </a>
                    </div>
                </div>
                <DropDown id="utitliyStatistics" label="View" options={displayFilters} ascending='' setAscending={() => { }} field={selectedFilter} setField={setSelectedFilter} fieldMap={viewFieldMap} change={''} setChange={() => {}} />
                {selectedFilter && <div className="multiFilterOptions">
                    {props.listingsViewsCounts && props.listingsViewsCounts[0]?.map((facet, index) => {
                        return <div className="individualItem" key={index}><div className="listingCount-option">{facet.count}</div><div className="option">{facet.value}</div></div>
                    })}
                </div>
                }
            </div>
            <div className="documents">
                <div className="documents__content">
                    <div className="title">Documents</div>
                    <a href={TOOL_USER_GUIDE_URL} target="_blank" className="download" rel="noreferrer"
                        onKeyPress={(e) => {
                            if (e.code === "Space") {
                              e.preventDefault();
                              window.open(TOOL_USER_GUIDE_URL, '_blank');
                            }
                        }}  
                    >
                        <img src="/icons/download.svg" alt="download"/> Tool User Guide
                    </a>
                    <a href={LISTING_CRITERIA_URL} target="_blank" className="download" rel="noreferrer"
                        onKeyPress={(e) => {
                            if (e.code === "Space") {
                              e.preventDefault();
                              window.open(LISTING_CRITERIA_URL, '_blank');
                            }
                        }}
                    >
                        <img src="/icons/download.svg" alt="download"/> Listing Criteria
                    </a>
                    {/* <a href={ENDORSEMENT_CRITERIA_URL} target="_blank" className="download" rel="noreferrer"
                        onKeyPress={(e) => {
                            if (e.code === "Space") {
                              e.preventDefault();
                              window.open(ENDORSEMENT_CRITERIA_URL, '_blank');
                            }
                        }}
                    >
                        <img src="/icons/download.svg" alt="download"/> Endorsement Criteria
                    </a> */}
                    { downloadResourceTemplate &&
                        <a href={RESOURCE_TEMPLATE} target="_blank" className="download" rel="noreferrer"
                            onKeyPress={(e) => {
                                if (e.code === "Space") {
                                e.preventDefault();
                                window.open(RESOURCE_TEMPLATE, '_blank');
                                }
                            }}
                        >
                            <img src="/icons/download.svg" alt="download"/> Download Template
                        </a>
                    }
                </div>
            </div>
        </div >
    );
}

export default UtilityBar;
