import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RESOURCE_FIELDS, RESOURCE_STATES, ROLES, TRUE } from "../../constants";
import QueryService from "../../Services/QueryService";
import utilityService from "../../Services/utilityService";
import DropDown from "../common/DropDown";
import Tile from "../Tile/Tile";
import UtilityBar from "../UtilityBar/UtilityBar";
import "./ResourceEndorse.css";
import { RESOURCES } from '../../constants'
import ResourceView from '../ResourceView/ResourceView';
import ResourceConfirmationView from '../ResourceConfirmationView/ResourceConfirmationView'
import DropdownField from "../common/DropdownField";
import { useFilterOrganizationsByStatus } from "../../hooks/useFilterOrganizationsByStatus";

function ResourceEndorse(props) {
    const navigate = useNavigate();
    const [sortChange, setSortChange] = useState(0);
    const [viewChange, setViewChange] = useState(0);
    const [createState, setCreateState] = useState(RESOURCES.MAIN);
    const [postCallType, setPostCallType] = useState('');
    const [resourceInfo, setResourceInfo] = useState({
        reviewer_comments: ''
    });
    const [viewField, setViewField] = useState('');
    const [organizationsWithCountList, setOrganizationsWithCountList] = useState([]);
    const orgNamesWithCount = useMemo(() => getOrgNamesWithCount(props?.facetCounts), [props.facetCounts]);
    const orgNames = useMemo(() => [...orgNamesWithCount.keys()], [orgNamesWithCount.size]);
    const [activeOrganizationsWithListings] = useFilterOrganizationsByStatus(orgNames, TRUE);

    useEffect(() => {
        const viewOptions = activeOrganizationsWithListings?.org_name?.[0]?.data?.map((facet, index) => ({ label: `${facet.value} (${orgNamesWithCount.get(facet.value)})`, value: facet.value }));
        if (viewOptions) {
            viewOptions.unshift({ label: 'All Organizations', value: "" });
            setOrganizationsWithCountList(viewOptions);
        }
    }, [activeOrganizationsWithListings]);

    const queryService = QueryService({
        setter: props.setResults,
        currentData: props.results,
        pageSetter: props.setPage,
        querySetter: props.querySetter,
        totalSetter: props.totalSetter,
        sortField: props.sortField,
        organization: viewField,
        sortAscending: props.sortAscending,
        filters: props.filters,
        exclude_org: !props.isSuperAdmin && props.user.org_id,
        setPostResults: props.setPostResults,
        postResults: props.postResults,
        uploadResult: props.uploadResult,
        setUploadResult: props.setUploadResult,
        facetCounts: props.setFacetCounts
    });

    const sortFieldMap = {
        'Newest': RESOURCE_FIELDS.UPLOADED_DATE,
        'Oldest': RESOURCE_FIELDS.UPLOADED_DATE,
        [RESOURCE_FIELDS.UPLOADED_DATE]: { true: 'Oldest', false: 'Newest' },
        'Listing Title (A-Z)': RESOURCE_FIELDS.TITLE,
        'Listing Title (Z-A)': RESOURCE_FIELDS.TITLE,
        [RESOURCE_FIELDS.TITLE]: { true: 'Listing Title (A-Z)', false: 'Listing Title (Z-A)' },
    };

    const sortOptions = ['Newest', 'Oldest', 'Listing Title (A-Z)', 'Listing Title (Z-A)'];

    if (props.isSuperAdmin || props.isOrganizationAdmin) {
        sortFieldMap['Organizations'] = RESOURCE_FIELDS.ORGANIZATION_NAME;
        sortFieldMap[[RESOURCE_FIELDS.ORGANIZATION_NAME]] = { true: 'Organizations' };
        sortOptions.push('Organizations')
    }

    useEffect(() => {
        if (!props.authCookie) {
            navigate('/');
        }
    }, [props.authCookie, navigate]);

    useEffect(() => {
        if (props.user.roles && !props.user.roles.includes(ROLES.ENDORSER)) {
            navigate('/');
        }
    }, [props.user]);

    useEffect(() => {
        if (props.user.roles && sortChange === 1) {
            queryService.performAdminSearch(RESOURCE_STATES.ENDORSE, RESOURCE_FIELDS.ORGANIZATION_NAME);
        }
        else if (props.user.roles && sortChange > 1) {
            queryService.performAdminSearch(RESOURCE_STATES.ENDORSE);
        }
    }, [sortChange]);

    useEffect(() => {
        if (props.user.roles && viewChange > 0) {
            queryService.performAdminSearch(RESOURCE_STATES.ENDORSE);
        }
    }, [viewField]);

    useEffect(() => {
        if (props?.postResults?.id) {
            setCreateState(RESOURCES.SUBMIT_RESOURCE_CONFIRMED);
            window.scrollTo(0, 0);
        }
    }, [props.postResults]);


    useEffect(() => {
        if (props?.uploadResult?.link) {
            const pathname = new URL(props?.uploadResult?.link).pathname;
            const index = pathname.lastIndexOf('/');
            const fileName = pathname.substring(index + 1);
            const formattedFileName = fileName.replaceAll("%20", " ");
            props.setUploadedFile(formattedFileName);
        }
    }, [props.uploadResult]);

    useEffect(() => {
        utilityService.focusNextCardOnLoadMore(props);
    }, [props.results.length]);

    function fetchMoreResults() {
        queryService.fetchNextPage('', props.page, props.results, RESOURCE_STATES.ENDORSE);
    }

    function getOrgNamesWithCount(facetCounts) {
        const orgNamesWithCountMap = new Map();

        facetCounts?.[0]?.forEach((orgName) => {
            if (orgName?.value && orgName?.count) {
                orgNamesWithCountMap.set(orgName.value, orgName.count);
            }
        });

        return orgNamesWithCountMap;
    }

    //TODO: update the target location below to point to proper path when available.
    function renderResult(result, groupAmt) {
        return new Array(groupAmt).fill(0, 0, groupAmt).map((n, i) => {
            return (result.length > i ? <Tile key={i} index={i} tile={result[i]} search={props.search} setResult={props.setResult} setPrevious={props.setPrevious} setCreateState={setCreateState} resourceTab={RESOURCES.ENDORSE} populateFields={populateFields} setIsEditingDisabled={() => { }} targetLocation="/Admin/" setTileId={props.setTileId} tileId={props.tileId} /> : <div key={i} className="_results _tile void"></div>);
        })
    }

    const getResults = (results) => {
        let cnt = 0;
        if (!results?.length) {
            return (
                <div className="no-results">
                    No results found.
                </div>
            )
        }
        results.forEach((result) => {
            result.index = cnt++;
        });
        const groupAmt = 3;
        const groupedResults = utilityService.groupResults(results, groupAmt);
        const renderedResults = groupedResults.map((result, index) => {
            return (
                <div className="tile-row" key={index} index={index}>
                    {renderResult(result, groupAmt)}
                </div>
            )
        });
        return (
            <div>
                {renderedResults}
            </div>
        )
    }

    const handleBackToListings = () => {
        setCreateState(RESOURCES.MAIN)
        props.setUploadResult({});
        props.setUploadedFile('');
        setPostCallType('');
    }

    const populateFields = (result) => {
        setResourceInfo(result);
        props.setUploadResult({});
        props.setUploadedFile('');
    }

    const handleTextAreaChange = (e) => {
        const value = e.target.value;
        setResourceInfo({
            ...resourceInfo,
            [e.target.name]: value.replace(/[^a-zA-Z0-9\[\]\!\@\#\$\%\^\&\*\(\)\-\_\,\.\{\}\[\]\+\=\;\:\"\'\~\`\<\>\s]/gi, '')
        });
    }

    const handleApproveResource = () => {
        let comments = resourceInfo.endorser_comments?.raw || resourceInfo.endorser_comments;
        let reqBody = {
            resourceId: resourceInfo?.id?.raw,
            reviewer: props?.user?.name,
            comments: comments,
            approved: true,
            endorsementRequested: resourceInfo?.endorsement_requested?.raw,
            evaluationFormLink: props?.uploadedFile ? props?.uploadResult?.link : null,
            creatorId: resourceInfo?.uploaded_by_id?.raw,
        };
        setPostCallType("Approve");
        queryService.performEndorseResource(reqBody);
    }

    const handleRejectResource = () => {
        let comments = resourceInfo.endorser_comments?.raw || resourceInfo.endorser_comments;
        let reqBody = {
            resourceId: resourceInfo?.id?.raw,
            reviewer: props?.user?.name,
            comments: comments,
            approved: false,
            endorsementRequested: resourceInfo?.endorsement_requested?.raw,
            evaluationFormLink: props?.uploadedFile ? props?.uploadResult?.link : null,
            creatorId: resourceInfo?.uploaded_by_id?.raw,
        };
        setPostCallType("Reject");
        queryService.performEndorseResource(reqBody);
    }

    const uploadFile = (e) => {
        const fileData = new FormData();
        const fileName = e.target.files[0].name;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        const fileSizeInMb = e.target.files[0].size / Math.pow(1024, 2)
        if (parseInt(fileSizeInMb) > 5) {
            alert("File size should not exceed: 5mb");
        } else {
            if (["jpg", "jpeg", "png", "pdf", "xlsx", "txt", "docx"].includes(extFile)) {
                fileData.append('file', e.target.files[0], fileName);
                queryService.performDocumentUpload(fileData);
            } else {
                alert("Only jpg/jpeg, png, pdf, xlsx, txt, docx files are allowed!");
            }
        }

    }

    const removeUploadedFile = () => {
        props.setUploadedFile('');
        document.forms["uploadFile"].reset();
    }

    const handleOpenDialog = () => {
        props.setOpenDialog(true);
    }

    const handleSelectDropdown = (event) => {
        setViewField(event.value);
        setViewChange(viewChange + 1);
    }

    return (
        <div className="_endorse">
            <div className="_endorse__content">
                {props.authCookie &&
                    <>
                        <div className="main">
                            {createState !== RESOURCES.SUBMIT_RESOURCE_CONFIRMED && (
                                <>
                                    <div className="utility">
                                        <UtilityBar logoLink={props.logoLink} setLogoLink={props.setLogoLink} user={props.user} organizations={props.organizations} setListingsViewsCounts={props.setListingsViewsCounts} listingsViewsCounts={props.listingsViewsCounts} listingsPublishedCount={props.listingsPublishedCount} setListingsPublishedCount={props.setListingsPublishedCount} />
                                    </div>
                                    {createState === RESOURCES.MAIN &&
                                        <div className="content">
                                            <div className="page-title">Evaluate for Endorsement</div>
                                            <div className="results-control">
                                                <div className="_dropdown-wrapper">
                                                    <span className="label">{`View:`}</span>
                                                    <DropdownField
                                                        id={"endorse-view"}
                                                        options={organizationsWithCountList}
                                                        onChange={handleSelectDropdown}
                                                        placeholder="Select Organization"
                                                        isRequired={false}
                                                    />
                                                </div>
                                                <div className="sort">
                                                    <DropDown label="Sort by" toggleAscending={true} options={sortOptions} ascending={props.sortAscending} setAscending={props.setSortAscending} field={props.sortField} setField={props.setSortField} fieldMap={sortFieldMap} change={sortChange} setChange={(val) => setSortChange(val)} id="endorse" />
                                                </div>
                                            </div>
                                            <div className="results">
                                                {getResults(props.results)}
                                            </div>
                                            {props.page < props.total &&
                                                (
                                                    <div className="load-more">
                                                        <div className="void"></div>
                                                        <button className="btn-secondary" onClick={() => fetchMoreResults()}>Load More</button>
                                                        <div className="void"></div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    }

                                    {createState === RESOURCES.ENDORSE &&
                                        <div className="content">
                                            <div className="back" onClick={() => handleBackToListings()}>{"< Back to Listings"}</div>
                                            <div className="title">Evaluate for Endorsement</div>
                                            <div className="sub-content">Please leave all feedback in the comments field at the bottom of the form.
                                                All fields required unless otherwise stated.
                                            </div>
                                            <hr className="divider" />
                                            <ResourceView
                                                isFromEndorseTab
                                                data={resourceInfo}
                                                handleTextAreaChange={handleTextAreaChange}
                                                handleApproveResource={handleApproveResource}
                                                handleRejectResource={handleRejectResource}
                                                uploadFile={uploadFile}
                                                uploadResult={props.uploadResult}
                                                postCallType={postCallType}
                                                uploadedFile={props.uploadedFile}
                                                removeUploadedFile={removeUploadedFile}
                                                handleOpenDialog={handleOpenDialog}
                                                replaceFile={props.replaceFile}
                                            />
                                        </div>
                                    }
                                </>
                            )}
                        </div>

                        {createState === RESOURCES.SUBMIT_RESOURCE_CONFIRMED && (
                            <ResourceConfirmationView handleBackToListings={handleBackToListings}
                                heading={`The listing has been ${postCallType === "Approve" ? "approved" : "rejected"}`}
                                subTextOne={'An email has been sent to creator of the listing informing about the decision.'}
                                subTextTwo={'Click on the button below to go back to dashboard.'}
                                postResults={props?.postResults}
                            />
                        )
                        }

                    </>
                }
            </div >
        </div >
    );
}

export default React.memo(ResourceEndorse);
