import React, { useEffect, useState } from 'react'
import './TextArea.css'

const TextArea = ({ label, id, type, name, value, placeholder, onChange, maxLength, isRequired, className, isCharacterCount, submitAttempted = false, disabled = false}) => {

    const [isValid, setIsValid] = useState(!submitAttempted);

    const textAreaClassName = (!isValid && isRequired) ? `${className} invalid` : className;
    const displayLabel = label.includes("(") ? label.substring(0, label.indexOf("(")) : label;
    const errorMessage = `* ${displayLabel} is required`;

    useEffect(() => {
        if(submitAttempted) {
            isRequired && setIsValid(value?.length)
        } else {
            setIsValid(true);
        }
    }, [submitAttempted]);

    const onBlur = () => {
        isRequired && setIsValid(value?.length)
    }

    const onFocus = () => {
        setIsValid(true)
    }

    return (
        <div className="text-area">
            {label}
            <textarea
                id={id}
                name={name}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                maxLength={maxLength}
                onBlur={onBlur}
                onFocus={onFocus}
                disabled={disabled}
                className={textAreaClassName}
            />
            {(!isValid && isRequired) && (<span className="error-message">{errorMessage}</span>)}
            <div>
                {isCharacterCount && <span className="character-count">Character count: {value?.length} / {maxLength}</span>}
            </div>

        </div>
    );
};

export default TextArea;
