import Select from 'react-select';
import { useState, useEffect } from 'react';

import './MultiSelectField.css';

const MultiSelectField = (props) => {
    const { isMulti, value, onChange, submitAttempted = false, isRequired, title, limitOptions, maxLimit, isClearable, isOhTeaching } = props;

    const [isDropdownValid, setIsDropdownValid] = useState(!submitAttempted);

    const errorMessage = `* ${title} is required`;

    useEffect(() => {
        if (submitAttempted) {
                isRequired && setIsDropdownValid(value?.length > 0)
        } else {
            setIsDropdownValid(true);
        }
    }, [submitAttempted]);

    const onBlur = () => {
        if (!isMulti) {
            isRequired && setIsDropdownValid(value?.value?.length > 0)
        } else {
            isRequired && setIsDropdownValid(value?.length > 0)
        }
    }

    const onFocus = () => {
        setIsDropdownValid(true)
    }


    const handleRemoveValue = (e) => {
        if (!onChange) return;
        const { name: buttonName } = e.currentTarget;
        const removedValue = value.find((val) => val.value === buttonName);
        if (!removedValue) return;
        onChange(
        value.filter((val) => val.value !== buttonName),
        { action: "remove-value", removedValue }
        );
    };

    const customStyles = (value) => {
        let bodyStyles = window.getComputedStyle(document.body);

        const customizedStyles = {
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? bodyStyles.getPropertyValue('--brand-md-blue') : bodyStyles.getPropertyValue('--white'),
                '&:hover': { backgroundColor: state.isSelected ? bodyStyles.getPropertyValue('--brand-md-blue') : bodyStyles.getPropertyValue('--extended-light-blue') }
            }),
            control: (base, state) => ({
                ...base,
                border: (isRequired && !isDropdownValid) ? `1px solid ${bodyStyles.getPropertyValue('--brand-red')}` : `1px solid ${bodyStyles.getPropertyValue('--brand-md-grey')}`,
                borderRadius: '5px',
                minHeight: '44px',
                boxShadow: 'none',
                '&:hover': {
                    border: `2px solid ${bodyStyles.getPropertyValue('--brand-md-blue')}`,
                },
                backgroundColor: bodyStyles.getPropertyValue('--white')
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                minHeight: '40px',
                maxHeight: '44px',
                padding: '0px 16px',
                fontSize: '14px',
                overflowY: "none", 
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                minHeight: '40px',
            }),
            dropdownIndicator: base => ({
                ...base,
                color: bodyStyles.getPropertyValue('--brand-md-blue')
            }),
            placeholder: (provided) => ({
                ...provided,
                fontSize: '14px',
                lineHeight: '20px'
            })
        }
        return customizedStyles;
    };




  return (
    <div>
        <div className="dropdown-title">{title}</div>
        <Select 
            {...props} 
            controlShouldRenderValue={!isMulti} 
            styles={customStyles(value)}
            isOptionDisabled={() => limitOptions ? value?.length >= maxLimit : null}
            isClearable={isClearable}
            onBlur={onBlur}
            onFocus={onFocus}
        />
        {(!isDropdownValid && isRequired) && (<div id="error-msg" className="error-message">{errorMessage}</div>)}
      <div className={isOhTeaching ? "_dropdownField_multi-container oh-teaching-container" : "_dropdownField_multi-container"}>
        {isMulti
          ? value.map((val) => (
              <div className="_dropdownField_multi-value" key={val.value}>
                <div className={isOhTeaching ? '_dropdownField_multi-value-inner oh-teaching-value-inner' : '_dropdownField_multi-value-inner'}>
                    {val.label}
                </div>
                <button className="_dropdownField_multi-close" name={val.value} onClick={handleRemoveValue}>
                  ✕
                </button>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default MultiSelectField;
