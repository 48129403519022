import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import "react-datepicker/dist/react-datepicker.css"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ENV_DOMAINS } from './constants';

const googleTagScript = document.createElement('script');
googleTagScript.src = process.env.REACT_APP_APIG_ROOT_URL === ENV_DOMAINS.PROD ? "https://www.googletagmanager.com/gtag/js?id=G-183G8Q06FB" : "https://www.googletagmanager.com/gtag/js?id=G-CR2HDTYT51";
googleTagScript.async = true;
document.body.appendChild(googleTagScript);
const loadTagByID = (tagID) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() { window.dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', tagID);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {
        process.env.REACT_APP_APIG_ROOT_URL === ENV_DOMAINS.PROD &&
        loadTagByID('G-183G8Q06FB')
      }
      {
        [ENV_DOMAINS.STAGE, ENV_DOMAINS.DEV].includes(process.env.REACT_APP_APIG_ROOT_URL) &&
        loadTagByID('G-CR2HDTYT51')
      }
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
