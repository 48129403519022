import React from 'react';
import './Results.css';
import Filters from '../Filters/Filters'
import Tile from '../Tile/Tile';
import utilityService from '../../Services/utilityService';
import QueryService from '../../Services/QueryService';
import { useEffect, useState } from 'react';
import { RESOURCE_FIELDS, SEARCHABLE_STATES } from '../../constants';
import DropDown from '../common/DropDown';
import ContentProvider from './ContentProvider';

function Results(props) {
  const [sortChange, setSortChange] = useState('');
  const [scrollToTile, setScrollToTile] = useState(false);
  const [mFilters, setMFilters] = useState(props.multiFilters);
  const [organizationsWithListings, setOrganizationsWithListings] = useState(null)
  const [resetOrgTypeDropdown, setResetOrgTypeDropdown] = useState(false);
  const [initialContentProviderSearchFilter, setInitialContentProviderSearchFilter] = useState(null)
  const [enableClearFilterInContentProvider, setEnableClearFilterInContentProvider] = useState(false)

  const queryService = QueryService({
    setter: props.setResults,
    currentData: props.results,
    pageSetter: props.setPage,
    querySetter: props.querySetter,
    totalSetter: props.totalSetter,
    resultsTotalSetter: props.resultsTotalSetter,
    sortField: props.sortField,
    sortAscending: props.sortAscending,
    filters: props.filters,
    facetCounts: props.setOrganizationsWithListings,
    initialContentProviderSearchFilter: initialContentProviderSearchFilter
  });

  const sortOptions = ['Most Relevant', 'Least Relevant', 'Newest', 'Oldest', 'Creator (A-Z)', 'Creator (Z-A)', 'Listing Title (A-Z)', 'Listing Title (Z-A)', 'Cost (Low-High)', 'Cost (High-Low)'];

  const sortFieldMap = {
    'Most Relevant': RESOURCE_FIELDS.SCORE,
    [RESOURCE_FIELDS.SCORE]: { true: 'Most Relevant', false: 'Least Relevant' },
    'Least Relevant': RESOURCE_FIELDS.SCORE,
    'Newest': RESOURCE_FIELDS.PUBLISHED_DATE,
    [RESOURCE_FIELDS.PUBLISHED_DATE]: { true: 'Oldest', false: 'Newest' },
    'Oldest': RESOURCE_FIELDS.PUBLISHED_DATE,
    'Creator (A-Z)': RESOURCE_FIELDS.ORGANIZATION_NAME,
    [RESOURCE_FIELDS.ORGANIZATION_NAME]: { true: 'Creator (A-Z)', false: 'Creator (Z-A)' },
    'Creator (Z-A)': RESOURCE_FIELDS.ORGANIZATION_NAME,
    'Listing Title (A-Z)': RESOURCE_FIELDS.TITLE,
    [RESOURCE_FIELDS.TITLE]: { true: 'Listing Title (A-Z)', false: 'Listing Title (Z-A)' },
    'Listing Title (Z-A)': RESOURCE_FIELDS.TITLE,
    'Cost (High-Low)': RESOURCE_FIELDS.COST,
    [RESOURCE_FIELDS.COST]: { true: 'Cost (Low-High)', false: 'Cost (High-Low)' },
    'Cost (Low-High)': RESOURCE_FIELDS.COST,
  }

  useEffect(() => {
    if (organizationsWithListings === null && props.organizationsWithListings?.length > 0) {
      setOrganizationsWithListings(props.organizationsWithListings[0]?.map(org => org.value))
    }
  }, [props.organizationsWithListings])

  useEffect(() => {
    if (!props.pageUpdate) {
      queryService.performBasicSearch(props.query, 1, true, [], RESOURCE_FIELDS.ORGANIZATION_NAME);
    }
  }, [props.sortField, props.sortAscending, props.query, props.filterChange, sortChange, initialContentProviderSearchFilter])

  useEffect(() => {
    if (props.pageUpdate && props.tileId) {
      queryService.fetchPreviousPages(props.query, props.page);
      props.setPageUpdate(false);
      setScrollToTile(true);
    }
  }, [props.pageUpdate]);

  useEffect(() => {
    if (scrollToTile && document.getElementById(props.tileId)) {
      document.getElementById(props.tileId).scrollIntoView({ behavior: "smooth" });
      setScrollToTile(false);
    }
    if (document.getElementById(props.tileId)) {
      document.getElementById(props.tileId).style.border = '1px solid var(--brand-md-blue)';
    }
  }, [props.results])

  useEffect(() => {
    if (props.backDrop && props.showFilters) {
      props.setModalContentTitle('Filter');
    } else if (props.showFilters) {
      props.setModalContentTitle('');
      props.setShowFilters(false);
    }
  }, [props.backDrop, props.showFilters]);

  useEffect(() => {
    utilityService.focusNextCardOnLoadMore(props);
  }, [props.results.length]);

  function fetchMoreResults() {
    queryService.fetchNextPage(props.query, props.page, props.results, SEARCHABLE_STATES);
  }

  function getSortWidget(props) {
    return (<div className="sort-by__wrapper">
      <DropDown label="Sort by" options={sortOptions} toggleAscending={true} ascending={props.sortAscending} setAscending={props.setSortAscending} field={props.sortField} setField={props.setSortField} fieldMap={sortFieldMap} change={sortChange} setChange={() => setSortChange} id="results" />
    </div>);
  }

  function SearchResults(props) {
    return (
      <div className="search-results" onMouseMove={removeTileHighlighting}>
        <div className="title-bar">
          <div className="section-title" id="results-summary-title">{props.query ? `Displaying ${props.resultsTotal} Search Results for "${props.query}"` : `Displaying ${props.resultsTotal} Search Results`}</div>
          {getSortWidget(props)}
        </div>
        {props?.results?.isError ? showError(props) : getResults(props.results)}
        {props.page < props.total && (
          <div className="load-more">
            <button id="load-more-button" className="btn-secondary"
              onClick={fetchMoreResults}
            >
              <span className="mobile-only">View More</span>
              <span className="desktop-only">Load More</span>
            </button>
          </div>)}
      </div>
    )
  }

  function renderResult(result, groupAmt) {
    return new Array(groupAmt).fill(0, 0, groupAmt).map((n, i) => {
      return (result.length > i ? <Tile key={i} index={i} tile={result[i]} search={props.search} setResult={props.setResult} setPrevious={props.setPrevious} targetLocation="/details/" setTileId={props.setTileId} tileId={props.tileId} /> : <div key={i} className="_results _tile void"></div>);
    })
  }

  function showError(props) {
    const { results } = props

    if (results?.isError || (results?.statusCode && results?.statusCode !== 200)) {
      return (
        <div className="error-section">
          Something went wrong, please try again in a bit.
        </div>
      )
    }
  }

  function getResults(results) {
    let cnt = 0;
    let filteredResults = filterResults(results);
    if (!filteredResults?.length) {
      return (
        <div className="no-results">
          No results found, please try again with different search criteria.
        </div>
      )
    }
    filteredResults.forEach((result) => {
      result.index = cnt++;
    });
    let groupAmt = 3;
    let intermediaryGroupAmt = 2

    const groupedResults = utilityService.groupResults(filteredResults, groupAmt);
    const intermediaryGroupedResults = utilityService.groupResults(filteredResults, intermediaryGroupAmt);
    const renderedResults = groupedResults.map((result, index) => {
      return (
        <div className="tile-row" key={index} index={index}>
          {renderResult(result, groupAmt)}
        </div>
      )
    });
    const intermediaryRenderedResults = intermediaryGroupedResults.map((result, index) => {
      return (
        <div className="intermediary-tile-row" key={index} index={index}>
          {renderResult(result, intermediaryGroupAmt)}
        </div>
      )
    });
    return (
      <div className="results">
        {renderedResults}
        {intermediaryRenderedResults}
      </div>
    )
  }

  function filterResults(results) {
    return results;
  }

  const removeTileHighlighting = () => {
    if (document.getElementById(props.tileId)) {
      document.getElementById(props.tileId).style.border = "none";
    }
  }

  return (
    <div className="_results">
      <div className="_results__content">
        <div className="filter-bar">
          <div id="filter-bar__title_wrapper" className="filter-bar__title_wrapper">
            <div className="section-title">Search Filter:</div>
            {(props.filters?.length > 0 || enableClearFilterInContentProvider) &&
              <div
                id="filter-bar__clear-filters_wrapper"
                className="filter-bar__clear-filters_wrapper"
                tabIndex="0"
                onClick={() => {
                  props.setIsEndorsed(false);
                  setEnableClearFilterInContentProvider(false)
                  props.setFilters([]);
                  props.setFilterChange(props.filterChange + 1);
                  setInitialContentProviderSearchFilter(null)
                  setResetOrgTypeDropdown(true);
                }}
                onKeyPress={(e) => {
                  if (e.code === "Space" || e.code === "Enter") {
                    e.preventDefault();
                    props.setIsEndorsed(false);
                    setEnableClearFilterInContentProvider(false)
                    props.setFilters([]);
                    props.setFilterChange(props.filterChange + 1);
                    setInitialContentProviderSearchFilter(null)
                    setResetOrgTypeDropdown(true);
                  }
                }}
              >
                <span className="filter-bar__clear-filters_text">Clear Filters</span>
                <div className="filter-bar__clear-filters_x"></div>
              </div>
            }
          </div>

          <ContentProvider
            organizationFacets={props.organizationFacets}
            setOrganizationFacets={props.setOrganizationFacets}
            selectedOrgType={props.selectedOrgType}
            setSelectedOrgType={props.setSelectedOrgType}
            dropdownOrgTypes={props.dropdownOrgTypes}
            setDropdownOrgTypes={props.setDropdownOrgTypes}
            dropdownOrgNames={props.dropdownOrgNames}
            setDropdownOrgNames={props.setDropdownOrgNames}
            filtersToFetchOrganizations={props.filtersToFetchOrganizations}
            setFiltersToFetchOrganizations={props.setFiltersToFetchOrganizations}
            activeOrganizations={props.activeOrganizations}
            setActiveOrganizations={props.setActiveOrganizations}
            resetOrgTypeDropdown={resetOrgTypeDropdown}
            setResetOrgTypeDropdown={setResetOrgTypeDropdown}
            organizationsWithListings={organizationsWithListings}
            setInitialContentProviderSearchFilter={setInitialContentProviderSearchFilter}
            setEnableClearFilterInContentProvider={setEnableClearFilterInContentProvider}
            gtag={props.gtag}
            filters={props.filters}
            setFilters={props.setFilters}
            filterChange={props.filterChange}
            setFilterChange={props.setFilterChange}
            switchFilters={props.switchFilters}
            isEndorsed={props.isEndorsed}
            setIsEndorsed={props.setIsEndorsed} />

          <Filters gtag={props.gtag} filters={props.filters} setFilters={props.setFilters} filterChange={props.filterChange} setFilterChange={props.setFilterChange} switchFilters={props.switchFilters} multiFilters={mFilters} isEndorsed={props.isEndorsed} setIsEndorsed={props.setIsEndorsed} />
        </div>
        <SearchResults query={props.query} results={props.results} page={props.page} total={props.total} resultsTotal={props.resultsTotal} setSortField={props.setSortField} sortField={props.sortField} setSortAscending={props.setSortAscending} sortAscending={props.sortAscending} />
      </div>
    </div>
  );
}

export default React.memo(Results);


