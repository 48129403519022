import './Filters.css';
import MultiFilter from './MultiFilter';
import SwitchFilter from './SwitchFilter';

function Filters(props) {

  const SwitchFilters = [];
  const MultiFilters = [];
  props.switchFilters.forEach(
    (filter, index) => {
      SwitchFilters.push(<SwitchFilter gtag={props.gtag} key={index} name={filter} filters={props.filters} setFilters={props.setFilters} filterChange={props.filterChange} setFilterChange={props.setFilterChange} isEndorsed={props.isEndorsed} setIsEndorsed={props.setIsEndorsed} />);
    }
  );
  Object.keys(props.multiFilters).forEach(
    (key) => {
      MultiFilters.push(<MultiFilter gtag={props.gtag} key={key} name={key} values={props.multiFilters[key]} filters={props.filters} setFilters={props.setFilters} filterChange={props.filterChange} setFilterChange={props.setFilterChange} setClearFiltersVisibility={props.setClearFiltersVisibility} />);
    }
  )
  return (
    <div className="_filters">
      {SwitchFilters.length > 0 && 
        <div className="switch-filters">
          {SwitchFilters}
        </div>
      }

      <div className="multi-filters" id="multi-user-filters">
        {MultiFilters}
      </div>
    </div>
  );
}

export default Filters;
